import styled, { css } from 'styled-components';

type InputProps = {
  hover: boolean
}

export const Div = styled.div<InputProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-family: Roboto, sans-serif;

  input {
    cursor: pointer;
    
  }

  label {
    cursor: pointer;
    padding-bottom: 2px;
  }

  .form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin-right: 10px;

  font: inherit;
  color:  ${props => props.theme.colors.whiteDarkBlueLight};
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid  ${props => props.theme.colors.whiteDarkBlueLight};
  border-radius: 50%;
  transform: translateY(-0.15em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: ${props => props.theme.colors.whiteDarkBlueLight};
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

${(props) => props.hover &&
    css`
    &:has(.checkbox):hover {
      background-color:  ${(props) => props.theme.colors.dropdownHover};
    }
    input[type="checkbox"]:focus {
      background-color: rebeccapurple;
    }
  `
  }

> .checkbox, .radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 960px) {
  &#weight {
    width: 85px;
  }
  > .checkbox {
    gap: 10px;
  }
}

`;

