export function privacyPolicyByLangugue (languageCode: string) {
    let url;

    switch (languageCode) {
        case 'PT':
            url = 'https://d3kywl98xjzxww.cloudfront.net/PT/Politica_Privacidade_PLATAFORMA_IOK_Portugues.pdf';
            break;
        case 'EN':
            url = 'https://d3kywl98xjzxww.cloudfront.net/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'ES':
            url = 'https://d3kywl98xjzxww.cloudfront.net/ES/Politica_Privacidad_PLATAFORMA_IOK_Espanol.pdf';
            break;
        case 'PL':
            url = 'https://d3kywl98xjzxww.cloudfront.net/PL/Polityka_Prywatnosci_PLATFORMA_IOK_Polski.pdf';
            break;
        case 'ZH':
            url = 'https://d3kywl98xjzxww.cloudfront.net/ZH/Privacy_Policy_IOK_PLATFORM_Mandarin.pdf';
            break;
        case 'RU':
            url = 'https://d3kywl98xjzxww.cloudfront.net/RU/Privacy_Policy_IOK_PLATFORM_Russian.pdf';
            break;
        case 'DE':
            url = 'https://d3kywl98xjzxww.cloudfront.net/DE/Politik_Datenschutz_PLATTFORM_IOK_Deutsch.pdf';
            break;
        case 'FR':
            url = 'https://d3kywl98xjzxww.cloudfront.net/FR/Politique_Confidentialite_PLATEFORME_IOK_Fran%C3%A7ais.pdf';
            break;
        case 'EL':
            url = 'https://d3kywl98xjzxww.cloudfront.net/GR/Privacy_Policy_IOK_PLATFORM_Greek.pdf';
            break;
        default:
            url = 'https://d3kywl98xjzxww.cloudfront.net/PT/Politica_Privacidade_PLATAFORMA_IOK_Portugues.pdf';
    }

    return url;
}
