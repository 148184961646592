import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { persistor } from '../store';

interface RouteMonitorProps {
  children: React.ReactNode;
}

const RouteMonitor: React.FC<RouteMonitorProps> = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const inMenuFlow =
      !!currentPath?.match(/^\/menu\/\w+\/group/)?.length || currentPath.includes('/menu/simple');

    const inCookbookFlow =
      !!currentPath?.match('/cookbook/recipe')?.length || !!currentPath?.match('/cookbook/company')?.length;

    if (inMenuFlow || inCookbookFlow) {
      if (!persistor.getState().bootstrapped) {
        persistor.persist();
      }
    } else {
      if (persistor.getState().bootstrapped) {
        window.sessionStorage.removeItem('persist:menu');
        window.sessionStorage.removeItem('persist:steps');
      }
    }

    const inEquipmentPage = currentPath === '/equipment';
    if (inEquipmentPage) localStorage.removeItem('filtersEventExecution')

  }, [currentPath]);

  return <>{children}</>;
};

export default RouteMonitor;
