import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User, { UserStateModel } from '../../models/User';
import UserConfigs from '../../models/UserConfigs';

interface UserState {
  object: UserStateModel;
}

const initialState: UserState = {
  object: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserStateModel>) => {
      state.object = action.payload;
    },
    removeUser: (state) => {
      state.object = null;
    },
    replaceUserData: (state, action: PayloadAction<User>) => {
      if (state.object) {
        state.object = {
          ...state.object,
          user: action.payload,
        };
      }
    },
    updateUserProperties: (state, action: PayloadAction<Partial<User>>) => {
      if (state.object?.user) {
        state.object.user = { ...state.object.user, ...action.payload };
      }
    },
    replaceUserConfigsData: (state, action: PayloadAction<UserConfigs>) => {
      if (state.object) {
        state.object = {
          ...state.object,
          userConfigs: action.payload,
        };
      }
    },
    updateUserConfigsProperties: (state, action: PayloadAction<Partial<UserConfigs>>) => {
      if (state.object?.userConfigs) {
        state.object.userConfigs = { ...state.object.userConfigs, ...action.payload };
      }
    }
  }
});

export const { setUser, removeUser, replaceUserData, updateUserProperties, replaceUserConfigsData, updateUserConfigsProperties} = userSlice.actions;

export default userSlice.reducer;
