import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepLastroToroModel } from '../../models/stepLastroToro';

interface StepsLastroToroState {
  steps: StepLastroToroModel[];
}

const initialState: StepsLastroToroState = {
  steps: [],
};

const stepsLastroToroSlice = createSlice({
  name: 'stepsLastroToro',
  initialState,
  reducers: {
    setStepsLastroToro(state, action: PayloadAction<StepLastroToroModel[]>) {
      state.steps = action.payload;
    },
    addStepLastroToro(state, action: PayloadAction<StepLastroToroModel>) {
      state.steps.push(action.payload);
    },
    removeStepLastroToro(state, action: PayloadAction<number>) {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStepLastroToro(state, action: PayloadAction<{ index: number; changes: Partial<StepLastroToroModel> }>) {
      const { index, changes } = action.payload;
      const step = state.steps[index];
      if (step) {
        state.steps[index] = { ...step, ...changes };
      }
    },
    clearStepsLastroToro(state) {
      state.steps = [];
    }
  }
});

export const { setStepsLastroToro, addStepLastroToro, removeStepLastroToro, updateStepLastroToro, clearStepsLastroToro } = stepsLastroToroSlice.actions;

export default stepsLastroToroSlice.reducer;