import { LOCALES } from '../constants';
const el = {
  [LOCALES.EL]: {
    //LOGIN
    "login.Login": "Σύνδεση",
    "login.Informe seu e-mail": "Εισαγωγή του ηλεκτρονικού σας ταχυδρομείου",
    "login.E-mail inválido. Por favor, verifique": "Μη έγκυρο ηλεκτρονικό ταχυδρομείο. Παρακαλώ, ελέγξτε",
    "login.Senha": "Κωδικός πρόσβασης",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Ο κωδικός πρόσβασής σας πρέπει να έχει τουλάχιστον 6 χαρακτήρες",
    "login.Permanecer conectado": "Παραμείνετε συνδεδεμένοι",
    "login.Esqueci minha senha": "Ξεχάσα τον κωδικό μου",
    "login.ENTRAR": "ΕΙΣΟΔΟΣ",
    "login.CRIAR CONTA": "ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "Δεν ήταν δυνατή η σύνδεση. Ελέγξτε τα στοιχεία σας και δοκιμάστε ξανά",
    "login.Solicitar Acesso": "Αίτηση πρόσβασης",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Χρησιμοποιήστε αυτή την επιλογή σε περίπτωση που έχετε μόνο παλιούς φούρνους που δεν παράγουν PIN.",
    "login.Não exibir este aviso novamente": "Η ΠΛΑΤΦΟΡΜΑ IOK ΑΛΛΑΞΕ!",
    "login.Se você já possui um cadastro na antiga plataforma,": "Μην εμφανίσετε ξανά αυτή την ειδοποίηση.",
    "login.clique aqui": "Εάν έχετε ήδη εγγραφή στην προηγούμενη πλατφόρμα,",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "κάντε κλικ εδώ",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "για να μεταφέρετε τα δεδομένα σας και να απολαύσετε την πλήρη εμπειρία IOK",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Ο λογαριασμός σας δεν έχει ενεργοποιηθεί. Παρακαλώ, επαληθεύστε το ηλεκτρονικό σας ταχυδρομείο για να τον ενεργοποιήσετε.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Εάν έχετε ήδη εγγραφή στην παλιά πλατφόρμα, ",
    "login. para": " Για ",
    "login.migrar": "αποδημώ ",
    "login.seus dados e aproveitar toda a experiência IOK.": "τα δεδομένα σας και απολαύστε ολόκληρη την εμπειρία του IOK.",
    "login.PLATAFORMA EM MANUTENÇÃO": "ΠΛΑΤΦΟΡΜΑ ΥΠΟ ΣΥΝΤΗΡΗΣΗ",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "ΕΤΟΙΜΑΖΟΥΜΕ ΤΗΝ ΝΕΑ ΠΛΑΤΦΟΡΜΑ της ΙΟΚ ΚΑΙ ΘΑ ΕΙΝΑΙ ΕΤΟΙΜΗ ΣΥΝΤΟΜΑ!",
    "login.A PLATAFORMA IOK MUDOU!": "Η ΠΛΑΤΦΟΡΜΑ της ΙΟΚ ΑΛΛΑΞΕ!",
    "login.Migrar Conta": "Μετεγκατάσταση λογαριασμού",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Εάν έχετε ήδη εγγραφή στην παλιά πλατφόρμα, κάντε κλικ εδώ στο Migrate Account για να μετεγκαταστήσετε τα δεδομένα σας και να απολαύσετε ολόκληρη την εμπειρία του IOK",
    "login.Política de privacidade": "Πολιτική Απορρήτου",
    "login.ENTENDI": "ΕΝΤΑΞΕΙ",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "Χρησιμοποιούμε cookies για να βελτιώσουμε την εμπειρία σας στην πλατφόρμα και να προσφέρουμε εξατομικευμένες υπηρεσίες.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "Χρησιμοποιώντας την πλατφόρμα IOK, συμφωνείτε αυτόματα με τη χρήση της.",
    "login.Senha incorreta! Duas tentativas restantes antes de ter o acesso à conta bloqueado.": "Λάθος κωδικός! Απομένουν δύο προσπάθειες πριν αποκλειστεί η πρόσβαση στον λογαριασμό.",
    "login.Senha incorreta! Uma tentativa restantes antes de ter o acesso à conta bloqueado.": "Λάθος κωδικός! Απομένει μία προσπάθεια πριν αποκλειστεί η πρόσβαση στον λογαριασμό.",
    "login.Acesso bloqueado! Siga as instruções de recuperação de conta.": "Η πρόσβαση αποκλείστηκε! Ακολουθήστε τις οδηγίες για την ανάκτηση του λογαριασμού.",

    //REGISTER
    "DISTRIBUIDOR": "ΔΙΑΝΟΜΕΑΣ: Είμαι διανομέας εξοπλισμού Prática.",
    "REDE": "ΔΙΚΤΥΟ: Έχω ένα δίκτυο.",
    "DONO": "ΕΤΑΙΡΕΙΑ: Είμαι ιδιοκτήτης της δικής μου εταιρείας/είμαι μέρος ενός δικτύου.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "ΠΡΟΕΤΟΙΜΑΖΟΥΜΕ ΤΗ ΝΕΑ ΠΛΑΤΦΟΡΜΑ IOK ΚΑΙ ΘΑ ΕΙΝΑΙ ΕΤΟΙΜΗ ΣΥΝΤΟΜΑ!",
    "register.E-mail inválido. Por favor, verifique": "Εισάγετε το ηλεκτρονικό σας ταχυδρομείο που είναι εγγεγραμμένο στην προηγούμενη πλατφόρμα IOK",
    "register.BUSCAR DADOS": "Μη έγκυρο ηλεκτρονικό ταχυδρομείο. Παρακαλώ, ελέγξτε",
    "register.Nome da organização": "Όνομα Οργανισμού",
    "register.Informe o nome de sua organização": "Εισαγάγετε το όνομα του οργανισμού σας",
    "register.O campo nome da organização é obrigatório": "Το πεδίο του ονόματος του οργανισμού είναι υποχρεωτικό",
    "register.Este nome já existe. Por favor, escolha outro": "Αυτό το όνομα υπάρχει ήδη. Παρακαλώ, επιλέξτε ένα άλλο",
    "register.RECUPERAR CONTA": "ΕΞΑΚΡΙΒΩΣΗ ΛΟΓΑΡΙΑΣΜΟΥ",
    "register.E-mail não encontrado": "Το ηλεκτρονικό ταχυδρομείο δεν βρέθηκε",
    "register.Digite seu e-mail da plataforma IOK antiga": "Πληκτρολογήστε το ηλεκτρονικό ταχυδρομείο σας από την προηγούμενη πλατφόρμα IOK",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Δεν ήταν δυνατή η εξαγωγή του λογαριασμού σας. Επαληθεύστε τα στοιχεία σας και προσπαθήστε ξανά",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Δεν ήταν δυνατή η εξαγωγή των δεδομένων σας. Επαληθεύστε και προσπαθήστε ξανά",
    "register.PROSSEGUIR": "ΣΥΝΕΧΕΙΑ",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "ΖΗΤΑΤΕ ΠΡΟΣΒΑΣΗ ΣΤΟΝ ΟΡΓΑΝΙΣΜΟ:",
    "register.COM O ADMINISTRADOR": "ΜΕ ΔΙΑΧΕΙΡΙΣΤΗ:",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "Το ηλεκτρονικό ταχυδρομείο έχει ήδη καταχωρηθεί στην πλατφόρμα. Εισαγάγετε ένα νέο ηλεκτρονικό ταχυδρομείο ή έχετε πρόσβαση στην πλατφόρμα με το ήδη καταχωρημένο.",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "Η εταιρεία έχει ήδη καταχωρηθεί στην πλατφόρμα. Παρακαλώ, εισαγάγετε ένα νέο όνομα εταιρείας.",
    "register.CONFIRMAR": "ΔΕΝ ΑΝΑΓΝΩΡΙΣΑΤΕ ΤΟΝ ΧΡΗΣΤΗ {userName} ΩΣ ΜΕΛΟΣ ΤΗΣ ΟΡΓΑΝΩΣΗΣ ΣΑΣ ΚΑΙ ΓΙΑ ΑΥΤΟ ΟΙ ΛΟΓΑΡΙΑΣΜΟΙ ΤΟΥΣ ΔΙΑΓΡΑΦΗΚΑΝ.",
    "register.Informe o ID da organização": "ΔΕΝ ΗΤΑΝ ΔΥΝΑΤΗ Η ΔΙΑΓΡΑΦΗ ΤΟΥ ΧΡΗΣΤΗ {userName}. ΠΑΡΑΚΑΛΩ, ΠΡΟΣΠΑΘΗΣΤΕ ΞΑΝΑ ΑΡΓΟΤΕΡΑ.",
    "register.O campo com ID é obrigatório": "ΕΠΙΒΕΒΑΙΩΣΗ",
    "register.Ainda não tenho uma organização. Criar": "Εισάγετε το αναγνωριστικό του οργανισμού",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "Το πεδίο του αναγνωριστικού είναι υποχρεωτικό",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "Δεν έχω ακόμη οργάνωση. Δημιουργία",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Το ενημερωμένο αναγνωριστικό δεν αντιστοιχεί σε καμία εταιρεία. Ελέγξτε και προσπαθήστε ξανά",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "Ο ΔΙΑΧΕΙΡΙΣΤΗΣ ΤΗΣ ΟΡΓΑΝΩΣΗΣ ΘΑ ΛΑΒΕΙ ΕΝΑ ΗΛΕΚΤΡΟΝΙΚΟ ΤΑΧΥΔΡΟΜΕΙΟ ΓΙΑ ΤΗΝ ΕΝΕΡΓΟΠΟΙΗΣΗ ΤΟΥ ΧΡΗΣΤΙΚΟΥ ΣΑΣ ΛΟΓΑΡΙΑΣΜΟΥ. ΠΑΡΑΚΑΛΩ, ΠΕΡΙΜΕΝΕΤΕ",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "Αυτό το όνομα συνεταιρισμού έχει ήδη ένα ενεργό λογαριασμό στην πλατφόρμα",
    "register.Nome": "Δεν ήταν δυνατή η εγγραφή. Επαληθεύστε τα δεδομένα σας και προσπαθήστε ξανά",
    "register.Informe seu nome": "ΘΑ ΛΑΒΕΤΕ ΕΝΑ ΗΛΕΚΤΡΟΝΙΚΟ ΤΑΧΥΔΡΟΜΕΙΟ ΓΙΑ ΤΗΝ ΟΛΟΚΛΗΡΩΣΗ ΤΗΣ ΕΓΓΡΑΦΗΣ ΣΑΣ ΚΑΙ ΤΗΝ ΕΝΕΡΓΟΠΟΙΗΣΗ ΤΟΥ ΔΙΑΧΕΙΡΙΣΤΙ",
    "register.O campo nome é obrigatório": "Το πεδίο του ονόματος είναι υποχρεωτικό",
    "register.E-mail": "Ηλεκτρονικό ταχυδρομείο",
    "register.Informe seu e-mail": "Εισαγωγή του ηλεκτρονικού ταχυδρομείου σας",
    "register.E-mail inválido. Por favor, verifique novamente": "Μη έγκυρο ηλεκτρονικό ταχυδρομείο. Παρακαλώ, ελέγξτε",
    "register.Telefone": "Τηλέφωνο",
    "register.Informe seu telefone": "Εισαγωγή του αριθμού τηλεφώνου σας",
    "register.Crie sua senha": "Δημιουργία κωδικού πρόσβασης",
    "register.Informe sua senha": "Ενημέρωση του κωδικού πρόσβασής σας",
    "register.O campo senha deve conter no mínimo 6 caracteres": "Το πεδίο του κωδικού πρόσβασης πρέπει να έχει τουλάχιστον 6 χαρακτήρες",
    "register.Confirme sua senha": "Επιβεβαίωση κωδικού πρόσβασης",
    "register.As senhas devem ser iguais": "Ο κωδικός πρόσβασης πρέπει να ταιριάζει",
    "register.CRIAR CONTA": "ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ",
    "register.CONTA ATIVADA COM SUCESSO": "Ο ΛΟΓΑΡΙΑΣΜΟΣ ΕΝΕΡΓΟΠΟΙΗΘΗΚΕ ΜΕ ΕΠΙΤΥΧΙΑ",
    "register.ACESSAR": "ΠΡΟΣΒΑΣΗ",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "ΔΕΝ ΉΤΑΝ ΔΥΝΑΤΗ Η ΕΝΕΡΓΟΠΟΙΗΣΗ ΤΟΥ ΛΟΓΑΡΙΑΣΜΟΥ ΣΑΣ",
    "register.Digite seu telefone": "Ενημερώστε τον αριθμό τηλεφώνου σας",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "ΠΑΡΑΚΑΛΩ ΠΕΡΙΜΕΝΕΤΕ ΩΣΤΕ ΜΕΤΑΦΕΡΟΥΜΕ ΤΑ ΔΕΔΟΜΕΝΑ ΣΑΣ. ΑΥΤΗ Η ΔΙΑΔΙΚΑΣΙΑ ΠΡΕΠΕΙ ΝΑ ΔΙΑΡΚΕΙ ΛΙΓΑ ΛΕΠΤΑ. ΕΑΝ ΕΧΕΤΕ ΜΕΓΑΛΟ ΠΟΣΟ ΔΕΔΟΜΕΝΩΝ, Η ΜΕΤΑΦΟΡΑ ΜΠΟΡΕΙ ΝΑ ΔΙΑΡΚΕΙ ΕΩΣ ΜΙΑ ΩΡΑ.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "ΤΑ ΔΕΔΟΜΕΝΑ ΜΕΤΑΦΕΡΘΗΚΑΝ ΜΕ ΕΠΙΤΥΧΙΑ! ΚΑΝΤΕ ΚΛΙΚ ΣΤΟ ΚΟΥΜΠΙ ΚΑΤΩΤΕΡΑ ΓΙΑ ΝΑ ΑΠΟΚΤΗΣΕΤΕ ΠΡΟΣΒΑΣΗ ΣΤΗΝ ΠΛΑΤΦΟΡΜΑ.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "ΜΗ ΕΓΚΥΡΟ ΗΛΕΚΤΡΟΝΙΚΟ ΤΑΧΥΔΡΟΜΕΙΟ. ΠΑΡΑΚΑΛΩ, ΕΛΕΓΞΤΕ ΕΑΝ Ο ΛΟΓΑΡΙΑΣΜΟΣ ΣΑΣ ΕΧΕΙ ΗΔΗ ΕΝΕΡΓΟΠΟΙΗΘΕΙ.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "Η νέα πλατφόρμα IOK διαθέτει τώρα νέες μορφές πρόσβασης που σχετίζονται με τον τύπο του οργανισμού στον οποίο εργάζεστε. Ο υπεύθυνος του οργανισμού σας πρέπει να μεταφέρει τον κύριο λογαριασμό της εταιρείας, γίνοντας διαχειριστής, ενώ οι υπόλοιποι υπάλληλοι πρέπει να συνδεθούν με αυτόν χρησιμοποιώντας το παρεχόμενο αναγνωριστικό (ID).",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "Ο διαχειριστής του οργανισμού μου έχει ήδη μεταφέρει τον λογαριασμό και έχουμε ένα ID. Θέλω να μεταφέρω τα δεδομένα χρήστη μου.",
    "register.Migrar conta da organização": "Θέλω να μεταφέρω τον λογαριασμό του οργανισμού μου και να γίνω διαχειριστής.",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "ΕΧΕΤΕ ΠΟΛΛΑ ΜΕΝΟΥ, ΔΕΝ ΜΠΟΡΟΥΜΕ ΝΑ ΠΡΑΓΜΑΤΟΠΟΙΗΣΟΥΜΕ ΑΥΤΟΜΑΤΗ ΕΙΣΑΓΩΓΗ. Ο ΛΟΓΑΡΙΑΣΜΟΣ ΣΑΣ ΘΑ ΕΝΕΡΓΟΠΟΙΗΘΕΙ, ΑΛΛΑ ΘΑ ΧΡΕΙΑΖΕΤΑΙ ΝΑ ΜΕΤΑΓΡΑΦΕΙΤΕ ΧΕΙΡΟΚΙΝΗΤΑ.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "ΓΙΑ ΝΑ ΜΕΤΑΓΓΕΛΙΣΕΤΕ ΤΑ ΜΕΝΟΥ ΣΑΣ, ΜΕΤΑ ΤΗΝ ΕΙΣΑΓΩΓΗ, ΚΑΝΤΕ ΚΛΙΚ ΣΤΟ ΟΝΟΜΑ ΣΑΣ ΣΤΗΝ ΠΑΝΩ ΔΕΞΙΑ ΓΩΝΙΑ, ΚΑΝΤΕ ΚΛΙΚ ΣΤΟ ΕΠΕΞΕΡΓΑΣΙΑ ΛΟΓΑΡΙΑΣΜΟΥ ΚΑΙ ΜΕΤΑ ΣΤΑ ΜΕΝΟΥ ΕΙΣΑΓΩΓΗΣ.",
    "register.Atenção:": "Προσοχή:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "mόνο ένα άτομο από τον οργανισμό μπορεί να δημιουργήσει τον λογαριασμό και, αφού τον ενεργοποιήσει, να προσκαλέσει τους υπόλοιπους χρήστες της εταιρείας να τον προσπελάσουν.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "ΑΚΥΡΩΣΗ ΔΙΑΚΡΙΤΙΚΟΎ ΑΝΆΠΤΥΞΗΣ",
    "register.Sua senha deve estar entre 8 e 20 caracteres, ter uma letra maiúscula, uma minúscula, um número e um caractere especial" : "Ο κωδικός πρόσβασής σας πρέπει να έχει από 8 έως 20 χαρακτήρες, να περιέχει ένα κεφαλαίο γράμμα, ένα πεζό γράμμα, έναν αριθμό και έναν ειδικό χαρακτήρα",

    //HOME
    "home.Menus disponíveis na plataforma": "Μενού διαθέσιμα στην πλατφόρμα",
    "home.Criados na plataforma": "Δημιουργήθηκαν στην πλατφόρμα",
    "home.Recebidos dos fornos": "Παραλήφθηκε από τους φούρνους",
    "home.Equipamentos vinculados": "Συνδεδεμένος εξοπλισμός",
    "home.Total de receitas cadastradas": "Συνολικός αριθμός καταχωρημένων συνταγών",
    "home.Receitas": "Συνταγές",
    "home.Receitas em menus": "Συνταγές σε μενού",
    "home.Em menus": "Σε μενού",
    "home.Receitas salvas no Cookbook": "Συνταγές αποθηκευμένες στο βιβλίο μαγειρικής",
    "home.No Cookbook": "Στο βιβλίο μαγειρικής",
    "home.Atualizações": "Ενημερώσεις",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Εγκατεστημένη έκδοση",
    "home.Versão disponível": "Διαθέσιμη έκδοση",
    "home.Usuários cadastrados": "Καταχωρημένοι χρήστες",
    "home.Aceitação pendente": "Εκκρεμεί αποδοχή",
    "home.Atualizado": "Ενημερώθηκε",
    "home.OLÁ": "ΓΕΙΑ",
    "home.É muito bom ter você aqui!": "Είναι υπέροχο που είσαι εδώ!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "Με την πλατφόρμα IOK μπορείτε να διαχειρίζεστε τους φούρνους σας από όπου κι αν βρίσκεστε, να δημιουργείτε και να διαχειρίζεστε συνταγές και μενού, να δημιουργείτε αντίγραφα ασφαλείας, να αναλύετε δεδομένα από τον εξοπλισμό σας και πολλά άλλα!",
    "home.Conheça a plataforma": "Ανακαλύψτε την πλατφόρμα",
    "home.Taxa de erro por modelo": "Ποσοστό σφάλματος ανά μοντέλο",
    "home.Erro": "Λάθος",
    "home.Modelos em evidência": "Προβαλλόμενα μοντέλα",
    "home.Modelos": "Μοντέλα",
    "home.Total de equipamentos vinculados": "Συνολικός αριθμός συνδεδεμένων εξοπλισμών",
    "home.Total de equipamentos online": "Συνολικός αριθμός εξοπλισμών online",
    "home.Equipamentos online": "Εξοπλισμοί online",
    "home.Países com Prática": "Χώρες με Prática",
    "home.Países": "Χώρες",
    "home.Aguarde enquanto carregamos os dados": "Παρακαλώ περιμένετε όσο φορτώνουμε τα δεδομένα",
    "home.Quase lá! Aguarde um pouco mais": "Σχεδόν έτοιμο! Περιμένετε λίγο ακόμα",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "ΘΕΛΟΥΜΕ ΝΑ ΓΝΩΡΙΖΟΥΜΕ ΤΗΝ ΓΝΩΜΗ ΣΑΣ ΣΧΕΤΙΚΑ ΜΕ",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Εκτιμούμε πολύ την άποψή σας και, ως εκ τούτου, δημιουργήθηκε αυτή η φόρμα έρευνας ικανοποίησης στην πλατφόρμα {model}!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Εκτιμούμε πολύ την άποψή σας και, ως εκ τούτου, δημιουργήθηκε αυτή η φόρμα έρευνας ικανοποίησης σχετικά με τη γραμμή {model}!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Οι απαντήσεις σας θα μας βοηθήσουν να βελτιώσουμε τις υπηρεσίες μας, διασφαλίζοντας ότι θα συνεχίσουμε να σας παρέχουμε την καλύτερη δυνατή εμπειρία.",
    "home.Reserve uns minutinhos de seu dia e participe!": "Αφιέρωσε λίγα λεπτά από τη μέρα σου και συμμετείχε!",
    "home.Responder a pesquisa": "Απαντήστε στην έρευνα",
    "home.Vendas por Equipamento": "Πωλήσεις ανά εξοπλισμό",
    "home.Total de equipamentos vendidos": "Σύνολο εξοπλισμού που πωλήθηκε",
    "home.Equipamentos vendidos": "Πωλημένος εξοπλισμός",

    //MAIN
    "main.HOME": "ΑΡΧΙΚΗ",
    "main.COOKBOOK": "ΒΙΒΛΙΟ ΣΥΝΤΑΓΩΝ",
    "main.MEUS MENUS": "ΤΑ ΔΙΚΑ ΜΟΥ ΜΕΝΟΥ",
    "main.EQUIPAMENTOS": "ΕΞΟΠΛΙΣΜΟΣ",
    "main.USUÁRIOS": "ΧΡΗΣΤΕΣ",
    "main.ATENDIMENTOS": "ΥΠΗΡΕΣΙΕΣ",
    "main.Idioma": "Γλώσσα",
    "main.Editar conta": "Επεξεργασία λογαριασμού",
    "main.Configurações": "Ρυθμίσεις",
    "main.Sair": "Αποσύνδεση",
    "main.Configurações salvas com sucesso!": "Οι ρυθμίσεις αποθηκεύτηκαν με επιτυχία!",
    "main.SALVAR": "ΑΠΟΘΗΚΕΥΣΗ",
    "main.CONFIGURAÇÕES": "ΡΥΘΜΙΣΕΙΣ",
    "main.Tema dark": "Σκούρο θέμα",
    "main.Notificações": "Ειδοποιήσεις",
    "main.Informações para as receitas": "Πληροφορίες συνταγής",
    "main.Peso:": "Βάρος",
    "main.Gramas": "Γραμμάρια",
    "main.Oz": "Ουγγιές",
    "main.Temperatura:": "Θερμοκρασίες:",
    "main.Celsius (°C)": "Κελσίου (°C)",
    "main.Fahrenheit (°F)": "Φαρενάιτ (°F)",
    "main.Conta editada com sucesso!": "Ο λογαριασμός επεξεργάστηκε με επιτυχία!",
    "main.EDITAR CONTA": "ΕΠΕΞΕΡΓΑΣΙΑ ΛΟΓΑΡΙΑΣΜΟΥ",
    "main.Nome": "Όνομα",
    "main.Preencha com o seu nome": "Συμπληρώστε το όνομά σας",
    "main.Telefone": "Τηλέφωνο",
    "main.Preencha com o seu telefone": "Συμπληρώστε τον αριθμό τηλεφώνου σας",
    "main.E-mail": "E-mail",
    "main.Preencha com o seu e-mail": "Συμπληρώστε το ηλεκτρονικό σας ταχυδρομείο",
    "main.Alterar Senha": "Αλλαγή κωδικού πρόσβασης",
    "main.ALTERAR SENHA": "ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ",
    "main.Senha atual": "Τρέχων κωδικός πρόσβασης",
    "main.Nova senha": "Νέος κωδικός πρόσβασης",
    "main.Confirmar nova senha": "Επιβεβαίωση νέου κωδικού πρόσβασης",
    "main.Informe sua senha atual": "Εισάγετε τον τρέχοντα κωδικό πρόσβασης σας",
    "main.O campo senha deve conter no mínimo 6 caracteres": "Το πεδίο του κωδικού πρόσβασης πρέπει να έχει τουλάχιστον 6 χαρακτήρες",
    "main.Informe sua nova senha": "Εισάγετε τον νέο σας κωδικό πρόσβασης",
    "main.Confirme sua nova senha": "Επιβεβαιώστε τον νέο σας κωδικό πρόσβασης",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Ο νέος σας κωδικός πρόσβασης πρέπει να έχει τουλάχιστον 6 χαρακτήρες",
    "main.INFORME SUA SENHA": "ΕΙΣΑΓΕΤΕ ΤΟΝ ΚΩΔΙΚΟ ΣΑΣ",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Εισάγετε τον κωδικό πρόσβασής σας για να αλλάξετε το ηλεκτρονικό ταχυδρομείο της πλατφόρμας:",
    "main.CONFIRMAR": "ΕΠΙΒΕΒΑΙΩΣΗ",
    "main.Existem campos que não foram preenchidos corretamente": "Υπάρχουν πεδία που δεν έχουν συμπληρωθεί σωστά",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Εισάγετε τον κωδικό πρόσβασης σας για να συνεχίσετε με την αλλαγή του ηλεκτρονικού σας ταχυδρομείου",
    "main.Senha alterada com sucesso": "Ο κωδικός πρόσβασης άλλαξε με επιτυχία",
    "main.Informe sua senha atual corretamente": "Εισάγετε σωστά τον τρέχοντα κωδικό πρόσβασης σας",
    "main.As senhas devem ser iguais": "Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν",
    "main.Nome do novo tipo de usuário": "Όνομα νέου τύπου χρήστη",
    "main.Dê um nome para o novo tipo de usuário": "Δώστε ένα όνομα στον νέο τύπο χρήστη",
    "main.Token Desenvolvedor": "Διακριτικό Ανάπτυξης",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "Το διακριτικό ανάπτυξης είναι ένας μοναδικός και μη μεταφερόμενος κωδικός που επιτρέπει την πραγματοποίηση αλλαγών σε επίπεδο ανάπτυξης απευθείας στον κώδικα της πλατφόρμας μέσω εξωτερικών APIs. Αυτά επιτρέπουν την προσαρμογή της πλατφόρμας για να ικανοποιήσει καλύτερα τις ανάγκες της εταιρείας σας.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Συνιστάται οι αλλαγές αυτές να πραγματοποιηθούν από επαγγελματία του τομέα της τεχνολογίας.",
    "main.Para acessar o token, informe sua senha": "Για πρόσβαση στο διακριτικό, εισάγετε τον κωδικό πρόσβασής σας.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Αντιγράψτε και επικολλήστε το διακριτικό στον τρόπο ελέγχου ταυτότητας όλων των αιτημάτων.",
    "main.Seu Token": "Το διακριτικό σας",
    "main.Token copiado com sucesso!": "Το διακριτικό αντιγράφηκε με επιτυχία!",
    "main.Você deseja cancelar o preenchimento das informações?": "Θέλετε να ακυρώσετε τη συμπλήρωση των πληροφοριών;",
    "main.Rever tutoriais": "Επανεξέταση οδηγών",
    "main.Tutoriais resetados com sucesso": "Τα σεμινάρια επαναφέρθηκαν με επιτυχία",
    "main.review.tutorials": "Θέλετε να παρακολουθήσετε ξανά τα σεμινάρια της πλατφόρμας IOK?",
    "main.Procurar": "Αναζήτηση",

    //MENUS
    "menus.Procurar menu": "Μενού αναζήτησης ή χρήστη",
    "menus.Procurar menu excluído": "Αναζήτηση διαγραμμένου μενού",
    "menus.Menus": "Μενού",
    "menus.Lixeira": "Κάδος απορριμμάτων",
    "menus.CRIAR MENU": "ΔΗΜΙΟΥΡΓΙΑ ΜΕΝΟΥ",
    "menus.IMPORTAR MENU": "ΕΙΣΑΓΩΓΗ ΜΕΝΟΥ",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Τα αρχεία θα διατηρηθούν στον κάδο απορριμμάτων για 30 ημέρες από την ημερομηνία διαγραφής. Μετά από αυτήν την περίοδο, θα διαγραφούν οριστικά.",
    "menus.VOLTAR": "ΕΠΙΣΤΡΟΦΗ",
    "menus.Compatível com": "Συμβατό με",
    "menus.Última atualização": "Τελευταία ενημέρωση",
    "menus.Última atualização: ": "Τελευταία ενημέρωση: ",
    "menus.EXPORTAR MENU": "ΕΞΑΓΩΓΗ ΜΕΝΟΥ",
    "menus.EDITAR MENU": "ΕΠΕΞΕΡΓΑΣΙΑ ΜΕΝΟΥ",
    "menus.Equipamentos associados": "Συνδεδεμένος εξοπλισμός",
    "menus.Grupos": "Ομάδες",
    "menus.Procurar grupo": "Αναζήτηση ομάδας",
    "menus.CRIAR GRUPO": "ΔΗΜΙΟΥΡΓΙΑ ΟΜΑΔΑΣ",
    "menus.receitas": "συνταγές",
    "menus.Nenhum grupo cadastrado": "Καμία ομάδα εγγεγραμμένη",
    "menus.Nenhum menu cadastrado": "Κανένα μενού εγγεγραμμένο",
    "menus.Nome": "Όνομα",
    "menus.Modelo": "Μοντέλο",
    "menus.Atividade": "Δραστηριότητα",
    "menus.Associação": "Συσχέτιση",
    "menus.Exportar": "Εξαγωγή",
    "menus.Editar": "Επεξεργασία",
    "menus.Excluir": "Διαγραφή",
    "menus.Equips": "Εξοπλισμός",
    "menus.Receitas": "Συνταγές",
    "menus.Compatibilidade": "Συμβατότητα",
    "menus.Versão": "Έκδοση",
    "menus.Origem": "Προέλευση",
    "menus.Excluído por: ": "Διαγράφτηκε από: ",
    "menus.Restaurar": "Επαναφορά",
    "menus.Existem campos que não foram preenchidos": "Υπάρχουν πεδία που δεν έχουν συμπληρωθεί",
    "menus.Menu criado com sucesso!": "Το μενού δημιουργήθηκε με επιτυχία!",
    "menus.Existem campos com informações inválidas": "Υπάρχουν πεδία με μη έγκυρες πληροφορίες",
    "menus.SALVAR": "ΑΠΟΘΗΚΕΥΣΗ",
    "menus.CANCELAR": "ΑΚΥΡΩΣΗ",
    "menus.Nome do menu": "Όνομα του μενού",
    "menus.Informe o nome do menu": "Ενημέρωση ονόματος μενού",
    "menus.Preencha o nome do menu sem caracteres especiais": "Συμπληρώστε το όνομα του μενού χωρίς ειδικούς χαρακτήρες",
    "menus.Selecionar": "Επιλογή",
    "menus.Idioma": "Γλώσσα",
    "menus.Temperatura de pré-aquec 1": "Θερμοκρασία προθέρμανσης 1",
    "menus.Informe a temperatura de pré-aquec 1": "Ενημέρωση θερμοκρασίας προθέρμανσης 1",
    "menus.Temperatura de pré-aquec 2": "Θερμοκρασία προθέρμανσης 2",
    "menus.Informe a temperatura de pré-aquec 2": "Ενημέρωση θερμοκρασίας προθέρμανσης 2",
    "menus.Tempo para estabilização": "Χρόνος σταθεροποίησης",
    "menus.Informe o tempo para estabilização": "Ενημέρωση χρόνου σταθεροποίησης",
    "menus.Desativar pré-aquec 2": "Απενεργοποίηση προθέρμανσης 2",
    "menus.Configurações de data e hora": "Ρυθμίσεις ημερομηνίας και ώρας",
    "menus.Data": "Ημερομηνία",
    "menus.DD/MM/AAAA": "DD/MM/YYYY",
    "menus.Hora": "Ώρες",
    "menus.24h": "24ωρη",
    "menus.AM/PM": "AM/PM",
    "menus.EUR": "EUR: DD/MM/YYYY",
    "menus.USA": "USA: MM/DD/YYYY",
    "menus.ISO": "ISO: YYYY/MM/DD",
    "menus.Seleção de acessos para habilitar": "Επιλογή πρόσβασης για ενεργοποίηση",
    "menus.Modo manual": "Χειροκίνητη λειτουργία",
    "menus.Habilitado": "Ενεργοποιημένη",
    "menus.Desabilitado": "Απενεργοποιημένη",
    "menus.Favoritos": "Αγαπημένα",
    "menus.Aquecer/Dourar": "Θέρμανση/Καφέ",
    "menus.Repetir receita": "Επανάληψη συνταγής",
    "menus.Configurações gerais do menu": "Γενικές ρυθμίσεις μενού",
    "menus.Temperatura": "Θερμοκρασία",
    "menus.Celsius (°C)": "Κελσίου (°C)",
    "menus.Fahrenheit (°F)": "Φαρενάιτ (°F)",
    "menus.Peso": "Βάρος",
    "menus.Gramas": "Γραμμάρια",
    "menus.Oz": "Ουγγιές",
    "menus.Tema": "Θέμα",
    "menus.Dark": "Σκοτεινό",
    "menus.Light": "Φωτεινό",
    "menus.Introdução": "Εισαγωγή",
    "menus.Ativada": "Ενεργοποιημένη",
    "menus.Desativada": "Απενεργοποιημένη",
    "menus.Menu deletado com sucesso!": "Το μενού διαγράφηκε με επιτυχία!",
    "menus.Não foi possivel deletar o menu": "Αδυναμία διαγραφής μενού",
    "menus.Sim": "Ναι",
    "menus.Não": "Όχι",
    "menus.Excluir definitivamente": "Οριστική διαγραφή",
    "menus.SALVAR EM PEN DRIVE": "ΑΠΟΘΗΚΕΥΣΗ ΣΕ FLASH DRIVE",
    "menus.EXPORTAR": "ΕΞΑΓΩΓΗ",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Επιλογή μοντέλων και εκδόσεων εξοπλισμού για να λάβουν το μενού",
    "menus.Forno": "Forno",
    "menus.Fundo verde": "Πράσινο υπόβαθρο",
    "menus.Fundo preto": "Μαύρο υπόβαθρο",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Προσοχή: πριν μεταφέρετε το μενού από το USB flash drive στον εξοπλισμό, είναι απαραίτητο να αποσυμπιεστεί το αρχείο, εκτός από τα μενού του εξοπλισμού Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Επιλέξτε τον εξοπλισμό για τον οποίο θέλετε να εξάγετε το μενού.",
    "menus.Nome do equipamento": "Όνομα εξοπλισμού",
    "menus.Selecionar todos": "Επιλογή όλων",
    "menus.Selecionar todas": "Επιλογή όλων",
    "menus.Categoria: ": "Κατηγορία: ",
    "menus.Você deseja excluir o menu?": "Θέλετε να διαγράψετε το μενού;",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "Το μενού θα διαγραφεί από την πλατφόρμα, αλλά θα διατηρηθεί στον εξοπλισμό. Θα δημιουργηθεί αυτόματα αντίγραφο ασφαλείας και θα είναι διαθέσιμο για 30 ημέρες στα Μενού μου - Κάδος.",
    "menus.Você deseja excluir o arquivo definitivamente?": "Θέλετε να διαγράψετε το αρχείο οριστικά;",
    "menus.Esta ação não poderá ser desfeita.": "Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.",
    "menus.Menu editado com sucesso!": "Το μενού επεξεργάστηκε με επιτυχία!",
    "menus.IMPORTAR": "ΕΙΣΑΓΩΓΗ",
    "menus.VERIFICAÇÃO DE VERSÃO": "ΕΠΙΒΕΒΑΙΩΣΗ ΕΚΔΟΣΗΣ",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Για να ελέγξετε την έκδοση της πλακέτας του εξοπλισμού, ακολουθήστε τα παρακάτω βήματα:",
    "menus.No forno, acesse Configurações;": "Στο φούρνο, μεταβείτε στις Ρυθμίσεις;",
    "menus.Insira a senha 456789;": "Εισαγάγετε τον κωδικό πρόσβασης 456789;",
    "menus.Selecione a opção Sistema;": "Επιλέξτε την επιλογή Σύστημα;",
    "menus.Selecione Informações do Fabricante;": "Επιλέξτε Πληροφορίες Κατασκευαστή;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "Το μοντέλο της πλακέτας σας θα εμφανίζεται στη γραμμή Firmware IHM;",
    "menus.1ª geração A": "1η γενιά Α",
    "menus.Firmware IHM 12.3.4": "Firmware IHM 12.3.4",
    "menus.1ª geração B": "1η γενιά Β",
    "menus.2ª geração/Linux": "2η γενιά/Linux",
    "menus.Firmware IHM": "Firmware IHM",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Επιλέξτε το μοντέλο/έκδοση του εξοπλισμού από τον οποίο θέλετε να εισαγάγετε το μενού. Μάθετε πώς να ελέγξετε την έκδοση του εξοπλισμού σας",
    "menus.Como verificar a versão da placa": "Πώς να ελέγξετε την έκδοση της πλακέτας",
    "menus.1° Geração": "1η γενιά",
    "menus.Menu restaurado com sucesso!": "Το μενού αποκαταστάθηκε με επιτυχία!",
    "menus.Não foi possivel restaurar o menu": "Αδυναμία αποκατάστασης μενού",
    "menus.RESTAURAR ARQUIVO": "ΑΝΑΚΤΗΣΗ ΑΡΧΕΙΟΥ",
    "menus.Você deseja restaurar o arquivo?": "Θέλετε να αποκαταστήσετε το αρχείο;",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "Το αρχείο θα αποκατασταθεί ως νέα έκδοση στην πλατφόρμα στην καρτέλα Όλα. Για να το αποθηκεύσετε στη συσκευή σας, κάντε εξαγωγή.",
    "menus.EXCLUIR MENU": "ΔΙΑΓΡΑΦΗ ΜΕΝΟΥ",
    "menus.Nenhum equipamento associado": "Χωρίς συσχετισμένο εξοπλισμό",
    "menus.Nomeie o novo menu": "Ονομάστε το νέο μενού",
    "menus.Nomeie o novo grupo": "Ονομάστε τη νέα ομάδα",
    "menus.Nome do grupo": "Όνομα ομάδας",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Συμπληρώστε το όνομα της ομάδας χωρίς ειδικούς χαρακτήρες",
    "menus.Pré-aquecimento": "Προθέρμανση",
    "menus.Grupo criado com sucesso!": "Η ομάδα δημιουργήθηκε με επιτυχία!",
    "menus.SELECIONE UMA IMAGEM": "ΕΠΙΛΟΓΗ ΕΙΚΟΝΑΣ",
    "menus.SELECIONAR": "ΕΠΙΛΟΓΗ",
    "menus.Selecionar do computador": "Επιλέξτε από τον υπολογιστή",
    "menus.Selecionar do banco de imagens": "Επιλέξτε από την τράπεζα εικόνων",
    "menus.Formato .JPG ou .PNG": "Μορφή .JPG ή .PNG",
    "menus.Procurar imagem": "Αναζήτηση εικόνας",
    "menus.Pertence ao menu": "Ανήκει στο μενού",
    "menus.Alterar imagem": "Αλλαγή εικόνας",
    "menus.Remover imagem": "Αφαίρεση εικόνας",
    "menus.EDITAR GRUPO": "ΕΠΕΞΕΡΓΑΣΙΑ ΟΜΑΔΑΣ",
    "menus.CRIAR RECEITA": "ΔΗΜΙΟΥΡΓΙΑ ΣΥΝΤΑΓΗΣ",
    "menus.SALVAR DO COOKBOOK": "ΑΠΟΘΗΚΕΥΣΗ ΑΠΟ ΒΙΒΛΙΟ ΜΑΓΕΙΡΙΚΗΣ",
    "menus.Procurar receita do grupo": "Αναζήτηση συνταγής ομάδας",
    "menus.Ingrediente principal": "Κύριος συστατικός",
    "menus.Mover receitas": "Μετακίνηση συνταγών",
    "menus.Excluir receitas": "Διαγραφή συνταγών",
    "menus.Editar grupo": "Επεξεργασία ομάδας",
    "menus.Excluir grupo": "Διαγραφή ομάδας",
    "menus.aqui": "εδώ",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Εάν ο κωδικός αποτελείται μόνο από αριθμούς και το υπόβαθρο είναι πράσινο, ανήκει στην 1η A. Εάν αποτελείται μόνο από αριθμούς και το υπόβαθρο είναι μαύρο, ανήκει στην 1η B. Εάν ξεκινάει με τα γράμματα L, F ή S, η πλακέτα του ανήκει στη 2η γενιά/Linux.",
    "menus.1ª A - Fundo verde": "1η Α - Πράσινο υπόβαθρο",
    "menus.1ª B - Fundo preto": "1η Β - Μαύρο υπόβαθρο",
    "menus.2ª - Linux": "2η - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Προέκυψε ένα πρόβλημα. Δοκιμάστε ξανά αργότερα.",
    "menus.Grupo editado com sucesso!": "Η ομάδα επεξεργάστηκε με επιτυχία!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΗ ΟΜΑΔΕΣ ΕΔΩ :(",
    "menus.NENHUM MENU NA LIXEIRA": "ΚΑΝΕΝΑ ΜΕΝΟΥ ΣΤΟΝ ΚΑΔΟ",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΗ ΜΕΝΟΥ ΕΔΩ :(",
    "menus.Adicionar imagem": "Προσθήκη εικόνας",
    "menus.Nome da receita": "Όνομα συνταγής",
    "menus.Origem da receita": "Προέλευση συνταγής",
    "menus.Detalhes da receita": "Λεπτομέρειες συνταγής",
    "menus.Programação da receita": "Προγραμματισμός συνταγής",
    "menus.Nomeie sua receita": "Ονομάστε τη συνταγή σας",
    "menus.Preencha o nome da receita sem caracteres especiais": "Συμπληρώστε το όνομα της συνταγής χωρίς ειδικούς χαρακτήρες",
    "menus.Tipo de prato": "Τύπος γεύματος",
    "menus.SALVAR RECEITAS DO COOKBOOK": "ΑΠΟΘΗΚΕΥΣΗ ΣΥΝΤΑΓΩΝ ΑΠΟ ΤΟ ΒΙΒΛΙΟ ΜΑΓΕΙΡΙΚΗΣ",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu": "Επιλέξτε τις συνταγές που θέλετε να αποθηκεύσετε στην ομάδα του μενού σας:",
    "menus.Procurar receita": "Αναζήτηση συνταγής",
    "menus.Grupo deletado com sucesso!": "Η ομάδα διαγράφηκε με επιτυχία!",
    "menus.Não foi possivel deletar o grupo": "Αδυναμία διαγραφής ομάδας",
    "menus.EXCLUIR GRUPO": "ΔΙΑΓΡΑΦΗ ΟΜΑΔΑΣ",
    "menus.Você deseja excluir o grupo definitivamente": "Θέλετε να διαγράψετε οριστικά την ομάδα;",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΗ ΣΥΝΤΑΓΕΣ ΕΔΩ :(",
    "menus.Temp. de entrada": "Αρχική θερμοκρασία",
    "menus.Temp. de pré-aquec": "Θερμοκρασία προθέρμανσης",
    "menus.Ingredientes para montagem": "Συστατικά για τη συναρμολόγηση",
    "menus.Instruções operacionais": "Οδηγίες λειτουργίας",
    "menus.Passo": "Βήμα",
    "menus.Temp": "Θερμοκρασία",
    "menus.Temp.": "Θερμ. ",
    "menus.Tempo": "Χρόνος",
    "menus.Velocidade do ar": "Ταχύτητα αέρα",
    "menus.Micro-ondas": "Φούρνος μικροκυμάτων",
    "menus.Resistência inferior": "Χαμηλή αντίσταση",
    "menus.Informações": "Πληροφορίες",
    "menus.Digite as informações do passo": "Πληκτρολογήστε τις πληροφορίες του βήματος",
    "menus.desativar": "απενεργοποίηση",
    "menus.Ingredientes": "Συστατικά",
    "menus.Temperatura de entrada": "Αρχική θερμοκρασία",
    "menus.Equipamentos compatíveis": "Συμβατός εξοπλισμός",
    "menus.SALVAR NO COOKBOOK": "ΑΠΟΘΗΚΕΥΣΗ ΣΤΟ ΒΙΒΛΙΟ ΜΑΓΕΙΡΙΚΗΣ",
    "menus.ver localização": "δείτε τη θέση",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "ΕΠΙΛΟΓΗ ΣΥΝΤΑΓΩΝ ΓΙΑ ΔΙΑΓΡΑΦΗ",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "Θέλετε να διαγράψετε οριστικά τις επιλεγμένες συνταγές;",
    "menus.Receitas deletadas com sucesso!": "Οι συνταγές διαγράφηκαν με επιτυχία!",
    "menus.Informe aqui os ingredientes para montagem": "Ενημερώστε εδώ τα συστατικά για τη συναρμολόγηση",
    "menus.Informe aqui as instruções operacionais": "Ενημερώστε εδώ τις οδηγίες λειτουργίας",
    "menus.Adicionar passo": "Προσθήκη βήματος",
    "menus.Receita criada com sucesso!": "Η συνταγή δημιουργήθηκε με επιτυχία!",
    "menus.Ideal para": "Ιδανική για:",
    "menus.editar detalhes": "Επεξεργασία λεπτομερειών",
    "menus.excluir": "Διαγραφή",
    "menus.editar": "Επεξεργασία",
    "menus.Receita editada com sucesso!": "Η συνταγή επεξεργάστηκε με επιτυχία!",
    "menus.Passo deletado com sucesso!": "Το βήμα διαγράφηκε με επιτυχία!",
    "menus.Não foi possivel deletar o passo": "Αδυναμία διαγραφής βήματος",
    "menus.EXCLUIR PASSO": "ΔΙΑΓΡΑΦΗ ΒΗΜΑΤΟΣ",
    "menus.Você deseja excluir este passo": "Θέλετε να διαγράψετε αυτό το βήμα;",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Αυτή η ενέργεια δεν μπορεί να αναιρεθεί. Αν προτιμάτε, μπορείτε μόνο να το απενεργοποιήσετε, διατηρώντας τις ρυθμίσεις του.",
    "menus.Plataforma": "Πλατφόρμα",
    "menus.Receita salva com sucesso!": "Η συνταγή αποθηκεύτηκε με επιτυχία!",
    "menus.Exportar receitas": "Εξαγωγή συνταγών",
    "menus.expandir": "επεκτείνω",
    "menus.Vapor combinado": "Συνδυασμένο ατμό",
    "menus.Ar quente": "Ζεστός αέρας",
    "menus.Sonda": "Αισθητήρας",
    "menus.Tempo (min)": "Χρόνος (λεπτά)",
    "menus.Umidade (%)": "Υγρασία (%)",
    "menus.Injeção de vapor": "Εισαγωγή ατμού",
    "menus.Dumper aberto": "Άνοιγμα αντιστροφέα",
    "menus.Informe o peso": "Ενημερώστε το βάρος",
    "menus.Informe a temperatura de entrada": "Ενημερώστε την αρχική θερμοκρασία",
    "menus.Informe os ingredientes para montagem": "Εισαγωγή συστατικών για τη συναρμολόγηση",
    "menus.Informe as instruções operacionais": "Εισαγωγή των οδηγιών λειτουργίας",
    "menus.Injeção de vapor (seg)": "Εισαγωγή ατμού (δευτ.)",
    "menus.Aquecer mais": "Περισσότερη θερμότητα",
    "menus.Dourar mais": "Περισσότερο καφέ",
    "menus.Aquecer e dourar mais": "Περισσότερη θερμότητα και καφέ",
    "menus.Favoritar receita": "Αγαπημένη συνταγή",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Εισαγάγετε το χρόνο για περισσότερη θέρμανση από 0 έως 59 δευτερόλεπτα",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Εισαγάγετε το χρόνο για περισσότερο καφέ από 0 έως 59 δευτερόλεπτα",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Εισαγάγετε το χρόνο για περισσότερη θέρμανση και καφέ από 0 έως 59 δευτερόλεπτα",
    "menus.Apenas favoritos": "Μόνο αγαπημένα",
    "menus.Forno combinado": "Συνδυασμένος φούρνος",
    "menus.Cocção múltipla": "Πολλαπλή μαγειρική",
    "menus.Receitas Technicook": "Συνταγές Technicook",
    "menus.Editar grupos": "Επεξεργασία ομάδων",
    "menus.Informe a data": "Εισαγωγή ημερομηνίας",
    "menus.Informe a hora": "Εισαγωγή ώρας",
    "menus.até 300": "έως και 300",
    "menus.Vapor": "Ατμός",
    "menus.Turbina": "Τουρμπίνα",
    "menus.Criar grupo": "Δημιουργία ομάδας",
    "menus.MOVER": "ΜΕΤΑΚΙΝΗΣΗ",
    "menus.Configurações de acessos": "Πρόσβαση στις ρυθμίσεις:",
    "menus.SELECIONE RECEITAS PARA MOVER": "ΕΠΙΛΟΓΗ ΣΥΝΤΑΓΩΝ ΓΙΑ ΜΕΤΑΚΙΝΗΣΗ",
    "menus.AVANÇAR": "ΠΡΟΩΘΗΣΗ",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Επιλέξτε ή δημιουργήστε μια ομάδα στο ίδιο μενού για να μετακινήσετε τις επιλεγμένες συνταγές ή μετακινήστε τις σε άλλο μενού. Σημειώστε ότι θα εμφανιστούν μόνο τα μενού/ομάδες που είναι συμβατά με τις προεπιλογές της συνταγής.",
    "menus.Mover receitas para o mesmo menu": "Μετακίνηση συνταγών στο ίδιο μενού",
    "menus.Mover receitas para outro menu": "Μετακίνηση συνταγών σε άλλο μενού",
    "menus.Selecionar grupo": "Επιλέξτε ομάδα",
    "menus.Nenhuma receita foi selecionada": "Καμία συνταγή δεν επιλέχθηκε",
    "menus.Passo editado com sucesso!": "Το βήμα επεξεργάστηκε με επιτυχία!",
    "menus.Menu atualizado": "Το μενού ενημερώθηκε",
    "menus.até 260": "έως 260",
    "menus.Umidade na câmara": "Υγρασία θαλάμου",
    "menus.Passos": "Βήματα",
    "menus.ativar": "ενεργοποίηση",
    "menus.Informe a temperatura de pré-aquec": "Εισαγωγή θερμοκρασίας προθέρμανσης",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Μετακίνηση των επιλεγμένων συνταγών σε άλλο μενού. Σημειώστε ότι θα εμφανιστούν μόνο τα μενού που είναι συμβατά με τις προεπιλογές της συνταγής.",
    "menus.Sonda (°C)": "Αισθητήρας (ºC)",
    "menus.Turbo Grill": "Τουρμπο-Γκριλ",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Κανονική προθέρμανση, σύμφωνα με το βήμα 1",
    "menus.salvar": "αποθήκευση",
    "menus.até": "έως",
    "menus.Verificação de receita": "Έλεγχος συνταγής",
    "menus.Auto import": "Αυτόματη εισαγωγή",
    "menus.Atualização forçada": "Υποχρεωτική ενημέρωση",
    "menus.Lista de receitas exportada com sucesso!": "Επιτυχής εξαγωγή λίστας συνταγών!",
    "menus.Receita movida com sucesso!": "Επιτυχής μετακίνηση συνταγής!",
    "menus.Receitas movidas com sucesso!": "Οι συνταγές μετακινήθηκαν με επιτυχία!",
    "menus.+ Receita": "Συνταγή",
    "menus.Escolha um idioma compatível com o equipamento": "Επιλέξτε μια γλώσσα συμβατή με τον εξοπλισμό",
    "menus.Dourar": "Καφέ",
    "menus.+ Menu": "+ Μενού",
    "menus.Selecione o modelo/versão do equipamento": "Επιλέξτε το μοντέλο/έκδοση του εξοπλισμού",
    "menus.MENU IMPORTADO COM SUCESSO!": "Το μενού εισήχθη με επιτυχία!",
    "menus.Não atribuir a menu": "Μην εκχωρείτε στο μενού",
    "menus.Todos": "Όλα",
    "menus.Meus menus": "Τα Μενού μου",
    "menus.Para salvar uma receita, preencha todos os campos": "Για να αποθηκεύσετε μια συνταγή, συμπληρώστε όλα τα πεδία",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Επιλέξτε τις ομάδες εξοπλισμού για τις οποίες θέλετε να εξαγάγετε το μενού.",
    "menus.Você deseja excluir este passo?": "Θέλετε να διαγράψετε αυτό το βήμα;",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Μη έγκυρη μορφή. Επιτρέπονται μόνο οι μορφές .jpeg και .png",
    "menus.Reordenar grupos": "Αναδιάταξη ομάδων",
    "menus.Reordenar receitas": "Αναδιάταξη συνταγών",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ΤΑ ΣΤΟΙΧΕΙΑ ΑΝΑΔΙΑΤΑΧΘΗΚΑΝ ΕΠΙΤΥΧΩΣ! ΓΙΑ ΝΑ ΕΦΑΡΜΟΣΕΤΕ ΤΙΣ ΑΛΛΑΓΕΣ ΣΤΟ ΦΟΥΡΝΟ, ΕΞΑΓΕΤΕ ΤΟ ΜΕΝΟΥ.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Σύρετε τα στοιχεία στην επιθυμητή θέση.",
    "menus.Dumper": "Νταμπέρ",
    "menus.Aberto": "Ανοιξε",
    "menus.Fechado": "Κλειστό",
    "menus.Intermitente": "Διακοπτόμενη",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Εάν το μενού σας περιλαμβάνει στοιχεία που είναι ευαίσθητα στην ομοιομορφία, συνιστούμε να ρυθμίσετε την Ώρα Τακτοποίησης μεταξύ 3:00 και 5:00 λεπτών. Διαφορετικά μπορείτε να το αφήσετε στα 0:00 λεπτά.",
    "menus.Selecione o menu que deseja exportar:": "Επιλέξτε το μενού που θέλετε να εξαγάγετε:",
    "menus.Temperatura de pré-aquecimento:": "Θερμοκρασία προθέρμανσης:",
    "menus.Temperatura de lastro": "Θερμοκρασία έρματος",
    "menus.Temperatura de teto": "Θερμοκρασία οροφής",
    "menus.Volume de injeção de vapor (ml)": "Όγκος έγχυσης ατμού (ml)",
    "menus.Parada de turbina": "Παύση τουρμπίνας",
    "menus.Alta": "Υψηλή",
    "menus.Média": "Μέτρια",
    "menus.Baixa": "Χαμηλή",
    "menus.a": "ο",
    "menus.Valor da injeção": "Τιμή ένεσης",
    "menus.Volume: até 999ml": "Όγκος: έως 999 ml",
    "menus.Tempo: até 15 segundos": "Χρόνος: έως 15 δευτερόλεπτα",
    "menus.Tempo de injeção de vapor (seg)": "Χρόνος έγχυσης ατμού (δευτ.)",
    "menus.Atenção!": "Προσοχή!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Ελέγξτε τις συνταγές μενού και προσαρμόστε τις τιμές Steam Injection αφού αλλάξετε τη μονάδα μέτρησής σας.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Βεβαιωθείτε ότι η μονάδα θερμοκρασίας (°C ή °F) που ορίζεται στον εξοπλισμό είναι η ίδια με αυτήν που επιλέχθηκε κατά τη δημιουργία του μενού:",
    "menus.Injeção desativada": "Η ένεση απενεργοποιήθηκε",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Επιλέξτε τη μονάδα θερμοκρασίας που ορίζεται στον εξοπλισμό:",
    "menus.Clique para atualizar": "Κάντε κλικ για ενημέρωση",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados, e que possuam versão compatível com este tipo de atualização, na próxima vez em que forem ligados. Os fornos que não estiverem atualizados ainda precisarão realizar o aceite manual.": "Η λειτουργία Αναγκαστικής ενημέρωσης στέλνει αυτόματα το μενού στον επιλεγμένο εξοπλισμό, ο οποίος έχει έκδοση συμβατή με αυτόν τον τύπο ενημέρωσης, την επόμενη φορά που θα ενεργοποιηθεί. Οι φούρνοι που δεν έχουν ενημερωθεί θα πρέπει να υποβληθούν σε χειροκίνητη αποδοχή.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Οι επιλεγμένες επιλογές θα είναι διαθέσιμες σε εξοπλισμό που λαμβάνει αυτό το μενού και μπορούν να χρησιμοποιηθούν από τον χειριστή. Μπορείτε να αλλάξετε αυτές τις ρυθμίσεις αργότερα στον ίδιο τον φούρνο ή μέσω της πλατφόρμας.",
    "menus.Não foi possivel deletar as receitas": "Δεν είναι δυνατή η διαγραφή συνταγών",
    "menus.PRÉ": "ΠΡΟ",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "Συνδυαστικοί Φούρνοι",
    "menus.Fornos de Panificação": "Φούρνοι Αρτοποιίας",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "Μετά τη δημιουργία, ο τύπος του μενού δεν μπορεί να αλλάξει. Μπορεί να οριστεί μόνο κατά τη στιγμή της δημιουργίας.",
    "menus.Pré-aquecimento do grupo": "Προθέρμανση ομάδας",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, transferindo a pasta PRATICA para o equipamento." : "Προσοχή: πριν μεταφέρετε το μενού από τη μονάδα στυλό στον εξοπλισμό, πρέπει να αποσυμπιέσετε το αρχείο, μεταφέροντας τον φάκελο PRATICA στον εξοπλισμό.",
    "menus.Tamanho máximo": "Μέγιστο μέγεθος",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "ΔΙΑΘΕΣΙΜΕΣ ΣΥΝΤΑΓΕΣ",
    "cookbook.RECEITAS SALVAS": "ΑΠΟΘΗΚΕΥΜΕΝΕΣ ΣΥΝΤΑΓΕΣ",
    "cookbook.ENVIAR PARA MENU": "ΑΠΟΣΤΟΛΗ ΣΕ ΜΕΝΟΥ",
    "cookbook.SALVAR": "ΑΠΟΘΗΚΕΥΣΗ",
    "cookbook.salvar": "Αποθήκευση",
    "cookbook.Filtrar por": "Φιλτράρισμα κατά",
    "cookbook.Procurar receita do cookbook": "Αναζήτηση συνταγής από το βιβλίο συνταγών",
    "cookbook.Equipamento": "Εξοπλισμός",
    "cookbook.Ingrediente principal": "Κύριος συστατικό",
    "cookbook.Tipo de prato": "Τύπος γεύματος",
    "cookbook.Região": "Περιοχή",
    "cookbook.Pré-aquecimento": "Προθέρμανση",
    "cookbook.Procurar receita": "Αναζήτηση συνταγής",
    "cookbook.Menu": "Μενού",
    "cookbook.ENVIAR": "ΑΠΟΣΤΟΛΗ",
    "cookbook.Procurar nome do menu": "Αναζήτηση ονόματος μενού",
    "cookbook.Escolha um grupo deste menu": "Επιλογή ομάδας από αυτό το μενού",
    "cookbook.Selecionar grupo": "Επιλογή ομάδας",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "Παρουσιάστηκε πρόβλημα. Δοκιμάστε ξανά αργότερα",
    "cookbook.Receita adicionada com sucesso!": "Η συνταγή προστέθηκε με επιτυχία!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "Δεν ήταν δυνατή η προσθήκη της συνταγής. Δοκιμάστε ξανά αργότερα",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Επιλέξτε ένα μενού και μια ομάδα για να στείλετε αυτήν τη συνταγή",
    "cookbook.Selecione o modelo do equipamento primeiro": "Επιλέξτε πρώτα το μοντέλο του εξοπλισμού",
    "cookbook.Grupo": "Ομάδα",
    "cookbook.Não foi possível salvar os passos da receita": "Δεν ήταν δυνατή η αποθήκευση των βημάτων της συνταγής",
    "cookbook.Bovina": "Βοδινό",
    "cookbook.Frango": "Κοτόπουλο",
    "cookbook.Ovos": "Αυγά",
    "cookbook.Pães": "Ψωμιά",
    "cookbook.Peixes": "Ψάρι",
    "cookbook.Queijo": "Τυρί",
    "cookbook.Suína": "Χοιρινό",
    "cookbook.Vegetais": "Λαχανικά",
    "cookbook.Outros": "Άλλα",
    "cookbook.Entradas/aperitivos": "Ορεκτικά",
    "cookbook.Principais": "Κύριο πιάτο",
    "cookbook.Snacks": "Σνακ",
    "cookbook.Pizzas": "Πίτσες",
    "cookbook.Massas": "Πάστες",
    "cookbook.Acompanhamentos": "Παρεψηματικά",
    "cookbook.Sobremesas": "Επιδόρπια",
    "cookbook.Manhã": "Πρωινό",
    "cookbook.Padaria": "Φούρνος",
    "cookbook.Todos": "Όλα",
    "cookbook.Não atribuir origem": "Να μην αποδίδεται καταγωγή",
    "cookbook.Brasil": "Βραζιλία",
    "cookbook.Alemanha": "Γερμανία",
    "cookbook.França": "Γαλλία",
    "cookbook.Rússia": "Ρωσία",
    "cookbook.China": "Κίνα",
    "cookbook.Japão": "Ιαπωνία",
    "cookbook.Itália": "Ιταλία",
    "cookbook.Egito": "Αίγυπτος",
    "cookbook.Grécia": "Ελλάδα",
    "cookbook.Argentina": "Αργεντινή",
    "cookbook.África": "Αφρική",
    "cookbook.Estados Unidos": "Ηνωμένες Πολιτείες",
    "cookbook.Coreia": "Κορέα",
    "cookbook.Turquia": "Τουρκία",
    "cookbook.Ideal para": "Ιδανικό για",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Εξοπλισμός",
    "equipment.procurar equipamento": "Αναζήτηση εξοπλισμού ή χρήστη",
    "equipment.adicionar equipamento": "Προσθήκη εξοπλισμού",
    "equipment.atualizar equipamento": "Ενημέρωση εξοπλισμού",
    "equipment.+ equipamentos": "Εξοπλισμός",
    "equipment.buscar equipamento": "Αναζήτηση εξοπλισμού",
    "equipment.Desvincular": "Αποσύνδεση",
    "equipment.Editar": "Επεξεργασία",
    "equipment.sem conteúdo": "Δεν υπάρχει ακόμη εξοπλισμός εδώ :(",
    "equipment.editar equipamento": "Επεξεργασία εξοπλισμού",
    "equipment.equipamento editado com sucesso!": "Επεξεργασία εξοπλισμού με επιτυχία!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Δεν ήταν δυνατή η επεξεργασία του εξοπλισμού. Δοκιμάστε ξανά αργότερα",
    "equipment.Menu exportado com sucesso!": "Το μενού εξήχθη με επιτυχία!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Δεν ήταν δυνατή η εξαγωγή του μενού. Δοκιμάστε ξανά αργότερα",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Επιλέξτε τουλάχιστον έναν εξοπλισμό για να στείλετε το μενού",
    "equipment.Informações do menu": "Πληροφορίες μενού",
    "equipment.Procurar receita ou grupo": "Αναζήτηση συνταγής ή ομάδας",
    "equipment.Grupo": "Ομάδα",
    "equipment.Receita": "Συνταγή",
    "equipment.Quantidade": "Ποσότητα",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Οι πιο προετοιμασμένες συνταγές στον εξοπλισμό κατά τη διάρκεια της περιόδου",
    "equipment.Informações de limpeza": "Πληροφορίες καθαρισμού",
    "equipment.Data da limpeza": "Ημερομηνία καθαρισμού",
    "equipment.Hora da limpeza": "Ώρα καθαρισμού",
    "equipment.nome": "Όνομα",
    "equipment.modelo": "Μοντέλο",
    "equipment.categoria": "Κατηγορία",
    "equipment.loja": "Κατάστημα",
    "equipment.desvincular equipamento": "Αποσύνδεση εξοπλισμού",
    "equipment.unlink.paragrafo 1": "Θέλετε να αποσυνδέσετε τον εξοπλισμό από την πλατφόρμα IOK;",
    "equipment.unlink.paragrafo 2": "Κάνοντας αυτό, δεν θα είναι δυνατή η διαχείριση των ρυθμίσεών σας και του μενού σας μέσω της πλατφόρμας, εκτός αν συνδέσετε ξανά τον εξοπλισμό, ακολουθώντας τις οδηγίες σύνδεσης.",
    "equipamento desvinculado com sucesso!": "Ο εξοπλισμός αποσυνδέθηκε με επιτυχία!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Παρουσιάστηκε σφάλμα. Δοκιμάστε ξανά αργότερα.",
    "equipment.sim": "Ναι",
    "equipment.não": "Όχι",
    "equipment.Encerrar acesso remoto": "Λήξη απομακρυσμένης πρόσβασης",
    "equipment.Horas sem limpeza": "Ώρες χωρίς καθαρισμό",
    "equipment.Histórico de eventos": "Ιστορικό συμβάντων",
    "equipment.Evento": "Συμβάν",
    "equipment.Data": "Ημερομηνία",
    "equipment.ERRO SENSOR RI": "Σφάλμα με τον αισθητήρα χαμηλότερης αντίστασης",
    "equipment.ERRO COM INV. DE FREQ.": "Σφάλμα με τον συντονιστή συχνότητας",
    "equipment.ERRO MAGNETRON": "Σφάλμα μαγνητρονίου",
    "equipment.ERRO SENSOR CAMARA": "Σφάλμα με τον αισθητήρα κάμερας",
    "equipment.ERRO CAN": "Σφάλμα CAN",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Σφάλμα αντιστροφέα συχνότητας",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Σφάλμα μοντέλου εξοπλισμού",
    "equipment.ERRO PAINEL": "Σφάλμα πίνακα",
    "equipment.ERRO INV.FREQ.STATUS": "Κατάσταση σφάλματος αντιστροφέα συχνότητας",
    "equipment.ERRO SENSOR BOARD": "Σφάλμα αισθητήρα πίνακα",
    "equipment.ERRO ENERGY": "Σφάλμα ενέργειας",
    "equipment.ERRO I2C": "Σφάλμα I2C",
    "equipment.EQUIPAMENTO LIGADO": "Ενεργοποίηση εξοπλισμού",
    "equipment.EQUIPAMENTO DESLIGADO": "Απενεργοποίηση εξοπλισμού",
    "equipment.Plataforma": "Πλατφόρμα",
    "equipment.Forno": "Φούρνος",
    "equipment.Origem": "Προέλευση",
    "equipment.Locais": "Τοποθεσίες",
    "equipment.não foi possível carregar as entidades": "Δεν ήταν δυνατή η φόρτωση των οντοτήτων",
    "equipment.conceder acesso remoto a": "Παραχώρηση απομακρυσμένης πρόσβασης σε",
    "equipment.atualizar equipamentos": "Ενημέρωση εξοπλισμού",
    "equipment.suporte": "Υποστήριξη",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Όνομα εξοπλισμού",
    "equipment.vinculado à loja": "Συνδεδεμένο με το κατάστημα",
    "equipment.cadastrar loja": "Εγγραφή καταστήματος",
    "equipment.informe o PIN do forno": "Εισαγωγή PIN φούρνου",
    "equipment.preencha o PIN do forno": "Συμπληρώστε το PIN του φούρνου",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Προσθέστε ένα όνομα για να το αναγνωρίσετε πιο εύκολα",
    "equipment.preencha o nome do equipamento": "Συμπληρώστε το όνομα του εξοπλισμού",
    "equipment.selecionar": "Επιλογή",
    "equipment.nome fantasia": "Φανταστικό όνομα",
    "equipment.informe o nome fantasia": "Εισαγωγή του φανταστικού ονόματος",
    "equipment.preencha o nome fantasia": "Συμπληρώστε το φανταστικό όνομα",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Συμπληρώστε το CNPJ",
    "equipment.CEP": "Ταχυδρομικός κώδικας",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Συμπληρώστε τον ταχυδρομικό κώδικα",
    "equipment.cidade": "Πόλη",
    "equipment.informe a cidade": "Εισαγωγή της πόλης",
    "equipment.preencha a cidade": "Συμπληρώστε την πόλη",
    "equipment.UF": "Πολιτεία",
    "equipment.bairro": "Γειτονιά",
    "equipment.informe o bairro": "Εισαγωγή γειτονιάς",
    "equipment.endereço": "Διεύθυνση",
    "equipment.informe o seu endereço": "Εισαγωγή της διεύθυνσής σας",
    "equipment.preencha o seu endereço": "Συμπληρώστε τη διεύθυνσή σας",
    "equipment.Nº": "Αρ.",
    "equipment.equipamento cadastrado com sucesso!": "Ο εξοπλισμός καταχωρήθηκε με επιτυχία!",
    "equipment.ocorreu um erro": "Παρουσιάστηκε σφάλμα",
    "equipment.preencha o modelo": "Συμπληρώστε το μοντέλο",
    "equipment.selecione uma categoria": "Επιλέξτε μια κατηγορία",
    "equipment.selecione uma loja": "Επιλέξτε ένα κατάστημα",
    "equipment.preencha o bairro": "Συμπληρώστε τη γειτονιά",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "Δεν ήταν δυνατή η εγγραφή του εξοπλισμού. Δοκιμάστε ξανά αργότερα",
    "equipment.loja cadastrada com sucesso": "Το κατάστημα καταχωρήθηκε με επιτυχία",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "Δεν ήταν δυνατή η εγγραφή του καταστήματος. Δοκιμάστε ξανά αργότερα",
    "equipment.equipment": "εξοπλισμός",
    "equipment.stores": "καταστήματα",
    "equipment.search equipment": "αναζήτηση εξοπλισμού",
    "equipment.add equipment": "Προσθήκη εξοπλισμού",
    "equipment.update equipment": "Ενημέρωση εξοπλισμού",
    "equipment.plus equipment": "Εξοπλισμός",
    "equipment.Procurar equipamento": "Αναζήτηση εξοπλισμού",
    "equipment.EQUIPAMENTOS": "ΕΞΟΠΛΙΣΜΟΣ",
    "equipment.Atualizar equipamento": "Ενημέρωση εξοπλισμού",
    "equipment.Atualizar equipamentos": "Ενημέρωση εξοπλισμού",
    "equipment.confirmar": "Επιβεβαίωση",
    "equipment.Selecione os modelos que deseja atualizar:": "Επιλέξτε τα μοντέλα που θέλετε να ενημερώσετε",
    "equipment.Como deseja realizar a atualização?": "Πώς θέλετε να προχωρήσετε με την ενημέρωση;",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Αποστολή ενημέρωσης μέσω πλατφόρμας (Wi-Fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Αποθηκεύστε το αρχείο ενημέρωσης σε στικάκι",
    "equipment.Linha Express": "Γραμμή Express",
    "equipment.Escolha como deseja realizar a atualização": "Επιλέξτε πώς θέλετε να προχωρήσετε με την ενημέρωση",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Επιλέξτε τουλάχιστον ένα μοντέλο εξοπλισμού για ενημέρωση",
    "equipment.Status:": "Κατάσταση: ",
    "equipment.Número de série:": "Αριθμός σειράς",
    "equipment.Menu:": "Μενού",
    "equipment.Loja:": "Κατάστημα",
    "equipment.End:": "Λήξη: ",
    "equipment.Cidade:": "Πόλη: ",
    "equipment.CEP:": "Ταχυδρομικός κώδικας: ",
    "equipment.Atualização de software enviada com sucesso!": "Η ενημέρωση του λογισμικού στάλθηκε με επιτυχία!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "Δεν ήταν δυνατή η αποστολή της ενημέρωσης του λογισμικού. Δοκιμάστε ξανά αργότερα.",
    "equipment.Download realizado com sucesso": "Η λήψη ολοκληρώθηκε με επιτυχία",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Δεν ήταν δυνατή η ολοκλήρωση της λήψης. Δοκιμάστε ξανά αργότερα",
    "equipment.name": "Όνομα",
    "equipment.model": "Μοντέλο",
    "equipment.category": "Κατηγορία",
    "equipment.store": "Κατάστημα",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Συμπληρώστε το όνομα της έκδοσης",
    "equipment.Informe aqui todas as alterações desta nova versão": "Εισαγωγή όλων των αλλαγών σε αυτήν τη νέα έκδοση",
    "equipment.Nova versão de software atualizada com sucesso!": "Η νέα έκδοση λογισμικού ενημερώθηκε με επιτυχία!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Εισαγάγετε τουλάχιστον μία αλλαγή σε αυτήν την έκδοση",
    "equipment.Falha ao ler o arquivo": "Απέτυχε η ανάγνωση του αρχείου",
    "equipment.selecionar tipo": "Επιλέξτε τύπο",
    "equipment.selecione o tipo da versão": "Επιλογή τύπου έκδοσης",
    "equipment.atualização": "ΕΝΗΜΕΡΩΣΗ",
    "equipment.Selecione abaixo o que deseja fazer:": "Επιλέξτε παρακάτω τι θέλετε να κάνετε:",
    "equipment.Atualizar meus equipamentos": "Ενημέρωση του εξοπλισμού μου",
    "equipment.Enviar atualização para usuários selecionados": "Αποστολή ενημέρωσης σε επιλεγμένους χρήστες",
    "equipment.ENVIAR": "ΑΠΟΣΤΟΛΗ",
    "equipment.ENVIAR ATUALIZAÇÃO": "ΑΠΟΣΤΟΛΗ ΕΝΗΜΕΡΩΣΗΣ",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Επιλέξτε τα e-mails στα οποία θέλετε να στείλετε την ενημέρωση:",
    "equipment.A atualização disponibilizada no dia": "Η διαθέσιμη ενημέρωση την ημέρα",
    "equipment.referente ao equipamento": "σχετικά με τον εξοπλισμό",
    "equipment.contempla o seguinte:": "λαμβάνει υπόψη τα εξής:",
    "equipment.Selecionar arquivo para envio": "Επιλέξτε το αρχείο που θέλετε να στείλετε",
    "equipment.Por favor, selecione um arquivo .tar": "Παρακαλώ επιλέξτε ένα αρχείο .tar",
    "equipment.Selecione o modelo do equipamento:": "Επιλέξτε το μοντέλο του εξοπλισμού:",
    "equipment.Selecione uma opção": "Επιλέξτε μια επιλογή",
    "equipment.Nomeie a versão": "Ονομάστε την έκδοση",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "είναι",
    "equipment.Últimas versões de software:": "Τελευταίες εκδόσεις λογισμικού: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "Δεν έχετε ξεπερασμένο εξοπλισμό",
    "equipment.grupos": "ομάδες",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Δεν είναι δυνατή η φόρτωση ομάδων εξοπλισμού εταιρείας",
    "equipment.vinculado ao grupo": "Συνδέεται με την ομάδα",
    "equipment.selecione um grupo": "Επιλέξτε μια ομάδα",
    "equipment.cadastrar grupo": "Εγγραφή ομάδας",
    "equipment.Número de Série": "Αριθμός Σειράς",
    "equipment.Versão": "Έκδοση",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Δεν ήταν δυνατή η εγγραφή του εξοπλισμού. Επιλέξτε ή εγγραφείτε σε μια ομάδα",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "ΔΗΜΙΟΥΡΓΗΣΤΕ ΟΜΑΔΕΣ ΕΞΟΠΛΙΣΜΟΥ!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Για να μπορούν οι χρήστες που συνδέονται με τον λογαριασμό να βλέπουν τον εξοπλισμό, χρειάζεστε:",
    "equipment.1) Criar grupo(s);": "1) Δημιουργία ομάδας(ων);",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Ανάθεση εξοπλισμού στην ομάδα;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Ανάθεση χρηστών στην ομάδα.",
    "equipment.Status software": "Κατάσταση λογισμικού",
    "equipment.Status menu": "Κατάσταση μενού",
    "equipment.Pendente": "Εκκρεμής",
    "equipment.Aceito": "Αποδεκτό",
    "equipment.Recusado": "Απορρίφθηκε",
    "equipment.Atualizar": "Ενημέρωση",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Θέλετε να στείλετε την ενημέρωση στην επιλεγμένη συσκευή;",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Κάντε κλικ στο αρχείο που θέλετε να κατεβάσετε:",
    "equipment.Baixar atualização": "Λήψη ενημέρωσης",
    "equipment.Versões": "Εκδόσεις",
    "equipment.e superiores": "και ψηλότερα",
    "equipment.Companhia": "Εταιρία",
    "equipment.Exportar menu": "Μενού εξαγωγής",
    "equipment.companhias": "Εταιρείες",
    "equipment.Nome da companhia": "Ονομα εταιρείας",
    "equipment.Procurar equipamento, usuário ou companhia": "Αναζήτηση εξοπλισμού, χρήστη ή εταιρείας",
    "equipment.Versão da power": "power έκδοση",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΑ ΣΤΟΙΧΕΙΑ ΣΥΝΤΑΓΗΣ ΕΔΩ :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΑ ΔΕΔΟΜΕΝΑ ΚΑΘΑΡΙΣΜΟΥ :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΑ ΔΕΔΟΜΕΝΑ ΕΚΔΗΛΩΣΗΣ ΕΔΩ :(",
    "ID": "ID",
    "equipment.Hora": "ώρα",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "ΠΕΡΙΣΣΟΤΕΡΑ ΓΕΓΟΝΟΤΑ ΣΤΟΝ ΕΞΟΠΛΙΣΜΟ ΚΑΤΑ ΤΗΝ ΠΕΡΙΟΔΟ",
    "equipment.hour": "Ω",
    "equipment.minutes": "Λεπ",
    "equipment.date format": "ΗΗ/ΜΜ/ΕΕΕΕ",
    "equipment.preparada": "προετοιμασμένη",
    "equipment.vezes": "Φορές",
    "equipment.vez": "Φορά",
    "equipment.Ver": "Προβολή",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "Ο εξοπλισμός θα στείλει δεδομένα στην πλατφόρμα όταν ενεργοποιηθεί. Κάθε δύο μήνες, τα δεδομένα που λαμβάνονται θα διαγράφονται αυτόματα.",
    "equipment.Ligado": "Ενεργοποιημένο",
    "equipment.Desligado": "Απενεργοποιημένο",
    "equipment.CAN ERROR": "Σφάλμα CAN",
    "equipment.CAMERA SENSOR ERROR": "Σφάλμα αισθητήρα κάμερας",
    "equipment.STEAM/RI SENSOR ERROR": "Σφάλμα αισθητήρα RI",
    "equipment.PROBE 1 ERROR": "Προειδοποίηση ανίχνευσης 1",
    "equipment.PROBE 2 ERROR": "Προειδοποίηση ανίχνευσης 2",
    "equipment.PROBE 3 ERROR": "Προειδοποίηση ανίχνευσης 3",
    "equipment.PROBE 4 ERROR": "Προειδοποίηση ανίχνευσης 4",
    "equipment.PROBE 5 ERROR": "Προειδοποίηση ανίχνευσης 5",
    "equipment.PROBE 6 ERROR": "Προειδοποίηση ανίχνευσης 6",
    "equipment.GENERAL PROBE ERROR": "Γενικό σφάλμα ανίχνευσης",
    "equipment.WATER LACK ERROR": "Προειδοποίηση για έλλειψη νερού",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Ειδοποίηση υψηλής θερμοκρασίας στο ταμπλό",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Σφάλμα μετατροπέα συχνότητας",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Ειδοποίηση επικοινωνίας στον μετατροπέα συχνότητας",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Ειδοποίηση για την κατάσταση του μετατροπέα συχνότητας",
    "equipment.CLEANING NOT COMPLETED ERROR": "Ειδοποίηση ελλιπούς καθαρισμού",
    "equipment.MAGNETRON ERROR": "Ειδοποίηση Magnetron",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Σφάλμα μοντέλου εξοπλισμού",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Ειδοποίηση αισθητήρα θερμοκρασίας πλακέτας",
    "equipment.ENERGY MODULE ERROR": "Σφάλμα μονάδας ισχύος",
    "equipment.POWER BOARD I2C BUS ERROR": "Σφάλμα πλακέτας ισχύος",
    "equipment.GAS SYSTEM ERROR": "Σφάλμα αερίου",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Σφάλμα εξαιρετικά υψηλής θερμοκρασίας στον πίνακα",
    "equipment.POWER ON": "Συνδεδεμένος",
    "equipment.POWER OFF": "Μακριά από",
    "equipment.DOOR OPENED": "Ανοιχτή πόρτα",
    "equipment.DOOR CLOSED": "Κλειστή πόρτα",
    "equipment.CLEANING CANCELED ERROR": "Η απολύμανση ακυρώθηκε από τον χρήστη",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "Το PIN δεν βρέθηκε. Παρακαλούμε ελέγξτε και προσπαθήστε ξανά",
    "equipment.Online": "Σε σύνδεση",
    "equipment.Offline": "Εκτός σύνδεσης",
    "equipment.Exibir apenas eventos": "Εμφάνιση μόνο συμβάντων",
    "equipment.Endereço": "Διεύθυνση",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Εξοπλισμός συνδεδεμένος στην πλατφόρμα",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Ο εξοπλισμός αποσυνδέθηκε από την πλατφόρμα",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Το μενού ελήφθη από την πλατφόρμα",
    "equipment.MENU REJECTED BY THE USER": "Μενού απόρριψης χρήστη",
    "equipment.CONNECTED TO THE INTERNET": "Συνδεδεμένο στο διαδίκτυο",
    "equipment.DISCONNECTED FROM THE INTERNET": "Αποσυνδέθηκε από το διαδίκτυο",
    "equipment.Infelizmente, não há versões compatíveis com este equipamento que realizem o envio de dados.": "Δυστυχώς, δεν υπάρχουν εκδόσεις συμβατές με αυτόν τον εξοπλισμό που να μπορούν να στείλουν δεδομένα.",
    "equipment.O equipamento": "Ο εξοπλισμός",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "πρέπει να ενημερωθεί στην πιο πρόσφατη έκδοση λογισμικού ώστε να αρχίσει να στέλνει δεδομένα στην πλατφόρμα",
    "equipment.Versão do seu equipamento": "Έκδοση του εξοπλισμού σας",
    "equipment.Versão disponível": "Διαθέσιμη έκδοση",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Επιλέξτε παρακάτω πώς θέλετε να στείλετε την ενημέρωση στον εξοπλισμό σας",
    "equipment.Salvar em pen drive": "Αποθήκευση σε στικάκι USB",
    "equipment.Enviar por wi-fi": "Αποστολή μέσω Wi-Fi",
    "equipment.Cliente": "Πελάτης",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "Ο ΣΕΙΡΙΑΚΟΣ ΑΡΙΘΜΟΣ ΔΕΝ ΒΡΕΘΗΚΕ",
    "equipment.BUSCAR": "ΑΝΑΖΗΤΗΣΗ",
    "equipment.ACESSADOS RECENTEMENTE": "ΠΡΟΣΦΑΣΗ ΠΡΟΣΒΑΣΗ",
    "equipment.SEM BUSCAS RECENTES": "ΧΩΡΙΣ ΠΡΟΣΦΑΤΕΣ ΑΝΑΖΗΤΗΣΕΙΣ",
    "equipment.Procurar equipamento pelo número de série": "Αναζήτηση εξοπλισμού με σειριακό αριθμό",
    "equipment.AIR FILTER CLEANING COMPLETED": "Ο καθαρισμός του φίλτρου αέρα ολοκληρώθηκε",
    "equipment.AIR FILTER CLEANING REJECTED": "Ο καθαρισμός φίλτρου αέρα απορρίφθηκε",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Ολοκληρώθηκε ο καθαρισμός του καταλυτικού φίλτρου",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Απορρίφθηκε ο καθαρισμός του καταλυτικού φίλτρου",
    "equipment.Endereço (rua, avenida, rodovia)": "Διεύθυνση (οδός, λεωφόρος, αυτοκινητόδρομος)",
    "equipment.Nº/Km": "Nº/Km",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Όλος ο εξοπλισμός πρέπει να συνδέεται με μια ομάδα. Επιλέξτε μια ομάδα από τη λίστα ή καταχωρίστε μια νέα κάνοντας κλικ στο + Εγγραφή ομάδας.",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Για να προβάλετε το PIN, μεταβείτε στην εικονική πλατφόρμα IOK στις ρυθμίσεις εξοπλισμού.",
    "equipment.Vinculação IOK": "Δέσμευση ΙΟΚ",
    "equipment.Preparações": "Προετοιμασίες",
    "equipment.Última preparação": "Τελευταία προετοιμασία",
    "equipment.Pico de preparações": "Κορύφωση προετοιμασιών",
    "equipment.ID do equipamento": "ID εξοπλισμού",
    "equipment.Nomeie seu forno": "Ονομάστε τον φούρνο σας",
    "equipment.localização": "τοποθεσία",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "ΔΕΝ ΕΧΕΙ ΕΓΓΡΑΦΕΙ ΑΚΟΜΑ ΤΟΠΟΘΕΣΙΑ :(",
    "equipment.Procurar empresa ou local": "Αναζήτηση εταιρείας ή τοποθεσίας",
    "equipment.Adicionar localização": "Προσθήκη τοποθεσίας",
    "equipment.espaço": "Χώρος",
    "equipment.local": "Τοπικός",
    "equipment.EXCLUIR LOCALIZAÇÃO": "ΔΙΑΓΡΑΦΗ ΤΟΠΟΘΕΣΙΑΣ",
    "equipment.Você deseja excluir esta localização?": "Θέλετε να διαγράψετε αυτήν την τοποθεσία;",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Όταν το κάνετε αυτό, ο εξοπλισμός που είναι συνδεδεμένος με αυτόν δεν θα εμφανίζει καμία τοποθεσία, καθιστώντας απαραίτητη την παροχή μιας νέας τοποθεσίας για αυτούς.",
    "equipment.Localização deletada com sucesso!": "Η τοποθεσία διαγράφηκε με επιτυχία!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Σφάλμα κατά τη διαγραφή τοποθεσίας. Δοκιμάστε ξανά αργότερα",
    "equipment.Não foi possivel deletar a localização": "Δεν είναι δυνατή η διαγραφή τοποθεσίας",
    "equipment.Localização adicionada com sucesso!": "Η τοποθεσία προστέθηκε με επιτυχία!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Δεν είναι δυνατή η προσθήκη τοποθεσίας. Δοκιμάστε ξανά αργότερα",
    "equipment.Nome do espaço": "Όνομα χώρου",
    "equipment.Informe o nome do espaço": "Εισαγάγετε το όνομα του χώρου",
    "equipment.Preencha o nome do espaço": "Συμπληρώστε το όνομα του χώρου",
    "equipment.país": "Χώρα",
    "equipment.informe o país": "Εισαγάγετε τη χώρα",
    "equipment.preencha o país": "Συμπληρώστε τη χώρα",
    "equipment.Localização editada com sucesso!": "Η τοποθεσία επεξεργάστηκε με επιτυχία!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Δεν είναι δυνατή η επεξεργασία της τοποθεσίας. Δοκιμάστε ξανά αργότερα",
    "equipment.Editar localização": "Επεξεργασία τοποθεσίας",
    "equipment.informações da semana": "πληροφορίες της εβδομάδας",
    "equipment.horários de pico": "ώρες αιχμής",
    "equipment.horário de pico": "ώρα αιχμής",
    "equipment.preparações do dia": "Προετοιμασίες της ημέρας",
    "equipment.Horário": "Χρόνος",
    "equipment.dia selecionado": "επιλεγμένη ημέρα",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Ο σωστός και καθημερινός καθαρισμός του φούρνου τον βοηθά να λειτουργεί σωστά και επηρεάζει την εγγύηση.",
    "equipment.Total de limpezas realizadas:": "Ολικός καθαρισμός που πραγματοποιήθηκε:",
    "equipment.Limpezas não concluídas:": "Οι καθαρισμοί δεν ολοκληρώθηκαν:",
    "equipment.Desde a vinculação do forno a IOK": "Από τη σύνδεση του φούρνου στο ΙΟΚ",
    "equipment.Equipamento ligado há:": "Ο εξοπλισμός είναι ενεργοποιημένος για:",
    "equipment.Total de receitas preparadas:": "Συνολικός αριθμός προετοιμασμένων συνταγών:",
    "equipment.Receitas preparadas": "Προετοιμασμένες συνταγές",
    "equipment.Aberturas de porta": "Ανοίγματα θυρών",
    "equipment.Desde a última vez que o forno foi ligado": "Από την τελευταία φορά που άνοιξε ο φούρνος",
    "equipment.Preparação": "Προετοιμασία",
    "equipment.Nenhuma localização criada": "Δεν δημιουργήθηκαν τοποθεσίες",
    "equipment.Acesso remoto": "Απομακρυσμένη πρόσβαση",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Εισαγάγετε το αναγνωριστικό της εταιρείας που μπορεί να έχει απομακρυσμένη πρόσβαση στο φούρνο σας. Χρήση ; για διαχωρισμό ταυτοτήτων.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "Η απομακρυσμένη πρόσβαση επιτρέπει σε επιλεγμένες εταιρείες να στέλνουν μενού και ενημερώσεις στον εξοπλισμό σας.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Παραχωρώ απομακρυσμένη πρόσβαση στην επίσημη Prática.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Θέλετε να επιλέξετε άλλες συσκευές συνδεδεμένες με τον λογαριασμό σας για να τους παραχωρήσετε απομακρυσμένη πρόσβαση;",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Επιλέξτε παρακάτω τον εξοπλισμό για τον οποίο θέλετε να παραχωρήσετε απομακρυσμένη πρόσβαση στο",
    "equipment.Acesso remoto concedido com sucesso!": "Η απομακρυσμένη πρόσβαση παραχωρήθηκε με επιτυχία!",
    "equipment.Selecione pelo menos um equipamento": "Επιλέξτε τουλάχιστον μία συσκευή",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Δεν είναι δυνατή η επεξεργασία του εξοπλισμού. Δοκιμάστε ξανά αργότερα",
    "equipment.Solicitar atendimento": "Ζητήστε υπηρεσία",
    "equipment.Relate um problema": "Αναφέρετε ένα πρόβλημα",
    "equipment.Descreva aqui o problema de seu equipamento": "Περιγράψτε το πρόβλημα του εξοπλισμού σας εδώ",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Δεν μπορέσαμε να στείλουμε το αίτημα υπηρεσίας σας. Δοκιμάστε αργότερα",
    "equipment.Solicitação técnica enviada com sucesso!": "Το τεχνικό αίτημα στάλθηκε με επιτυχία!",
    "equipment.Enviar": "Επιβεβαιώνω",
    "equipment.Solicitação de atendimento": "Αίτημα εξυπηρέτησης",
    "equipment.Informações do magnetron": "Πληροφορίες για τον μαγνήτρον",
    "equipment.Vida útil aproximada": "Εκτιμώμενη διάρκεια ζωής",
    "equipment.MAGNETRON USAGE HOURS": "Ώρες χρήσης του μαγνήτρον",
    "equipment.Filtros": "Φίλτρα",
    "equipment.Filtrar": "Φιλτράρισμα",
    "equipment.Limpar filtros": "Εκκαθάριση φίλτρων",
    "equipment.Procurar por modelo, versão ou país": "Αναζήτηση ανά μοντέλο, έκδοση ή χώρα",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Αναζήτηση εξοπλισμού, Αριθμός σειράς, μοντέλου, έκδοσης ή χώρας",
    "equipment.Atendimento": "Υπηρεσία",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Τα περισσότερα συμβάντα στον εξοπλισμό κατά τη διάρκεια της περιόδου",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "ΕΞΟΠΛΙΣΜΟΣ ΧΩΡΙΣ ΣΕΙΡΙΚΟ ΑΡΙΘΜΟ",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Οι παρακάτω ID δεν ανήκουν σε εταιρεία τύπου 'Διανομέας' και έχουν αφαιρεθεί",
    "equipment.BAIXAR VERSÕES": "ΚΑΤΕΒΑΣΤΕ ΕΚΔΟΣΕΙΣ",
    "equipment.Compatível com todas as versões da IHM.": "Συμβατό με όλες τις εκδόσεις HMI.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Συμβατό μόνο με την έκδοση Power 2.0.0C.",
    "equipment.Procurar evento": "Αναζήτηση εκδήλωσης",
    "equipment.Forno conectado": "Ο φούρνος συνδέθηκε",
    "equipment.Limpeza de filtro de ar": "Καθαρισμός φίλτρου αέρα",
    "equipment.Limpeza de filtro catalítico": "Καθαρισμός καταλυτικού φίλτρου",
    "equipment.Forno ligado": "Ο φούρνος είναι ενεργοποιημένος",
    "equipment.Inversor de frequência": "Μετατροπέας συχνότητας",
    "equipment.Temperatura de painel": "Θερμοκρασία πάνελ",
    "equipment.Sensor da câmara": "Αισθητήρας κάμερας",
    "equipment.Magnetron": "Μαγνητρόν",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Πληροφορίες μενού",
    "equipment.Limpeza": "Καθαρισμός",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "Ο εξοπλισμός χρησιμοποιεί ήδη την πιο πρόσφατη έκδοση του λογισμικού.",
    "equipment.Atenção: o cálculo da vida útil do magnetron não considera o tempo de uso anterior à versão F1.0.6." : "Προσοχή: στον υπολογισμό της ωφέλιμης ζωής του magnetron δεν λαμβάνεται υπόψη ο χρόνος χρήσης πριν από την έκδοση F1.0.6.",
    "equipment.Equipamentos com erro" : "Εξοπλισμός με σφάλματα",
    "equipment.Em breve você terá acesso a dados importantes enviados pelo forno, como horário de pico, receitas mais executadas, histórico de higienizações, eventos ocorridos e mais! Lembre-se de manter seu equipamento sempre atualizado com as novas versões disponíveis na plataforma." : 
      "Σύντομα θα έχετε πρόσβαση σε σημαντικά δεδομένα που αποστέλλονται από τον φούρνο, όπως ώρες αιχμής, συνταγές που έχουν εκτελεστεί περισσότερο, ιστορικό καθαρισμού, γεγονότα που συνέβησαν και πολλά άλλα! Θυμηθείτε να διατηρείτε τον εξοπλισμό σας πάντα ενημερωμένο με τις νέες εκδόσεις που είναι διαθέσιμες στην πλατφόρμα.",
    "equipment.Para que a execução da limpeza seja registrada no equipamento e enviada à plataforma, é necessário seguir o passo a passo completo do procedimento no forno. Não é obrigatório assistir aos vídeos dos passos, quando disponíveis." : 
      "Για να καταγραφεί ο καθαρισμός στον εξοπλισμό και να σταλεί στην πλατφόρμα, είναι απαραίτητο να ακολουθήσετε την πλήρη διαδικασία βήμα προς βήμα στον φούρνο. Δεν είναι υποχρεωτικό να παρακολουθείτε βίντεο με τα βήματα, όταν είναι διαθέσιμα.",

    //LOJAS
    "store.Procurar loja": "Αναζήτηση καταστήματος",
    "store.Excluir": "Διαγραφή",
    "store.Editar": "Επεξεργασία",
    "store.nome": "Όνομα",
    "store.equipamentos": "Εξοπλισμός",
    "store.cidade": "Πόλη",
    "store.estado": "Πολιτεία",
    "store.salvar": "Αποθήκευση",
    "store.cadastrar loja": "Εγγραφή καταστήματος",
    "store.Nome fantasia": "Φανταστικό όνομα",
    "store.Preencha o nome da loja": "Συμπληρώστε το όνομα του καταστήματος",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Συμπληρώστε το CNPJ του καταστήματος",
    "store.CEP": "Ταχυδρομικός κώδικας",
    "store.Preencha o CEP da loja": "Συμπληρώστε τον ταχυδρομικό κώδικα του καταστήματος",
    "store.Informe o nome fantasia": "Συμπληρώστε το φανταστικό όνομα",
    "store.Cidade": "Πόλη",
    "store.Informe a cidade": "Εισαγωγή της πόλης",
    "store.Preencha a cidade": "Συμπληρώστε την πόλη",
    "store.UF": "Πολιτεία",
    "store.Preencha o UF da loja": "Συμπληρώστε την πολιτεία του καταστήματος",
    "store.Bairro": "Γειτονιά",
    "store.Informe o bairro": "Εισαγωγή της γειτονιάς",
    "store.Preencha o bairro da loja": "Συμπληρώστε τη γειτονιά του καταστήματος",
    "store.Endereço": "Διεύθυνση",
    "store.Informe o endereço": "Εισαγωγή της διεύθυνσης",
    "store.Preencha o endereço da loja": "Συμπληρώστε τη διεύθυνση του καταστήματος",
    "store.Nº": "Αρ.",
    "store.Loja cadastrada com sucesso": "Το κατάστημα καταχωρήθηκε με επιτυχία",
    "store.Erro ao adicionar loja. Tente novamente": "Σφάλμα κατά την προσθήκη του καταστήματος. Δοκιμάστε ξανά",
    "store.Loja deletada com sucesso": "Το κατάστημα διαγράφηκε με επιτυχία",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Σφάλμα κατά τη διαγραφή του καταστήματος. Δοκιμάστε ξανά αργότερα",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΗ ΚΑΤΑΧΩΡΗΜΕΝΑ ΚΑΤΑΣΤΗΜΑΤΑ :(",
    "store.EXCLUIR LOJA": "ΔΙΑΓΡΑΦΗ ΚΑΤΑΣΤΗΜΑΤΟΣ",
    "store.Você deseja excluir a loja da plataforma IOK?": "Θέλετε να διαγράψετε το κατάστημα από την πλατφόρμα IOK;",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "Με αυτόν τον τρόπο, ο εξοπλισμός που είναι συνδεδεμένος με αυτό δεν θα είναι συνδεδεμένος με κανένα κατάστημα, εκτός αν τα συνδέσετε ξανά.",
    "store.loja editada com sucesso": "Το κατάστημα επεξεργάστηκε με επιτυχία",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Δεν ήταν δυνατή η επεξεργασία του καταστήματος. Δοκιμάστε ξανά αργότερα",
    "store.editar loja": "Επεξεργασία καταστήματος",
    "store.Procurar grupo de equipamentos": "Ομάδα εξοπλισμού αναζήτησης",
    "store.cadastrar grupo": "εγγραφή ομάδας",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "ΔΕΝ ΕΧΟΥΝ ΕΓΓΡΑΦΕΙ ΑΚΟΜΑ ΟΜΑΔΑ :(",
    "store.cadastrar grupo de equipamentos": "καταχωρήστε ομάδα εξοπλισμού",
    "store.grupo de equipamentos cadastrado com sucesso!": "Η ομάδα εξοπλισμού εγγράφηκε με επιτυχία!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Δεν είναι δυνατή η εγγραφή της ομάδας. Δοκιμάστε ξανά αργότερα",
    "store.Nome do grupo": "Όνομα ομάδας",
    "store.informe o nome do grupo": "Εισαγάγετε το όνομα της ομάδας",
    "store.preencha o nome do grupo": "Συμπληρώστε το όνομα της ομάδας",
    "store.Grupo de equipamentos deletado com sucesso!": "Η ομάδα εξοπλισμού διαγράφηκε με επιτυχία!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Σφάλμα κατά τη διαγραφή της ομάδας. Δοκιμάστε ξανά αργότερα",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "ΔΙΑΓΡΑΦΗ ΟΜΑΔΑΣ ΕΞΟΠΛΙΣΜΟΥ",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Θέλετε να διαγράψετε αυτήν την ομάδα εξοπλισμού από την πλατφόρμα IOK;",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Όταν το κάνετε αυτό, ο εξοπλισμός που είναι συνδεδεμένος με αυτόν δεν θα συνδεθεί με καμία ομάδα, εκτός εάν τον συνδέσετε ξανά.",
    "store.grupo de equipamentos editado com sucesso!": "Η ομάδα εξοπλισμού επεξεργάστηκε με επιτυχία!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Δεν είναι δυνατή η επεξεργασία της ομάδας. Δοκιμάστε ξανά αργότερα",
    "store.editar grupo de equipamentos": "επεξεργασία ομάδας εξοπλισμού",
    "store.CEP não encontrado no Brasil": "Ο ταχυδρομικός κώδικας δεν βρέθηκε στη Βραζιλία",

    //NAVBAR
    "navbar.home": "Αρχική",
    "navbar.cookbook": "Συνταγολόγιο",
    "navbar.my menus": "Τα μενού μου",
    "navbar.equipment": "Εξοπλισμός",
    "navbar.users": "Χρήστες",
    "navbar.calls": "Υπηρεσίες",
    "navbar.settings": "Ρυθμίσεις",
    "navbar.help": "Βοήθεια",
    "navbar.logout": "Αποσύνδεση",
    "navbar.Editar conta": "Επεξεργασία λογαριασμού",
    "navbar.API Externa": "Εξωτερική API",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "ΕΚΚΡΕΜΕΣ ΥΠΗΡΕΣΙΕΣ",
    "calls.ATENDIMENTOS ENCERRADOS": "ΚΛΕΙΣΤΟΣ ΥΠΗΡΕΣΙΕΣ",

    //FOOTERBAR
    "footerBar.menu": "Μενού",
    "footerBar.cookbook": "Συνταγολόγιο",
    "footerBar.home": "Σπίτι",
    "footerBar.menus": "Μενού",
    "footerBar.notifications": "Ειδοποιήσεις",

    //MAPA
    "map.título": "Τοποθεσία",
    "map.erro": "σφάλμα",
    "map.mensagem de erro": "αδυναμία φόρτωσης χάρτη",
    "map.Localização não disponível": "Τοποθεσία μη διαθέσιμη",

    //MODAL
    "modal.CONFIRMAR": "ΕΠΙΒΕΒΑΙΩΣΗ",
    "modal.Salvar alterações": "Αποθήκευση εκδόσεων",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Υπάρχουν μη αποθηκευμένες αλλαγές στις παραμέτρους του βήματος",
    "Modal.Como deseja proceder?": "Πώς θέλετε να προχωρήσετε;",
    "Modal.Avaliar alterações realizadas": "Αξιολόγηση των πραγματοποιηθέντων αλλαγών",
    "Modal.Descartar alterações": "Απόρριψη των αλλαγών",

    //USUÁRIOS
    "user.Nome": "Ονομα",
    "user.E-mail": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    "user.Permissão": "Αδεια",
    "user.Loja": "Κατάστημα",
    "user.Status": "Κατάσταση",
    "user.Excluir": "Διαγράφω",
    "user.Editar": "Για να επεξεργαστείτε",
    "user.Procurar usuário": "Αναζήτηση χρήστη",
    "user.ADICIONAR USUÁRIO": "ΠΡΟΣΘΕΣΕ ΧΡΗΣΤΗ",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΚΟΜΑ ΕΓΓΕΓΡΑΜΜΕΝΟΙ ΧΡΗΣΤΕΣ :(",
    "user.+ Usuário": "+ Χρήστης",
    "user.Informe o nome do usuário": "Εισαγάγετε το όνομα χρήστη",
    "user.Preencha o nome do usuário": "Συμπληρώστε το όνομα χρήστη",
    "user.Informe o e-mail do usuário": "Εισαγάγετε το email του χρήστη",
    "user.Preencha com um e-mail válido": "Συμπληρώστε ένα έγκυρο email",
    "user.Administrador": "Διαχειριστής",
    "user.Usuário": "Χρήστης",
    "user.Preencha o telefone do usuário": "Συμπληρώστε τον αριθμό τηλεφώνου του χρήστη",
    "user.Selecione a permissão do usuário": "Επιλέξτε άδεια χρήστη",
    "user.Ativo": "Ενεργός",
    "user.Inativo": "Αδρανής",
    "user.Usuário deletado da plataforma com sucesso!": "Ο χρήστης διαγράφηκε επιτυχώς από την πλατφόρμα!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Παρουσιάστηκε σφάλμα. Δοκιμάστε ξανά αργότερα",
    "user.EXCLUIR USUÁRIO": "ΔΙΑΓΡΑΦΗ ΧΡΗΣΤΗ",
    "user.delete.paragrafo 1": "Θέλετε να διαγράψετε τον χρήστη από την πλατφόρμα του IOK;",
    "user.delete.paragrafo 2": "Με αυτόν τον τρόπο, θα χάσει την πρόσβαση σε όλα τα εργαλεία της πλατφόρμας, εκτός εάν προστεθεί ξανά σε αυτήν, ακολουθώντας τις οδηγίες εγγραφής.",
    "user.EDITAR USUÁRIO": "ΕΠΕΞΕΡΓΑΣΙΑ ΧΡΗΣΤΗ",
    "user.Usuário editado com sucesso!": "Ο χρήστης επεξεργάστηκε με επιτυχία!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Δεν είναι δυνατή η επεξεργασία αυτού του χρήστη. Παρακαλώ δοκιμάστε ξανά αργότερα",
    "user.INFORMAÇÕES DO USUÁRIO": "ΠΛΗΡΟΦΟΡΙΕΣ ΧΡΗΣΤΗ",
    "user.Telefone": "Τηλέφωνο",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Δεν είναι δυνατή η εγγραφή αυτού του χρήστη. Παρακαλώ δοκιμάστε ξανά αργότερα",
    "user.Usuário adicionado com sucesso!": "Ο χρήστης προστέθηκε με επιτυχία!",
    "user.CRIAR TIPO DE USUÁRIO": "ΔΗΜΙΟΥΡΓΙΑ ΤΥΠΟΥ ΧΡΗΣΤΗ",
    "user.Criar novo tipo de usuário": "Δημιουργία νέου τύπου χρήστη",
    "user.Adicionar novo usuário": "Προσθήκη νέου χρήστη",
    "user.Permissões": "Άδειες",
    "user.Preencha o nome do novo tipo de usuário": "Συμπληρώστε το όνομα του νέου τύπου χρήστη",
    "user.Selecione pelo menos uma permissão": "Επιλέξτε τουλάχιστον ένα δικαίωμα",
    "user.Não foi possível carregar as permissões": "Δεν είναι δυνατή η φόρτωση των δικαιωμάτων",
    "user.Tipo de usuário criado com sucesso!": "Ο τύπος χρήστη δημιουργήθηκε με επιτυχία!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Δεν είναι δυνατή η δημιουργία αυτού του νέου τύπου χρήστη. Παρακαλώ δοκιμάστε ξανά αργότερα",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Δεν είναι δυνατή η φόρτωση τύπων χρηστών της εταιρείας",
    "user.Tipo de usuário": "Τύπος χρήστη",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "Email έχει ήδη εγγραφεί στην πλατφόρμα. Εισαγάγετε ένα νέο email",
    "user.UNIR CONTAS": "ΕΓΓΡΑΦΕΙΤΕ ΛΟΓΑΡΙΑΣΜΟΙ",
    "user.ID da companhia": "Ταυτότητα Εταιρίας",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Εισαγάγετε το αναγνωριστικό της εταιρείας με την οποία θέλετε να συγχωνεύσετε τον λογαριασμό σας",
    "user.Preencha o ID da companhia": "Συμπληρώστε την ταυτότητα της εταιρείας",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "Ο διαχειριστής του λογαριασμού στον οποίο θέλετε να εγγραφείτε θα μπορεί να βρει το αναγνωριστικό κάνοντας κλικ στο όνομα χρήστη σας, που βρίσκεται στο επάνω μενού, στη δεξιά γωνία της οθόνης.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!": "Ο διαχειριστής του άλλου λογαριασμού θα λάβει ένα email για να αποδεχτεί το αίτημά σας. Μόλις γίνουν αποδεκτοί, οι δύο λογαριασμοί θα συγχωνευθούν!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Δεν είναι δυνατή η αίτηση συγχώνευσης λογαριασμού. Επιβεβαιώστε το αναγνωριστικό ή δοκιμάστε αργότερα",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "ΤΟ ΑΙΤΗΜΑ ΕΓΚΡΙΘΗΚΕ ΕΠΙΤΥΧΙΑ",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "ΠΑΡΑΚΑΛΩ ΠΕΡΙΜΕΝΕΤΕ ΟΣΟ ΣΥΝΔΕΣΟΥΜΕ ΤΟΥΣ ΛΟΓΑΡΙΑΣΜΟΥΣ. ΑΥΤΗ Η ΔΙΑΔΙΚΑΣΙΑ ΜΠΟΡΕΙ ΝΑ ΔΙΑΡΚΕΙ ΛΙΓΑ ΛΕΠΤΑ...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "Η ΕΝΩΣΗ ΛΟΓΑΡΙΑΣΜΩΝ ΟΛΟΚΛΗΡΩΘΗΚΕ ΜΕ ΕΠΙΤΥΧΙΑ! ΚΑΝΤΕ ΚΛΙΚ ΣΤΟ ΠΑΡΑΚΑΤΩ ΚΟΥΜΠΙ ΓΙΑ ΠΡΟΣΒΑΣΗ ΣΤΗΝ ΠΛΑΤΦΟΡΜΑ.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.": "ΔΕΝ ΜΠΟΡΟΥΜΕ ΝΑ ΣΥΓΧΩΝΕΥΟΥΜΕ ΤΟΥΣ ΛΟΓΑΡΙΑΣΜΟΥΣ. ΠΑΡΑΚΑΛΩ ΠΡΟΣΠΑΘΗΣΕ ΞΑΝΑ. ΕΑΝ ΤΟ ΠΡΟΒΛΗΜΑ ΕΠΙΜΕΝΕΙ, ΠΑΡΑΚΑΛΩ ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΕ ΤΗΝ ΥΠΟΣΤΗΡΙΞΗ ΜΑΣ.",
    "user.possui todos os acessos": "έχει όλη την πρόσβαση",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "έχει πρόσβαση στο βιβλίο μαγειρικής, τα μενού και τις καρτέλες του εξοπλισμού μου",
    "user.possui apenas o acesso da aba cookbook": "έχει πρόσβαση μόνο στην καρτέλα του βιβλίου μαγειρικής",
    "user.possui apenas o acesso da aba equipamentos": "έχει πρόσβαση μόνο στην καρτέλα εξοπλισμού",
    "user.possui apenas o acesso da aba meus menus": "έχει πρόσβαση μόνο στην καρτέλα τα μενού μου",
    "user.Grupo de equipamentos": "Ομάδα εξοπλισμού",
    "user.Informe o telefone do usuário": "Εισαγάγετε τον αριθμό τηλεφώνου του χρήστη",
    "user.Nenhum grupo criado": "Δεν δημιουργήθηκαν ομάδες",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Εισαγάγετε το αναγνωριστικό της εταιρείας που θα έχει πρόσβαση στον λογαριασμό σας",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Όταν παρέχετε ένα αναγνωριστικό για τη συγχώνευση λογαριασμών με την εταιρεία σας, να γνωρίζετε ότι ο νέος χρήστης θα γίνει επίσης Διαχειριστής του λογαριασμού της εταιρείας σας. Είναι σημαντικό να ενημερώσετε ότι από τη στιγμή που ένας χρήστης συμφωνήσει να εγγραφεί σε λογαριασμούς με την εταιρεία σας, η εταιρεία που ανήκει σε αυτόν θα πάψει να υφίσταται.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Παράδειγμα: Ο λογαριασμός σας: 001 | Άλλος λογαριασμός: 111. Μετά τη συγχώνευση λογαριασμών με εσάς, ο άλλος λογαριασμός θα γίνει 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "Ο διαχειριστής του άλλου λογαριασμού θα μπορεί να εντοπίσει το αναγνωριστικό κάνοντας κλικ στο όνομα χρήστη σας, που βρίσκεται στο επάνω μενού, στη δεξιά γωνία της οθόνης.",
    "user.Tela de Equipamentos": "Οθόνη Εξοπλισμού",
    "user.Tela de Meus Menus": "Οθόνη των Μενού Μου",
    "user.Tela do Cookbook": "Οθόνη του Βιβλίου Συνταγών",
    "user.Tela de Usuários": "Οθόνη Χρηστών",
    "user.Aba de Suporte": "Καρτέλα Υποστήριξης",
    "user.Usuário Comum": "Κανονικός Χρήστης",
    "user.Usuário do Cookbook": "Χρήστης του Cookbook",
    "user.Usuário de Equipamentos": "Χρήστης Εξοπλισμού",
    "user.Usuário de Meus Menus": "Χρήστης των Μενού Μου",
    "user.Você deseja excluir seu acesso da plataforma IOK?" : "Θέλεις να διαγράψεις την πρόσβασή σου στην πλατφόρμα IOK;",
    "user.Ao fazer isso, você perderá o acesso a todas as ferramentas da plataforma e, para voltar a acessá-la, será necessário ser adicionado à ela novamente." : "Με αυτόν τον τρόπο, θα χάσεις την πρόσβαση σε όλα τα εργαλεία της πλατφόρμας και για να την ξαναπροσπελάσεις, θα χρειαστεί να προστεθείς ξανά σε αυτήν.",
    "user.CONFIRMAÇÃO" : "ΕΠΙΒΕΒΑΙΩΣΗ",
    "user.Confirme sua senha" : "Επιβεβαίωσε τον κωδικό σου",
    "user.Senha incorreta" : "Λάθος κωδικός πρόσβασης",
    
    //TUTORIAIS
    "tutorial.Fechar": "Να κλείσω",
    "tutorial.Fim": "Τέλος",
    "tutorial.Próximo": "Επόμενο",
    "tutorial.Abrir": "Ανοιξε",
    "tutorial.Pular tutorial": "Παράλειψη εκμάθησης",
    "tutorial.Editar detalhes da receita": "Επεξεργασία λεπτομερειών συνταγής",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Τώρα μπορείτε να προσθέσετε περισσότερες λεπτομέρειες στις συνταγές σας και να τις κάνετε ακόμα πιο ολοκληρωμένες. Κάντε κλικ στην Επεξεργασία λεπτομερειών και συμπληρώστε τις απαιτούμενες πληροφορίες.",
    "tutorial.Salvar edição da receita": "Αποθήκευση επεξεργασίας συνταγής",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Αφού εισαγάγετε περισσότερες λεπτομέρειες συνταγής, κάντε κλικ στην Αποθήκευση.",
    "tutorial.Editar registro de um equipamento": "Επεξεργασία εγγραφής εξοπλισμού",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Για να ολοκληρώσετε την εγγραφή του εξοπλισμού σας, ανοίξτε το πλαϊνό μενού, κάντε κλικ στην Επεξεργασία και συμπληρώστε τις πληροφορίες Κατηγορίας και Αποθήκης.",
    "tutorial.Editar usuário": "Επεξεργασία χρήστη",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Για να ολοκληρώσετε την εγγραφή χρήστη, ανοίξτε το πλαϊνό μενού, κάντε κλικ στην Επεξεργασία και συμπληρώστε τις πληροφορίες σχετικά με το Κατάστημα στο οποίο ανήκουν.",
    "tutorial.Salvar edições de um equipamento": "Αποθήκευση αλλαγών σε μια συσκευή",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Αφού ολοκληρώσετε την εγγραφή του εξοπλισμού σας, κάντε κλικ στην Αποθήκευση.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Κάνοντας κλικ στη γραμμή ενός εξοπλισμού που είναι καταχωρημένο σε ένα κατάστημα, μπορείτε να δείτε περισσότερα στατιστικά στοιχεία και πληροφορίες σχετικά με αυτό, εκτός από τη δυνατότητα να ζητήσετε σέρβις ή να ενεργοποιήσετε ενημερώσεις.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "Για να δείτε περισσότερες πληροφορίες σχετικά με το κατάστημα, όπως η τοποθεσία και οι πιο προετοιμασμένες συνταγές, κάντε κλικ στη γραμμή της μονάδας που θέλετε να αναλύσετε.",
    "tutorial.Criar receita": "Δημιουργήστε συνταγή",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.":
      "Στο βιβλίο μαγειρικής μπορείτε να δημιουργήσετε τις δικές σας συνταγές! Κάντε κλικ στο Create Recipe και συμπληρώστε όλες τις απαραίτητες πληροφορίες.",
    "tutorial.Editar ou excluir um usuário": "Επεξεργαστείτε ή διαγράψτε έναν χρήστη",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.":
      "Για να διαγράψετε ή να επεξεργαστείτε τις πληροφορίες ενός ήδη εγγεγραμμένου χρήστη, κάντε κλικ στο πλαϊνό μενού και επιλέξτε την επιθυμητή επιλογή.",
    "tutorial.Gerenciamento de usuários": "Διαχείριση χρηστών",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Εδώ μπορείτε να διαχειριστείτε τους χρήστες σας πιο εύκολα. Κάνοντας κλικ στο + Χρήστης, μπορείτε να προσθέσετε έναν νέο χρήστη ή να δημιουργήσετε έναν νέο τύπο χρήστη.",
    "tutorial.Suas receitas": "Οι συνταγές σας",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.":
      "Μέσα από το βιβλίο μαγειρικής θα βρείτε και τις δικές σας συνταγές, καθώς και να μπορείτε να τις δημιουργήσετε! Κάντε κλικ στο Αποθηκευμένες συνταγές για να δείτε τις συνταγές που αποθηκεύτηκαν από το βιβλίο μαγειρικής Εξάσκησης καθώς και αυτές που δημιουργήσατε εσείς.",
    "tutorial.Filtros do Cookbook": "Φίλτρα βιβλίων μαγειρικής",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.":
      "Για να διευκολύνετε την περιήγηση σε συνταγές, μπορείτε να εφαρμόσετε φίλτρα σε αυτές. Απλώς κάντε κλικ στο φίλτρο που θέλετε και επιλέξτε μία από τις επιλογές του.",
    "tutorial.Cookbook": "Βιβλίο μαγειρικής",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.":
      "Στο βιβλίο μαγειρικής, κάτω από τις Διαθέσιμες συνταγές, θα βρείτε τις συνταγές που δημιουργήθηκαν από τους Practice Chefs και μπορείτε να τις χρησιμοποιήσετε όποτε θέλετε.",
    "tutorial.Atualizar equipamentos": "Ενημέρωση εξοπλισμού",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.":
      "Για να στείλετε μια ενημέρωση στον εξοπλισμό σας, κάντε κλικ στο Ενημέρωση εξοπλισμού, επιλέξτε τα μοντέλα που θέλετε να ενημερώσετε και τον τρόπο που θέλετε να πραγματοποιήσετε αυτήν την ενημέρωση - μέσω Wi-Fi ή μονάδας στυλό. Μετά από αυτό, κάντε κλικ στην Επιβεβαίωση.",
    "tutorial.Adicionar equipamento": "Προσθήκη εξοπλισμού",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.":
      "Για να προσθέσετε εξοπλισμό στον λογαριασμό σας, κάντε κλικ στην Προσθήκη εξοπλισμού και συμπληρώστε τις πληροφορίες σχετικά με τον τρόπο λειτουργίας. Μετά από αυτό, κάντε κλικ στην επιλογή Αποθήκευση.",
    "tutorial.Editar equipamento": "Επεξεργασία εξοπλισμού",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Για να αποσυνδέσετε μια συσκευή ή να επεξεργαστείτε τις πληροφορίες της, κάντε κλικ στο πλευρικό μενού και επιλέξτε την επιθυμητή επιλογή.",
    "tutorial.Seus equipamentos": "Ο εξοπλισμός σας",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Εδώ θα δείτε όλο τον εξοπλισμό που είναι καταχωρημένος στην πλατφόρμα σας.",
    "tutorial.Restaurar arquivos": "Επαναφορά αρχείων",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Για να επαναφέρετε ένα διαγραμμένο αρχείο ή να το διαγράψετε οριστικά, κάντε κλικ στο πλαϊνό μενού και επιλέξτε την επιθυμητή επιλογή.",
    "tutorial.Lixeira": "Αποθήκη",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Τα διαγραμμένα μενού θα διατηρηθούν στον Κάδο απορριμμάτων για 30 ημέρες. Μετά από αυτό το διάστημα θα διαγραφούν οριστικά.",
    "tutorial.Salvar edições de usuário": "Αποθηκεύστε τις αλλαγές χρήστη",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Αφού ολοκληρώσετε την εγγραφή χρήστη, κάντε κλικ στην επιλογή Αποθήκευση.",
    "tutorial.Criar menu": "Δημιουργία μενού",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Για να δημιουργήσετε και να προσαρμόσετε ένα μενού, κάντε κλικ στο Create Menu και συμπληρώστε τις απαραίτητες πληροφορίες στο modal. Μπορείτε να επεξεργαστείτε ένα μενού ανά πάσα στιγμή.",
    "tutorial.Importar menu": "Μενού εισαγωγής",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Για να εισαγάγετε ένα μενού, κάντε κλικ στην επιλογή Εισαγωγή Μενού και επιλέξτε το μοντέλο και την έκδοση του εξοπλισμού.",
    "tutorial.Criar grupo": "Δημιουργησε ΟΜΑΔΑ",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Για να οργανώσετε τις συνταγές σας σε ομάδες, κάντε κλικ στο Create Group και συμπληρώστε τις απαραίτητες πληροφορίες στο modal.",
    "tutorial.Tipos de usuário": "Τύποι χρηστών",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Τώρα μπορείτε επίσης να δημιουργήσετε τύπους χρηστών με συγκεκριμένα δικαιώματα για την καλύτερη εξυπηρέτηση του οργανισμού σας.",
    "tutorial.Gerenciamento de contas": "Διαχείριση λογαριασμών",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Εάν κάποιος άλλος στον οργανισμό σας μετέφερε επίσης τον κύριο εταιρικό λογαριασμό του, εδώ μπορείτε να συγχωνεύσετε τους δύο λογαριασμούς.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Για να ολοκληρώσετε την εγγραφή του εξοπλισμού σας, ανοίξτε το πλαϊνό μενού, κάντε κλικ στην Επεξεργασία και συμπληρώστε τις πληροφορίες ομάδας.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Για να ολοκληρώσετε την εγγραφή χρήστη, ανοίξτε το πλαϊνό μενού, κάντε κλικ στην Επεξεργασία και συμπληρώστε τις πληροφορίες σχετικά με την Ομάδα Εξοπλισμού στην οποία ανήκει.",
    "tutorial.Grupos de equipamentos": "Ομάδες εξοπλισμού",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "Στην καρτέλα Ομάδες μπορείτε να δείτε τις καταχωρημένες ομάδες και τον εξοπλισμό που διανέμεται μεταξύ τους. Επιπλέον, είναι δυνατή η εγγραφή νέων ομάδων και η επεξεργασία αυτών που έχουν ήδη εγγραφεί.",
    "tutorial.Detalhes de grupos": "Λεπτομέρειες ομάδας",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "Για να δείτε περισσότερες πληροφορίες σχετικά με την ομάδα, όπως η τοποθεσία και οι πιο προετοιμασμένες συνταγές, κάντε κλικ στη γραμμή που θέλετε να αναλύσετε.",
    "tutorial.Equipamentos em grupos": "Εξοπλισμός σε ομάδες",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Κάνοντας κλικ στη γραμμή μιας συσκευής που είναι εγγεγραμμένη σε μια ομάδα, μπορείτε να δείτε περισσότερα στατιστικά στοιχεία και πληροφορίες σχετικά με αυτήν, εκτός από τη δυνατότητα να ζητήσετε υπηρεσία ή να ενεργοποιήσετε ενημερώσεις.",
    "tutorial.Grupos": "Ομάδες",
    "tutorial.Atualização e cache": "Ενημέρωση και προσωρινή αποθήκευση",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Για να ενημερώσετε την πλατφόρμα και να διορθώσετε τυχόν προβλήματα με την προσωρινή μνήμη, κάντε κλικ εδώ.",
    "tutorial.Dados dos equipamentos": "Δεδομένα εξοπλισμού",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "Κάνοντας κλικ στη γραμμή ενός εξοπλισμού, μπορείτε να δείτε περισσότερες στατιστικές και πληροφορίες σχετικά με αυτόν, όπως οι πιο προετοιμασμένες συνταγές, οι πραγματοποιημένες καθαρισμοί και άλλα σχετικά δεδομένα.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Για να αλλάξετε τη γλώσσα της πλατφόρμας σας, κάντε κλικ στο Language και επιλέξτε τη γλώσσα που θέλετε.",
    "tutorial.Alterar idioma": "Αλλάξτε τη γλώσσα",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Για να επεξεργαστείτε ή να διαμορφώσετε τον λογαριασμό σας, κάντε κλικ στο όνομά σας και επιλέξτε την επιλογή που θέλετε.",
    "tutorial.Editar conta": "Επεξεργασία λογαριασμού",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Σύνολο εγγεγραμμένων χρηστών στον λογαριασμό σας.",
    "tutorial.Home": "Σπίτι",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Θέση του εξοπλισμού σας, καθιστώντας δυνατή την επέκταση του χάρτη για καλύτερη προβολή.",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Ενημερώσεις σχετικά με την έκδοση του εξοπλισμού που συνδέεται με τον λογαριασμό σας.",
    "tutorial.Total de Receitas Cadastradas;": "Σύνολο εγγεγραμμένων εσόδων.",
    "tutorial.Menus Disponíveis;": "Διαθέσιμα μενού.",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "Αυτό είναι το σπίτι σας! Εδώ θα βρείτε μια γενική περίληψη του λογαριασμού σας, όπως τον αριθμό του Συνδεδεμένου Εξοπλισμού.",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Κάντε κλικ στις καρτέλες μενού για να εξερευνήσετε την πλατφόρμα και να ανακαλύψετε όλα όσα μπορεί να προσφέρει.",
    "tutorial.Exportar menu": "Μενού εξαγωγής",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Αφού προσαρμόσετε το μενού σας, εξάγετε το στις συσκευές σας μέσω του διαδικτύου ή μέσω της μονάδας στυλό.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "Στην καρτέλα Τοποθεσία μπορείτε να προβάλετε τις διευθύνσεις που έχουν ήδη καταχωρηθεί, εκτός από τη δημιουργία ακριβών τοποθεσιών για τη σύνδεση του εξοπλισμού σας με αυτές.",
    "tutorial.Localização": "Τοποθεσία",

    //RECOVER ACCOUNT
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "Θέλετε να αποκτήσετε πρόσβαση στην πλατφόρμα ως ΔΙΑΝΟΜΕΑΣ; Δημιουργήστε τον λογαριασμό σας κάνοντας κλικ στην ΕΤΑΙΡΕΙΑ και επικοινωνήστε μαζί μας για να απελευθερώσετε άλλη πρόσβαση.",
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Για να συνεχίσετε, επιλέξτε την κατηγορία στην οποία εντάσσεστε:",
    "recoverAccount.A qual organização você pertence": "Σε ποιον οργανισμό ανήκετε;",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "Email ανάκτησης κωδικού πρόσβασης",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "Εισαγάγετε το email που έχετε καταχωρίσει στην πλατφόρμα",
    "recoverPassword.E-mail inválido. Por favor, verifique": "Ακυρη διεύθυνση ηλεκτρονικού ταχυδρομείου. Επιβεβαιώστε",
    "recoverPassword.RECUPERAR SENHA": "ΑΝΑΚΤΗΣΗ ΚΩΔΙΚΟΥ",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "ΣΥΝΤΟΜΑ ΘΑ ΛΑΒΕΤΕ ΕΝΑ EMAIL ΜΕ ΤΟΝ ΣΥΝΔΕΣΜΟ ΑΝΑΚΤΗΣΗΣ ΚΩΔΙΚΟΥ",
    "recoverPassword.Login": "Σύνδεση",
    "recoverPassword.Informe seu e-mail": "Συμπληρώστε το email σας",
    "recoverPassword.Crie uma nova senha": "Δημιουργήστε έναν νέο κωδικό πρόσβασης",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "Το πεδίο κωδικού πρόσβασης πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες",
    "recoverPassword.Confirme sua senha": "Επιβεβαιώστε τον κωδικό πρόσβασής σας",
    "recoverPassword.As senhas devem ser iguais": "Οι κωδικοί πρόσβασης πρέπει να είναι ίδιοι",
    "recoverPassword.ENTRAR": "ΜΠΑΙΝΩ",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "ΣΥΝΤΟΜΑ ΘΑ ΛΑΒΕΤΕ ΕΝΑ EMAIL ΓΙΑ ΕΠΙΒΕΒΑΙΩΣΗ ΤΗΣ ΤΑΥΤΟΤΗΤΑΣ ΣΑΣ",
    "recoverPassword.CONTINUAR": "ΝΑ ΣΥΝΕΧΙΣΕΙ",
    "recoverPassword.CONFIRMAR": "ΕΠΙΒΕΒΑΙΩΣΗ",
    "recoverPassword.Por segurança, o acesso à conta foi bloqueado devido a tentativas de login incorretas.": "Για λόγους ασφαλείας, η πρόσβαση στον λογαριασμό αποκλείστηκε λόγω λανθασμένων προσπαθειών σύνδεσης.",
    "recoverPassword.Para desbloquear sua conta, siga as instruções após clicar no botão abaixo:": "Για να ξεκλειδώσετε τον λογαριασμό σας, ακολουθήστε τις οδηγίες αφού κάνετε κλικ στο παρακάτω κουμπί:",
    "recoverPassword.Desbloquear conta": "Ξεκλείδωμα λογαριασμού",
    "recoverPassword.Você recebeu um e-mail para iniciar o desbloqueio de sua conta.": "Έχετε λάβει ένα email για να ξεκινήσετε την ξεκλείδωση του λογαριασμού σας.",
    "recoverPassword.Verifique sua caixa de entrada ou spam.": "Ελέγξτε τα εισερχόμενα ή τα ανεπιθύμητα μηνύματα.",
    "recoverPassword.Verifique seu e-mail": "Ελέγξτε το email σας.",

    // EXPORT
    "export.Limpezas": "Καθαρισμοί",
    "export.Eventos": "Συμβάντα",
    "export.Lista de receitas preparadas": "Λίστα προετοιμασμένων συνταγών",
    "export.Lista de limpezas realizadas": "Λίστα πραγματοποιημένων καθαρισμών",
    "export.Lista de eventos ocorridos": "Λίστα των γεγονότων που συνέβησαν",
    "export.Exportar dados": "Εξαγωγή δεδομένων",
    "export.Informações do equipamento": "Πληροφορίες εξοπλισμού",
    "export.Dados exportados com sucesso!": "Τα δεδομένα εξήχθησαν με επιτυχία!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Δεν ήταν δυνατή η εξαγωγή των δεδομένων. Παρακαλώ δοκιμάστε ξανά αργότερα.",
    "export.Detalhes do menu": "Λεπτομέρειες μενού",
    "export.Detalhes de receitas": "Λεπτομέρειες συνταγών",
    "export.Detalhes e Passos": "Λεπτομέρειες και Βήματα",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Εξαγωγή δεδομένων. Το αρχείο θα είναι σύντομα διαθέσιμο!",
    "export.Resumo dos passos": "Περίληψη των βημάτων",
    "export.salvar imagem": "αποθήκευση εικόνας"
  },
};

export default el;
