import { LOCALES } from '../constants';
const es = {
  [LOCALES.ES]: {
    //LOGIN
    "login.Login": "Inicio de sesión",
    "login.Informe seu e-mail": "Ingresa tu correo electrónico",
    "login.E-mail inválido. Por favor, verifique": "Correo electrónico no válido. Por favor, verifica.",
    "login.Senha": "Contraseña",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Tu contraseña debe tener al menos 6 caracteres.",
    "login.Permanecer conectado": "Permanecer conectado",
    "login.Esqueci minha senha": "Olvidé mi contraseña",
    "login.ENTRAR": "INGRESAR",
    "login.CRIAR CONTA": "CREAR CUENTA",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "No fue posible iniciar sesión. Verifica tus datos e intenta nuevamente.",
    "login.Solicitar Acesso": "Solicitar acceso",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Utiliza esta opción en caso de que solo tengas hornos de versiones antiguas que no generan PIN.",
    "login.A PLATAFORMA IOK MUDOU!": "¡LA PLATAFORMA IOK HA CAMBIADO!",
    "login.Não exibir este aviso novamente": "No mostrar este aviso nuevamente.",
    "login.Se você já possui um cadastro na antiga plataforma,": "Si ya tienes un registro en la plataforma anterior,",
    "login.clique aqui": "haz clic aquí",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "para migrar tus datos y disfrutar de la experiencia completa de IOK.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Tu cuenta no ha sido activada. Por favor, verifica tu correo electrónico para activarla",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Para solicitar acceso a una organización, el nombre que proporcionas debe ser exactamente el mismo que el registrado por el administrador de la cuenta. En caso de duda, busca la cadena responsable.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Si ya tienes un registro en la plataforma antigua, ",
    "login. para": " pour ",
    "login.migrar": "emigrar ",
    "login.seus dados e aproveitar toda a experiência IOK.": "tus datos y disfruta de toda la experiencia IOK.",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATAFORMA EN MANTENIMIENTO",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "¡ESTAMOS PREPARANDO LA NUEVA PLATAFORMA IOK Y PRONTO ESTARÁ LISTA!",
    "login.Migrar Conta": "Cuenta migrante",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Si ya tienes un registro en la plataforma anterior, haz clic aquí en Migrar Cuenta para migrar tus datos y disfrutar de toda la experiencia IOK",
    "login.Política de privacidade": "Política de privacidad",
    "login.ENTENDI": "ENTENDÍ",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "Utilizamos cookies para mejorar su experiencia en la plataforma y ofrecer servicios personalizados.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "Al utilizar la Plataforma IOK, automáticamente acepta su uso.",
    "login.Senha incorreta! Duas tentativas restantes antes de ter o acesso à conta bloqueado.": "¡Contraseña incorrecta! Quedan dos intentos antes de que se bloquee el acceso a la cuenta.",
    "login.Senha incorreta! Uma tentativa restantes antes de ter o acesso à conta bloqueado.": "¡Contraseña incorrecta! Queda un intento antes de que se bloquee el acceso a la cuenta.",
    "login.Acesso bloqueado! Siga as instruções de recuperação de conta.": "¡Acceso bloqueado! Siga las instrucciones de recuperación de cuenta.",

    //REGISTER
    "DISTRIBUIDOR": "DISTRIBUIDOR: Soy distribuidor de equipos de Prática.",
    "REDE": "RED: Soy dueño de una red.",
    "DONO": "EMPRESA: Soy dueño de mi propia empresa/soy parte de una red.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Ingresa tu correo electrónico registrado en la plataforma IOK anterior.",
    "register.E-mail inválido. Por favor, verifique": "Correo electrónico no válido. Por favor, verifica.",
    "register.BUSCAR DADOS": "BUSCAR DATOS",
    "register.Nome da organização": "Nombre de la organización",
    "register.Informe o nome de sua organização": "Ingresa el nombre de tu organización.",
    "register.O campo nome da organização é obrigatório": "El campo del nombre de la organización es obligatorio.",
    "register.Este nome já existe. Por favor, escolha outro": "Este nombre ya existe. Por favor, elige otro.",
    "register.RECUPERAR CONTA": "RECUPERAR CUENTA",
    "register.E-mail não encontrado": "Correo electrónico no encontrado.",
    "register.Digite seu e-mail da plataforma IOK antiga": "Ingresa tu correo electrónico de la antigua plataforma IOK.",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "No fue posible recuperar tu cuenta. Verifica tus datos e inténtalo nuevamente.",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "No fue posible recuperar tus datos. Verifica e inténtalo nuevamente.",
    "register.PROSSEGUIR": "CONTINUAR",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "ESTÁS SOLICITANDO ACCESO A LA ORGANIZACIÓN:",
    "register.COM O ADMINISTRADOR": "CON ADMINISTRADOR:",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "El correo electrónico ya está registrado en la plataforma. Ingresa un nuevo correo electrónico o accede a la plataforma con el que ya está registrado.",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "La empresa ya está registrada en la plataforma. Por favor, ingresa un nuevo nombre de empresa.",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "NO RECONOCISTE AL USUARIO {userName} COMO PARTE DE TU ORGANIZACIÓN Y POR ESO SU CUENTA FUE ELIMINADA.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "NO FUE POSIBLE ELIMINAR AL USUARIO {userName}. POR FAVOR, INTÉNTALO NUEVAMENTE MÁS TARDE.",
    "register.CONFIRMAR": "CONFIRMAR",
    "register.Informe o ID da organização": "Ingresa el ID de tu organización.",
    "register.O campo com ID é obrigatório": "El campo ID es obligatorio.",
    "register.Ainda não tenho uma organização. Criar": "Todavía no tengo una organización. Crear",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "El ID proporcionado no coincide con ninguna empresa. Verifica e inténtalo nuevamente.",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "EL ADMINISTRADOR DE LA ORGANIZACIÓN RECIBIRÁ UN CORREO ELECTRÓNICO PARA ACTIVAR TU CUENTA DE USUARIO. POR FAVOR, ESPERA.",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Esta franquicia ya tiene una cuenta activa en la plataforma.",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "No fue posible registrarse. Verifica tus datos e inténtalo nuevamente.",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "RECIBIRÁS UN CORREO ELECTRÓNICO PARA FINALIZAR TU REGISTRO Y ACTIVAR TU CUENTA DE ADMINISTRADOR.",
    "register.Nome": "Nombre",
    "register.Informe seu nome": "Ingresa tu nombre.",
    "register.O campo nome é obrigatório": "El campo del nombre es obligatorio",
    "register.E-mail": "Correo electrónico",
    "register.Informe seu e-mail": "Ingresa tu correo electrónico.",
    "register.E-mail inválido. Por favor, verifique novamente": "Correo electrónico no válido. Por favor, verifica",
    "register.Telefone": "Número de teléfono",
    "register.Informe seu telefone": "Ingresa tu número de teléfono",
    "register.Crie sua senha": "Crear una contraseña",
    "register.Informe sua senha": "Ingresa tu contraseña",
    "register.O campo senha deve conter no mínimo 6 caracteres": "El campo de la contraseña debe tener al menos 6 caracteres",
    "register.Confirme sua senha": "Confirmar contraseña",
    "register.As senhas devem ser iguais": "La contraseña debe coincidir",
    "register.CRIAR CONTA": "CREAR CUENTA",
    "register.CONTA ATIVADA COM SUCESSO": "CUENTA ACTIVADA CON ÉXITO",
    "register.ACESSAR": "ACCESO",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "NO FUE POSIBLE ACTIVAR TU CUENTA.",
    "register.Digite seu telefone": "Informa tu número de teléfono.",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "POR FAVOR ESPERE MIENTRAS TRANSFERIMOS SUS DATOS. ESTE PROCESO DEBE TOMAR UNOS MINUTOS. SI TIENES UNA GRAN CANTIDAD DE DATOS, LA TRANSFERENCIA PUEDE TARDAR HASTA UNA HORA.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "¡TRANSFERENCIA DE DATOS EXITOSA! HAZ CLIC EN EL BOTÓN DE ABAJO PARA ACCEDER A LA PLATAFORMA.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "CORREO ELECTRÓNICO NO VÁLIDO. POR FAVOR, VERIFICA SI TU CUENTA YA HA SIDO ACTIVADA.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "La nueva plataforma IOK ahora cuenta con nuevas formas de acceso, que están relacionadas con el tipo de organización en la que trabajas. El responsable de tu organización debe migrar la cuenta principal de la empresa, convirtiéndose en administrador, mientras que el resto de los empleados deben vincularse utilizando el ID proporcionado.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "Mi administrador de organización ya ha migrado la cuenta y tenemos un ID. Quiero migrar los datos de mi usuario.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Quiero migrar la cuenta de mi organización y convertirme en el administrador.",
    "register.Conta da organização já migrada": "Cuenta de organización ya migrada.",
    "register.Migrar conta da organização": "Migrar cuenta de organización.",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "TIENE MUCHOS MENÚS, NO PODEMOS REALIZAR IMPORTACIÓN AUTOMÁTICA. SU CUENTA SE ACTIVARÁ, PERO LOS MENÚS DEBEN MIGRARSE MANUALMENTE.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "PARA MIGRAR SUS MENÚS, DESPUÉS DE INICIAR SESIÓN, HAGA CLIC EN SU NOMBRE EN LA ESQUINA SUPERIOR DERECHA, HAGA CLIC EN EDITAR CUENTA Y LUEGO EN IMPORTAR MENÚS.",
    "register.Atenção:": "Atención:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "solo una persona de la organización puede crear la cuenta y, después de activarla, invitar a los demás usuarios de la empresa a acceder a ella.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "TOKEN DE DESARROLLADOR CANCELADO",
    "register.Sua senha deve estar entre 8 e 20 caracteres, ter uma letra maiúscula, uma minúscula, um número e um caractere especial" : "Su contraseña debe tener entre 8 y 20 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial",

    //HOME
    "home.Menus disponíveis na plataforma": "Menús disponibles en la plataforma",
    "home.Criados na plataforma": "Creado en la plataforma",
    "home.Recebidos dos fornos": "Recebidos dos fornos",
    "home.Equipamentos vinculados": "Equipos vinculados",
    "home.Total de receitas cadastradas": "Total de recetas registradas",
    "home.Receitas": "Recetas",
    "home.Receitas em menus": "Recetas en menús",
    "home.Em menus": "En menús",
    "home.Receitas salvas no Cookbook": "Recetas guardadas en el Cookbook",
    "home.No Cookbook": "En el Cookbook",
    "home.Atualizações": "Actualizaciones",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Versión instalada",
    "home.Versão disponível": "Versión disponible",
    "home.Usuários cadastrados": "Usuarios registrados",
    "home.Aceitação pendente": "Aceptación pendiente",
    "home.Atualizado": "Actualizado",
    "home.OLÁ": "HOLA",
    "home.É muito bom ter você aqui!": "¡Es genial tenerte aquí!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "¡Con la Plataforma IOK podrás gestionar tus hornos desde donde estés, crear y gestionar recetas y menús, realizar copias de seguridad, analizar datos de tu equipo y mucho más!",
    "home.Conheça a plataforma": "Descubre la plataforma",
    "home.Taxa de erro por modelo": "Tasa de error por modelo",
    "home.Erro": "Error",
    "home.Modelos em evidência": "Modelos destacados",
    "home.Modelos": "Modelos",
    "home.Total de equipamentos vinculados": "Total de equipos vinculados",
    "home.Total de equipamentos online": "Total de equipos en línea",
    "home.Equipamentos online": "Equipos en línea",
    "home.Países com Prática": "Países con Prática",
    "home.Países": "Países",
    "home.Aguarde enquanto carregamos os dados": "Espere mientras cargamos los datos",
    "home.Quase lá! Aguarde um pouco mais": "¡Casi listo! Espere un poco más",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "QUEREMOS SABER TU OPINIÓN SOBRE",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "¡Valoramos mucho tu punto de vista y, por eso, se creó este formulario de encuesta de satisfacción en la Plataforma {model}!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "¡Valoramos mucho tu punto de vista y por eso creamos este formulario de encuesta de satisfacción sobre la línea {model}!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Sus respuestas nos ayudarán a mejorar nuestros servicios, asegurando que sigamos brindándole la mejor experiencia posible",
    "home.Reserve uns minutinhos de seu dia e participe!": "¡Tómate unos minutos de tu día y participa!",
    "home.Responder a pesquisa": "Responde la encuesta",
    "home.Vendas por Equipamento": "Ventas por Equipo",
    "home.Total de equipamentos vendidos": "Total de Equipos Vendidos",
    "home.Equipamentos vendidos": "Equipos Vendidos",

    //MAIN
    "main.HOME": "INICIO",
    "main.COOKBOOK": "COOKBOOK",
    "main.MEUS MENUS": "MIS MENÚS",
    "main.EQUIPAMENTOS": "EQUIPAMIENTO",
    "main.USUÁRIOS": "USUARIOS",
    "main.ATENDIMENTOS": "SERVICIOS",
    "main.Idioma": "Idioma",
    "main.Editar conta": "Editar cuenta",
    "main.Configurações": "Configuraciones",
    "main.Sair": "Cerrar sesión",
    "main.Configurações salvas com sucesso!": "!Configuraciones guardadas exitosamente!",
    "main.SALVAR": "GUARDAR",
    "main.CONFIGURAÇÕES": "CONFIGURACIONES",
    "main.Tema dark": "Tema oscuro",
    "main.Notificações": "Notificaciones",
    "main.Informações para as receitas": "Información de recetas",
    "main.Peso:": "Peso",
    "main.Gramas": "Gramos",
    "main.Oz": "Onzas",
    "main.Temperatura:": "Temperaturas:",
    "main.Celsius (°C)": "Celsius (°C)",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Cuenta editada exitosamente!",
    "main.EDITAR CONTA": "EDITAR CUENTA",
    "main.Nome": "Nombre",
    "main.Preencha com o seu nome": "Ingresa tu nombre",
    "main.Telefone": "Teléfono",
    "main.Preencha com o seu telefone": "Ingresa tu número de teléfono",
    "main.E-mail": "Correo electrónico",
    "main.Preencha com o seu e-mail": "Ingresa tu correo electrónico",
    "main.Alterar Senha": "Cambiar contraseña",
    "main.ALTERAR SENHA": "CAMBIAR CONTRASEÑA",
    "main.Senha atual": "Contraseña actual",
    "main.Nova senha": "Nueva contraseña",
    "main.Confirmar nova senha": "Confirmar nueva contraseña",
    "main.Informe sua senha atual": "Ingresa tu contraseña actual",
    "main.O campo senha deve conter no mínimo 6 caracteres": "El campo de la contraseña debe tener al menos 6 caracteres",
    "main.Informe sua nova senha": "Ingresa tu nueva contraseña",
    "main.Confirme sua nova senha": "Confirma tu nueva contraseña",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Tu nueva contraseña debe tener al menos 6 caracteres",
    "main.INFORME SUA SENHA": "INGRESA TU CONTRASEÑA",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Ingresa tu contraseña para cambiar tu correo electrónico en la plataforma:",
    "main.CONFIRMAR": "CONFIRMAR",
    "main.Existem campos que não foram preenchidos corretamente": "Hay campos que no se han llenado correctamente",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Ingresa tu contraseña para proceder con el cambio de correo electrónico",
    "main.Senha alterada com sucesso": "Contraseña cambiada exitosamente",
    "main.Informe sua senha atual corretamente": "Ingresa tu contraseña actual correctamente",
    "main.As senhas devem ser iguais": "Las contraseñas deben coincidir",
    "main.Nome do novo tipo de usuário": "Nombre del nuevo tipo de usuario",
    "main.Dê um nome para o novo tipo de usuário": "Asigna un nombre al nuevo tipo de usuario",
    "main.Token Desenvolvedor": "Token de Desarrollador",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "El Token de Desarrollador es un código único e intransferible que permite la realización de cambios a nivel de desarrollo directamente en el código de la plataforma mediante APIs externas. Estas posibilitan la personalización de la plataforma para satisfacer mejor las necesidades de su empresa.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Se recomienda que estos cambios sean realizados por un profesional del área de tecnología.",
    "main.Para acessar o token, informe sua senha": "Para acceder al token, ingrese su contraseña.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Copie y pegue el token en la autenticación de todas las solicitudes.",
    "main.Seu Token": "Su token",
    "main.Token copiado com sucesso!": "¡Token copiado correctamente!",
    "main.Você deseja cancelar o preenchimento das informações?": "¿Desea cancelar el llenado de la información?",
    "main.Rever tutoriais": "Revisar tutoriales",
    "main.Tutoriais resetados com sucesso": "Tutoriales restablecidos con éxito",
    "main.review.tutorials": "¿Desea revisar los tutoriales de la plataforma IOK?",
    "main.Procurar": "Buscar",

    //MENUS
    "menus.Procurar menu": "Menú de búsqueda o usuario",
    "menus.Procurar menu excluído": "Buscar menú eliminado",
    "menus.Menus": "Menús",
    "menus.Lixeira": "Papelera",
    "menus.CRIAR MENU": "CREAR MENÚ",
    "menus.IMPORTAR MENU": "IMPORTAR MENÚ",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Los archivos se mantendrán en la papelera durante 30 días desde la fecha de eliminación. Después de este período, serán eliminados definitivamente.",
    "menus.VOLTAR": "REGRESO",
    "menus.Compatível com: ": "Compatible con: ",
    "menus.Última atualização: ": "Última actualización: ",
    "menus.Última atualização": "Última actualización",
    "menus.EXPORTAR MENU": "EXPORTAR MENÚ",
    "menus.EDITAR MENU": "EDITAR MENÚ",
    "menus.Equipamentos associados": "Equipo asociado",
    "menus.Grupos": "Grupos",
    "menus.Procurar grupo": "Buscar grupo",
    "menus.CRIAR GRUPO": "CREAR GRUPO",
    "menus.receitas": "Recetas",
    "menus.Nenhum grupo cadastrado": "Ningún grupo registrado",
    "menus.Nenhum menu cadastrado": "Ningún menú registrado",
    "menus.Nome": "Nombre",
    "menus.Modelo": "Modelo",
    "menus.Atividade": "Actividad",
    "menus.Associação": "Asociación",
    "menus.Exportar": "Exportar",
    "menus.Editar": "Editar",
    "menus.Excluir": "Eliminar",
    "menus.Equips": "Equipar",
    "menus.Receitas": "Recetas",
    "menus.Compatibilidade": "Compatibilidad",
    "menus.Versão": "Versión",
    "menus.Origem": "Origen",
    "menus.Excluído por: ": "Eliminado por: ",
    "menus.Restaurar": "Restaurar",
    "menus.Existem campos que não foram preenchidos": "Hay campos que no han sido llenados.",
    "menus.Menu criado com sucesso!": "Menú creado exitosamente!",
    "menus.Existem campos com informações inválidas": "Hay campos con información inválida",
    "menus.SALVAR": "GUARDAR",
    "menus.CANCELAR": "CANCELAR",
    "menus.Nome do menu:": "Nombre del menú: ",
    "menus.Informe o nome do menu": "Informar nombre del menú",
    "menus.Preencha o nome do menu sem caracteres especiais": "Llenar el nombre del menú sin caracteres especiales",
    "menus.Selecionar": "Seleccionar",
    "menus.Idioma": "Idioma",
    "menus.Temperatura de pré-aquec 1:": "Temperatura de precalentamiento 1:",
    "menus.Informe a temperatura de pré-aquec 1": "Informar temperatura de precalentamiento 1",
    "menus.Temperatura de pré-aquec 2:": "Temperatura de precalentamiento 2:",
    "menus.Informe a temperatura de pré-aquec 2": "Informar temperatura de precalentamiento 2",
    "menus.Tempo para estabilização:": "Tiempo de estabilización:",
    "menus.Informe o tempo para estabilização": "Informar tiempo de estabilización",
    "menus.Desativar pré-aquec 2": "Desactivar precalentamiento 2",
    "menus.Configurações de data e hora": "Configuraciones de fecha y hora",
    "menus.Data:": "Fecha:",
    "menus.DD/MM/AAAA": "DD/MM/AAAA",
    "menus.Hora:": "Hora:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: DD/MM/AAAA",
    "menus.USA: MM/DD/AAAA": "USA: MM/DD/AAAA",
    "menus.ISO: AAAA/MM/DD": "ISO: AAAA/MM/DD",
    "menus.Seleção de acessos para habilitar": "Selección de acceso para habilitar",
    "menus.Modo manual": "Modo manual",
    "menus.Habilitado": "Habilitado",
    "menus.Desabilitado": "Deshabilitado",
    "menus.Favoritos": "Favoritos",
    "menus.Aquecer/Dourar": "Calor/Dorado",
    "menus.Repetir receita": "Repetir receta",
    "menus.Configurações gerais do menu": "Configuraciones generales del menú",
    "menus.Temperatura": "Temperatura",
    "menus.Temperatura:": "Temperatura:",
    "menus.Celsius (°C)": "Celsius (°C)",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Peso": "Peso",
    "menus.Gramas": "Gramos",
    "menus.Oz": "Onza",
    "menus.Tema:": "Tema:",
    "menus.Dark": "Oscuro",
    "menus.Light": "Claro",
    "menus.Introdução:": "Introducción:",
    "menus.Ativada": "Activado",
    "menus.Desativada": "Desactivado",
    "menus.Menu deletado com sucesso!": "Menú eliminado exitosamente!",
    "menus.Não foi possivel deletar o menu": "No se puede eliminar el menú",
    "menus.Sim": "Sí",
    "menus.Não": "No",
    "menus.Excluir definitivamente": "Eliminar permanentemente",
    "menus.SALVAR EM PEN DRIVE": "GUARDAR EN UNA MEMORIA USB",
    "menus.EXPORTAR": "EXPORTAR",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Seleccione los modelos y versiones de equipos para recibir el menú",
    "menus.Forno": "Horno",
    "menus.Fundo verde": "Fondo verde",
    "menus.Fundo preto": "Fondo negro",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Atención: antes de transferir el menú desde su memoria USB al equipo, es necesario descomprimir el archivo, excepto para los menús de equipos Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Seleccione el equipo para el cual desea exportar el menú.",
    "menus.Nome do equipamento": "Nombre del equipo",
    "menus.Selecionar todos": "Seleccionar todo",
    "menus.Selecionar todas": "Seleccionar todo",
    "menus.Categoria: ": "Categoría: ",
    "menus.Você deseja excluir o menu?": "¿Desea eliminar el menú?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "El menú se eliminará de la plataforma, pero se mantendrá en el equipo. Se creará automáticamente una copia de seguridad que estará disponible durante 30 días en Mis Menús - Papelera.",
    "menus.Você deseja excluir o arquivo definitivamente?": "¿Desea eliminar el archivo permanentemente?",
    "menus.Esta ação não poderá ser desfeita.": "Esta acción no se puede deshacer.",
    "menus.Menu editado com sucesso!": "Menú editado exitosamente!",
    "menus.IMPORTAR": "IMPORTAR",
    "menus.VERIFICAÇÃO DE VERSÃO": "VERIFICACIÓN DE VERSIÓN",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Para verificar la versión de la placa del equipo, siga los siguientes pasos:",
    "menus.No forno, acesse Configurações;": "En el horno, acceda a Configuraciones;",
    "menus.Insira a senha 456789;": "Ingrese la contraseña 456789;",
    "menus.Selecione a opção Sistema;": "Seleccione la opción Sistema;",
    "menus.Selecione Informações do Fabricante;": "Seleccione Información del fabricante;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "El modelo de su placa estará en la línea Firmware IHM;",
    "menus.1ª geração A": "1ra generación A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1ra generación B",
    "menus.2ª geração/Linux:": "2da generación/Linux",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Seleccione el modelo/versión del equipo desde el cual desea importar el menú. Sepa cómo verificar la versión de su equipo ",
    "menus.Selecione o modelo/versão do equipamento": "Seleccione el modelo/versión del equipo",
    "menus.Como verificar a versão da placa": "Cómo verificar la versión de la placa",
    "menus.1° Geração": "1ra generación",
    "menus.Menu restaurado com sucesso!": "Menú restaurado exitosamente!",
    "menus.Não foi possivel restaurar o menu": "No se puede restaurar el menú",
    "menus.RESTAURAR ARQUIVO": "RESTAURAR ARCHIVO",
    "menus.Você deseja restaurar o arquivo?": "¿Desea restaurar el archivo?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "El archivo se restaurará como una nueva versión en la plataforma en la pestaña Todo. Para guardarlo en su dispositivo, expórtelo.",
    "menus.EXCLUIR MENU": "ELIMINAR MENÚ",
    "menus.Nenhum equipamento associado": "Ningún equipo asociado",
    "menus.Nomeie o novo menu": "Nombrar el nuevo menú",
    "menus.Nomeie o novo grupo": "Nombrar el nuevo grupo",
    "menus.Nome do grupo:": "Nombre del grupo: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Llene el nombre del grupo sin caracteres especiales",
    "menus.Pré-aquecimento": "Precalentamiento",
    "menus.Pré-aquecimento:": "Precalentamiento:",
    "menus.Grupo criado com sucesso!": "Grupo creado exitosamente!",
    "menus.SELECIONE UMA IMAGEM": "SELECCIONAR UNA IMAGEN",
    "menus.SELECIONAR": "SELECCIONAR",
    "menus.Selecionar do computador": "Seleccionar desde la computadora",
    "menus.Selecionar do banco de imagens": "Seleccionar desde el banco de imágenes",
    "menus.Formato .JPG ou .PNG": "Formato .JPG o .PNG",
    "menus.Procurar imagem": "Buscar imagen",
    "menus.Pertence ao menu": "Pertenece al menú",
    "menus.Alterar imagem": "Cambiar imagen",
    "menus.Remover imagem": "Quitar imagen",
    "menus.EDITAR GRUPO": "EDITAR GRUPO",
    "menus.CRIAR RECEITA": "CREAR RECETA",
    "menus.SALVAR DO COOKBOOK": "GUARDAR DESDE EL LIBRO DE COCINA",
    "menus.Procurar receita do grupo": "Buscar receta del grupo",
    "menus.Ingrediente principal": "Ingrediente principal",
    "menus.Mover receitas": "Mover recetas",
    "menus.Excluir receitas": "Eliminar recetas",
    "menus.Editar grupo": "Editar grupo",
    "menus.Excluir grupo": "Eliminar grupo",
    "menus.aqui": "aquí",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Si el código tiene solo números y el fondo es verde, pertenece a 1ra A; si tiene solo números y el fondo es negro, pertenece a 1ra B y, si comienza con las letras L, F o S, su placa pertenece a 2da generación/Linux.",
    "menus.1ª A - Fundo verde": "1ra A - Fondo verde",
    "menus.1ª B - Fundo preto": "1ra B - Fondo negro",
    "menus.2ª - Linux": "2da - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Hemos tenido un problema. Intente nuevamente más tarde.",
    "menus.Grupo editado com sucesso!": "Grupo editado exitosamente!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "AÚN NO HAY GRUPOS AQUÍ :(",
    "menus.NENHUM MENU NA LIXEIRA": "NO HAY MENÚ EN LA BASURA",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "AÚN NO HAY MENÚS AQUÍ :(",
    "menus.Adicionar imagem": "Agregar imagen",
    "menus.Nome da receita": "Nombre de la receta",
    "menus.Origem da receita": "Origen de la receta",
    "menus.Detalhes da receita": "Detalles de la receta",
    "menus.Programação da receita": "Programación de la receta",
    "menus.Nomeie sua receita": "Nombre su receta",
    "menus.Preencha o nome da receita sem caracteres especiais": "Complete el nombre de la receta sin caracteres especiales",
    "menus.Tipo de prato": "Tipo de comida",
    "menus.SALVAR RECEITAS DO COOKBOOK": "GUARDAR RECETAS DESDE EL LIBRO DE COCINA",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "Seleccione las recetas que desea guardar en su grupo de menú:",
    "menus.Procurar receita": "Buscar receta",
    "menus.Grupo deletado com sucesso!": "Grupo eliminado exitosamente!",
    "menus.Não foi possivel deletar o grupo": "No se puede eliminar el grupo",
    "menus.EXCLUIR GRUPO": "ELIMINAR GRUPO",
    "menus.Você deseja excluir o grupo definitivamente?": "¿Desea eliminar definitivamente el grupo?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "AÚN NO HAY RECETAS AQUÍ :(",
    "menus.Temp. de entrada": "Temperatura inicial",
    "menus.Temp. de pré-aquec": "Temperatura de precalentamiento",
    "menus.Ingredientes para montagem": "Ingredientes para el montaje",
    "menus.Instruções operacionais": "Instrucciones de operación",
    "menus.Passo": "Paso",
    "menus.Temp": "Temp ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Tiempo ",
    "menus.Velocidade do ar": "Velocidad del aire",
    "menus.Micro-ondas": "Microondas",
    "menus.Resistência inferior": "Resistencia inferior",
    "menus.Informações": "Información",
    "menus.Digite as informações do passo": "Escriba la información del paso",
    "menus.desativar": "desactivar ",
    "menus.Ingredientes": "Ingredientes",
    "menus.Temperatura de entrada": "Temperatura inicial",
    "menus.Equipamentos compatíveis": "Equipo compatible",
    "menus.SALVAR NO COOKBOOK": "GUARDAR EN EL COOKBOOK",
    "menus.ver localização": "ver ubicación",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "SELECCIONAR RECETAS PARA ELIMINAR",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "¿Desea eliminar definitivamente las recetas seleccionadas?",
    "menus.Receitas deletadas com sucesso!": "Recetas eliminadas exitosamente!",
    "menus.Informe aqui os ingredientes para montagem": "Informe aquí los ingredientes para el montaje",
    "menus.Informe aqui as instruções operacionais": "Informe aquí las instrucciones de operación",
    "menus.Adicionar passo": "Agregar paso",
    "menus.Receita criada com sucesso!": "Receta creada exitosamente!",
    "menus.Ideal para:": "Ideal para: ",
    "menus.editar detalhes": "editar detalles",
    "menus.excluir": "eliminar",
    "menus.editar": "editar",
    "menus.Receita editada com sucesso!": "Receta editada exitosamente!",
    "menus.Passo deletado com sucesso!": "Paso eliminado exitosamente!",
    "menus.Não foi possivel deletar o passo": "No se puede eliminar el paso",
    "menus.EXCLUIR PASSO": "ELIMINAR PASO",
    "menus.Você deseja excluir este passo": "¿Desea eliminar este paso?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Esta acción no se puede deshacer. Si lo prefiere, es posible desactivarlo manteniendo su configuración.",
    "menus.Plataforma": "Plataforma",
    "menus.Receita salva com sucesso!": "Receta guardada exitosamente!",
    "menus.Exportar receitas": "Exportar recetas",
    "menus.expandir": "expandir",
    "menus.Vapor combinado": "Vapor combinado",
    "menus.Ar quente": "Aire caliente",
    "menus.Sonda": "Sonda",
    "menus.Tempo (min)": "Tiempo (min)",
    "menus.Umidade (%)": "Humedad (%)",
    "menus.Injeção de vapor": "Inyección de vapor",
    "menus.Dumper aberto": "Basculante abierto",
    "menus.Informe o peso": "Informe el peso",
    "menus.Informe a temperatura de entrada": "Informe la temperatura inicial",
    "menus.Informe os ingredientes para montagem": "Ingrese los ingredientes para el montaje",
    "menus.Informe as instruções operacionais": "Ingrese las instrucciones de operación",
    "menus.Injeção de vapor (seg)": "Inyección de vapor (seg)",
    "menus.Aquecer mais": "Calentar más",
    "menus.Dourar mais": "Dorar más",
    "menus.Aquecer e dourar mais": "Calentar y dorar más",
    "menus.Favoritar receita": "Receta favorita",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Ingrese el tiempo para calentar más de 0 a 59 segundos",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Ingrese el tiempo para dorar más de 0 a 59 segundos",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Ingrese el tiempo para calentar y dorar más de 0 a 59 segundos",
    "menus.Apenas favoritos": "Solo favoritos",
    "menus.Forno combinado": "Horno combinado",
    "menus.Cocção múltipla": "Cocción múltiple",
    "menus.Receitas Technicook": "Recetas Technicook",
    "menus.Editar grupos": "Editar grupos",
    "menus.Informe a data": "Ingresar fecha",
    "menus.Informe a hora": "Ingresar hora",
    "menus.até 300": "hasta 300",
    "menus.Vapor": "Vapor ",
    "menus.Turbina": "Turbina",
    "menus.Criar grupo": "Crear grupo",
    "menus.MOVER": "MOVER ",
    "menus.Configurações de acessos": "Acceder a configuraciones:",
    "menus.SELECIONE RECEITAS PARA MOVER": "SELECCIONAR RECETAS PARA MOVER",
    "menus.AVANÇAR": "AVANZAR",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Seleccione o cree un grupo en el mismo menú para mover recetas seleccionadas o muévalas a otro menú. Tenga en cuenta que solo se mostrarán los menús/grupos que sean compatibles con las configuraciones preestablecidas de la receta.",
    "menus.Mover receitas para o mesmo menu": "Mover recetas al mismo menú",
    "menus.Mover receitas para outro menu": "Mover recetas a otro menú",
    "menus.Selecionar grupo": "Seleccionar grupo",
    "menus.Nenhuma receita foi selecionada": "No se seleccionó ninguna receta",
    "menus.Passo editado com sucesso!": "Paso editado exitosamente!",
    "menus.Menu atualizado": "Menú actualizado",
    "menus.até 260": "hasta 260",
    "menus.Umidade na câmara": "Humedad de la cámara",
    "menus.Passos": "Pasos",
    "menus.ativar": "activar",
    "menus.Informe a temperatura de pré-aquec": "Ingrese la temperatura de precalentamiento",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Mover recetas seleccionadas a otro menú. Tenga en cuenta que solo se mostrarán los menús que sean compatibles con las configuraciones preestablecidas de la receta.",
    "menus.Sonda (°C)": "Sonda (ºC)",
    "menus.Turbo Grill": "Grill Turbo",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Precalentamiento estándar, según el paso 1",
    "menus.salvar": "guardar",
    "menus.até": "hasta",
    "menus.Verificação de receita": "Verificación de la receta",
    "menus.Auto import": "Importación automática",
    "menus.Atualização forçada": "Actualización forzada",
    "menus.Lista de receitas exportada com sucesso!": "Lista de recetas exportada exitosamente!",
    "menus.Receita movida com sucesso!": "Receta movida exitosamente!",
    "menus.Receitas movidas com sucesso!": "Recetas movidas exitosamente!",
    "menus.+ Receita": "Receta",
    "menus.Escolha um idioma compatível com o equipamento": "Seleccione un idioma compatible con el equipo",
    "menus.MENU IMPORTADO COM SUCESSO!": "Menú importado correctamente!",
    "menus.Dourar": "Marrón",
    "menus.Não atribuir a menu": "No asignar al menú",
    "menus.Todos": "Todos",
    "menus.Meus menus": "Mis menús",
    "menus.Para salvar uma receita, preencha todos os campos": "Para guardar una receta, complete todos los campos",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Seleccione los grupos de equipos para los que desea exportar el menú.",
    "menus.Você deseja excluir este passo?": "¿Desea eliminar este paso?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Formato no válido. Solo se permiten los formatos .jpeg y .png.",
    "menus.Reordenar grupos": "Reordenar grupos",
    "menus.Reordenar receitas": "Reordenar recetas",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "¡ELEMENTOS REORDENADOS CON ÉXITO! PARA APLICAR LOS CAMBIOS EN EL HORNO, EXPORTA EL MENÚ.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Arrastra los elementos a la ubicación deseada.",
    "menus.Dumper": "Compuerta",
    "menus.Aberto": "Abierto",
    "menus.Fechado": "Cerrado",
    "menus.Intermitente": "Intermitente",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Si su menú incluye elementos que son sensibles a la uniformidad, le recomendamos configurar el tiempo de establecimiento entre 3:00 y 5:00 minutos. En caso contrario puedes dejarlo en 0:00 minutos.",
    "menus.Selecione o menu que deseja exportar:": "Seleccione el menú que desea exportar:",
    "menus.Temperatura de pré-aquecimento:": "Temperatura de precalentamiento:",
    "menus.Temperatura de lastro": "Temperatura de lastre",
    "menus.Temperatura de teto": "Temperatura del techo",
    "menus.Volume de injeção de vapor (ml)": "Volumen de inyección de vapor (ml)",
    "menus.Parada de turbina": "Parada de turbina",
    "menus.Alta": "Alta",
    "menus.Média": "Media",
    "menus.Baixa": "Baja",
    "menus.a": "el",
    "menus.Valor da injeção": "Valor de inyección",
    "menus.Volume: até 999ml": "Volumen: hasta 999ml",
    "menus.Tempo: até 15 segundos": "Tiempo: hasta 15 segundos",
    "menus.Tempo de injeção de vapor (seg)": "Tiempo de inyección de vapor (seg)",
    "menus.Atenção!": "¡Atención!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Consulte las recetas del menú y ajuste los valores de inyección de vapor después de cambiar su unidad de medida.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Asegúrese de que la unidad de temperatura (°C o °F) definida en el equipo sea la misma que la seleccionada al crear el menú:",
    "menus.Injeção desativada": "Inyección deshabilitada",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Seleccione la unidad de temperatura que está definida en el equipo:",
    "menus.Clique para atualizar": "Haga clic para actualizar",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados, e que possuam versão compatível com este tipo de atualização, na próxima vez em que forem ligados. Os fornos que não estiverem atualizados ainda precisarão realizar o aceite manual.": "La función Actualización Forzada envía automáticamente el menú al equipo seleccionado, que tenga una versión compatible con este tipo de actualización, la próxima vez que se encienda. Los hornos que no estén actualizados deberán someterse a una aceptación manual.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Las opciones seleccionadas estarán disponibles en el equipo que reciba este menú y podrán ser utilizadas por el operador. Es posible cambiar estos ajustes más tarde en el propio horno o a través de la plataforma.",
    "menus.+ Menu": "+ Menú",
    "menus.Não foi possivel deletar as receitas": "No se pueden eliminar recetas",
    "menus.PRÉ": "PRE",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "Hornos Combinados",
    "menus.Fornos de Panificação": "Hornos de Panadería",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "Después de la creación, el tipo de menú no se puede cambiar. Solo se puede definir en el momento de la creación.",
    "menus.Pré-aquecimento do grupo": "Precalentamiento del grupo",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, transferindo a pasta PRATICA para o equipamento." : "Atención: antes de transferir el menú de su pendrive al equipo, debe descomprimir el archivo, transfiriendo la carpeta PRATICA al equipo.",
    "menus.Tamanho máximo": "Tamaño máximo",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "RECETAS DISPONIBLES",
    "cookbook.RECEITAS SALVAS": "RECETAS GUARDADAS",
    "cookbook.ENVIAR PARA MENU": "ENVIAR AL MENÚ",
    "cookbook.SALVAR": "GUARDAR",
    "cookbook.salvar": "guardar",
    "cookbook.Filtrar por": "Filtrar por",
    "cookbook.Procurar receita do cookbook": "Buscar receta en el Cookbook",
    "cookbook.Equipamento": "Equipo",
    "cookbook.Ingrediente principal": "Ingrediente principal",
    "cookbook.Tipo de prato": "Tipo de comida",
    "cookbook.Região": "Región",
    "cookbook.Pré-aquecimento": "Precalentamiento",
    "cookbook.Procurar receita": "Buscar receta",
    "cookbook.Menu": "Menú",
    "cookbook.ENVIAR": "ENVIAR",
    "cookbook.Procurar nome do menu": "Buscar por nombre de menú",
    "cookbook.Escolha um grupo deste menu": "Elegir un grupo de este menú",
    "cookbook.Selecionar grupo": "Seleccionar grupo",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "Tuvimos un problema. Inténtalo de nuevo más tarde",
    "cookbook.Receita adicionada com sucesso!": "Receta añadida exitosamente!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "No fue posible agregar la receta. Inténtalo de nuevo más tarde",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Selecciona un menú y un grupo para enviar esta receta",
    "cookbook.Selecione o modelo do equipamento primeiro": "Selecciona primero el modelo del equipo",
    "cookbook.Grupo": "Grupo",
    "cookbook.Não foi possível salvar os passos da receita": "No fue posible guardar los pasos de la receta",
    "cookbook.Bovina": "Carne de res",
    "cookbook.Frango": "Pollo",
    "cookbook.Ovos": "Huevos",
    "cookbook.Pães": "Pan",
    "cookbook.Peixes": "Pescado",
    "cookbook.Queijo": "Queso",
    "cookbook.Suína": "Cerdo",
    "cookbook.Vegetais": "Vegetales",
    "cookbook.Outros": "Otros",
    "cookbook.Entradas/aperitivos": "Entradas",
    "cookbook.Principais": "Principal",
    "cookbook.Snacks": "Aperitivos",
    "cookbook.Pizzas": "Pizzas",
    "cookbook.Massas": "Pastas",
    "cookbook.Acompanhamentos": "Acompañamientos",
    "cookbook.Sobremesas": "Postres",
    "cookbook.Manhã": "Desayuno",
    "cookbook.Padaria": "Panadería",
    "cookbook.Todos": "Todos",
    "cookbook.Não atribuir origem": "No atribuir origen",
    "cookbook.Brasil": "Brasil",
    "cookbook.Alemanha": "Alemania",
    "cookbook.França": "Francia",
    "cookbook.Rússia": "Rusia",
    "cookbook.China": "China",
    "cookbook.Japão": "Japón",
    "cookbook.Itália": "Italia",
    "cookbook.Egito": "Egipto",
    "cookbook.Grécia": "Grecia",
    "cookbook.Argentina": "Argentina",
    "cookbook.África": "África",
    "cookbook.Estados Unidos": "Estados Unidos",
    "cookbook.Coreia": "Corea",
    "cookbook.Turquia": "Turquía",
    "cookbook.Ideal para": "Ideal para",
    "cookbook.Polônia": "Polonia",
    "cookbook.Sanduíches": "Sándwiches",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Equipos",
    "equipment.procurar equipamento": "Buscar equipo o usuario",
    "equipment.adicionar equipamento": "Agregar equipo",
    "equipment.atualizar equipamento": "Actualizar equipo",
    "equipment.+ equipamentos": "Equipo",
    "equipment.buscar equipamento": "Buscar equipo",
    "equipment.Desvincular": "Desvincular",
    "equipment.Editar": "Editar",
    "equipment.sem conteúdo": "Todavía no hay equipos aquí :(",
    "equipment.editar equipamento": "editar equipo",
    "equipment.equipamento editado com sucesso!": "Equipo editado exitosamente!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "No fue posible editar el equipo. Inténtalo de nuevo más tarde",
    "equipment.Menu exportado com sucesso!": "Menú exportado exitosamente!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "No fue posible exportar el menú. Inténtalo de nuevo más tarde",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Selecciona al menos un equipo para enviar el menú",
    "equipment.Informações do menu": "Información del menú",
    "equipment.Procurar receita ou grupo": "Buscar receta o grupo",
    "equipment.Grupo": "Grupo",
    "equipment.Receita": "Receta",
    "equipment.Quantidade": "Cantidad",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Recetas más preparadas en el equipo durante el período",
    "equipment.Informações de limpeza": "Información de limpieza",
    "equipment.Data da limpeza": "Fecha de limpieza",
    "equipment.Hora da limpeza": "Hora de limpieza",
    "equipment.nome": "Nombre",
    "equipment.modelo": "Modelo",
    "equipment.categoria": "Categoría",
    "equipment.loja": "Tienda",
    "equipment.desvincular equipamento": "Desvincular equipo",
    "equipment.unlink.paragrafo 1": "¿Deseas desvincular el equipo de la plataforma IOK?",
    "equipment.unlink.paragrafo 2": "Al hacerlo, no será posible gestionar tus configuraciones y menú a través de la plataforma, a menos que vuelvas a vincular el equipo siguiendo las instrucciones de conexión.",
    "equipamento desvinculado com sucesso!": "Equipo desvinculado exitosamente!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Se ha producido un error. Inténtalo de nuevo más tarde.",
    "equipment.sim": "Sí",
    "equipment.não": "No",
    "equipment.Encerrar acesso remoto": "Finalizar acceso remoto",
    "equipment.Horas sem limpeza": "Horas sin limpieza",
    "equipment.Histórico de eventos": "Historial de eventos",
    "equipment.Evento": "Evento",
    "equipment.Data": "Fecha",
    "equipment.ERRO SENSOR RI": "Error con el sensor de resistencia inferior",
    "equipment.ERRO COM INV. DE FREQ.": "Error con el inversor de frecuencia",
    "equipment.ERRO MAGNETRON": "Error del magnetrón",
    "equipment.ERRO SENSOR CAMARA": "Error con el sensor de la cámara",
    "equipment.ERRO CAN": "Error CAN",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Error del inversor de frecuencia",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Error de modelo del equipo",
    "equipment.ERRO PAINEL": "Error de panel",
    "equipment.ERRO INV.FREQ.STATUS": "Estado de error del inversor de frecuencia",
    "equipment.ERRO SENSOR BOARD": "Error del sensor de la placa",
    "equipment.ERRO ENERGY": "Error de energía",
    "equipment.ERRO I2C": "Error I2C",
    "equipment.EQUIPAMENTO LIGADO": "Equipo encendido",
    "equipment.EQUIPAMENTO DESLIGADO": "Equipo apagado",
    "equipment.Plataforma": "Plataforma",
    "equipment.Forno": "Horno",
    "equipment.Origem": "Origen",
    "equipment.Locais": "Ubicaciones",
    "equipment.não foi possível carregar as entidades": "No fue posible cargar las entidades",
    "equipment.conceder acesso remoto a": "Conceder acceso remoto a",
    "equipment.atualizar equipamentos": "Actualizar equipo",
    "equipment.suporte": "Soporte",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Nombre del equipo",
    "equipment.vinculado à loja": "Vinculado a la tienda",
    "equipment.cadastrar loja": "Registrar tienda",
    "equipment.informe o PIN do forno": "Ingresa el PIN del horno",
    "equipment.preencha o PIN do forno": "Completa el PIN del horno",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Añade un nombre para reconocerlo más fácilmente",
    "equipment.preencha o nome do equipamento": "Completa el nombre del equipo",
    "equipment.selecionar": "Seleccionar",
    "equipment.nome fantasia": "Nombre fantástico",
    "equipment.informe o nome fantasia": "Ingresa el nombre fantástico",
    "equipment.preencha o nome fantasia": "Completa el nombre fantástico",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Completa el CNPJ",
    "equipment.CEP": "Código postal",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Completa el código postal",
    "equipment.cidade": "Ciudad",
    "equipment.informe a cidade": "Ingresa la ciudad",
    "equipment.preencha a cidade": "Completa la ciudad",
    "equipment.UF": "Estado",
    "equipment.bairro": "Barrio",
    "equipment.informe o bairro": "Ingresa el barrio",
    "equipment.endereço": "Dirección",
    "equipment.informe o seu endereço": "Ingresa tu dirección",
    "equipment.preencha o seu endereço": "Completa tu dirección",
    "equipment.Nº": "Nro.",
    "equipment.equipamento cadastrado com sucesso!": "!Equipo registrado exitosamente!",
    "equipment.ocorreu um erro": "Se ha producido un error",
    "equipment.preencha o modelo": "Completa el modelo",
    "equipment.selecione uma categoria": "Selecciona una categoría",
    "equipment.selecione uma loja": "Seleccionar una tienda",
    "equipment.preencha o bairro": "Completa el barrio",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "No fue posible registrar el equipo. Inténtalo de nuevo más tarde",
    "equipment.loja cadastrada com sucesso": "Tienda registrada exitosamente",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "No fue posible registrar la tienda. Inténtalo de nuevo más tarde",
    "equipment.equipment": "Equipos",
    "equipment.stores": "Tiendas",
    "equipment.search equipment": "Buscar equipo",
    "equipment.add equipment": "Agregar equipo",
    "equipment.update equipment": "Actualizar equipo",
    "equipment.plus equipment": "Equipo",
    "equipment.Procurar equipamento": "Buscar equipo",
    "equipment.EQUIPAMENTOS": "EQUIPO",
    "equipment.Atualizar equipamento": "Actualizar equipo",
    "equipment.Atualizar equipamentos": "Actualizar equipo",
    "equipment.confirmar": "Confirmar",
    "equipment.Selecione os modelos que deseja atualizar:": "Selecciona los modelos que deseas actualizar",
    "equipment.Como deseja realizar a atualização?": "¿Cómo deseas proceder con la actualización?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Enviar actualización a través de la plataforma (wi-fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Guardar archivo de actualización en una unidad flash USB",
    "equipment.Linha Express": "Línea Express",
    "equipment.Escolha como deseja realizar a atualização": "Elige cómo deseas proceder con la actualización",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Selecciona al menos un modelo de equipo para actualizar",
    "equipment.Status:": "Estado: ",
    "equipment.Número de série:": "Número de serie",
    "equipment.Menu:": "Menú",
    "equipment.Loja:": "Tienda",
    "equipment.End:": "Finalizar: ",
    "equipment.Cidade:": "Ciudad: ",
    "equipment.CEP:": "Código postal: ",
    "equipment.Atualização de software enviada com sucesso!": "Actualización de software enviada con éxito!",
    "equipment.Não foi possível enviar a atualização de software. Tente novamente mais tarde": "No fue posible enviar la actualización de software. Inténtalo de nuevo más tarde",
    "equipment.Download realizado com sucesso": "Descarga completada con éxito",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "No fue posible completar la descarga. Inténtalo de nuevo más tarde",
    "equipment.name": "Nombre",
    "equipment.model": "Modelo",
    "equipment.category": "Categoría",
    "equipment.store": "tienda",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Completa el nombre de la versión",
    "equipment.Informe aqui todas as alterações desta nova versão": "Ingresa aquí todos los cambios en esta nueva versión",
    "equipment.Nova versão de software atualizada com sucesso!": "Nueva versión de software actualizada con éxito!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Ingresa al menos un cambio en esta versión",
    "equipment.Falha ao ler o arquivo": "Error al leer el archivo",
    "equipment.selecionar tipo": "seleccionar tipo",
    "equipment.selecione o tipo da versão": "seleccionar tipo de versión",
    "equipment.atualização": "ACTUALIZAR",
    "equipment.Selecione abaixo o que deseja fazer:": "Selecciona a continuación lo que deseas hacer:",
    "equipment.Atualizar meus equipamentos": "Actualizar mi equipo",
    "equipment.Enviar atualização para usuários selecionados": "Enviar actualización a usuarios seleccionados",
    "equipment.ENVIAR": "ENVIAR",
    "equipment.ENVIAR ATUALIZAÇÃO": "ENVIAR ACTUALIZACIÓN",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Selecciona los correos electrónicos a los que deseas enviar la actualización:",
    "equipment.A atualização disponibilizada no dia": "La actualización disponible en el día",
    "equipment.referente ao equipamento": "con respecto al equipo",
    "equipment.contempla o seguinte:": "considera lo siguiente:",
    "equipment.Selecionar arquivo para envio": "Seleccionar archivo para enviar",
    "equipment.Por favor, selecione um arquivo .tar": "Por favor, selecciona un archivo .tar",
    "equipment.Selecione o modelo do equipamento:": "Selecciona el modelo del equipo:",
    "equipment.Selecione uma opção": "Selecciona una opción",
    "equipment.Nomeie a versão": "Nombre de la versión",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Haga clic en el archivo que desea descargar:",
    "equipment.Baixar atualização": "Descarga de la actualización",
    "equipment.Versões": "Versiones",
    "equipment.e superiores": "y más alto",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "y",
    "equipment.Últimas versões de software:": "Últimas versiones de software: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "No tienes ningún equipo obsoleto",
    "equipment.grupos": "grupos",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "No se pueden cargar los grupos de equipos de la empresa",
    "equipment.vinculado ao grupo": "Vinculado al grupo",
    "equipment.selecione um grupo": "Seleccione un grupo",
    "equipment.cadastrar grupo": "Registrar grupo",
    "equipment.Número de Série": "NS",
    "equipment.Versão": "Versión",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "No se pudo registrar el equipo. Seleccione o registre un grupo",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "¡CREA GRUPOS DE EQUIPOS!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Para que los usuarios vinculados a la cuenta puedan visualizar el equipo es necesario:",
    "equipment.1) Criar grupo(s);": "1) Crear grupo(s);",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Asignar equipo(s) al grupo;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Asigne usuarios al grupo.",
    "equipment.Status software": "Estado del software",
    "equipment.Status menu": "Estado del menú",
    "equipment.Pendente": "Pendiente",
    "equipment.Aceito": "Aceptado",
    "equipment.Recusado": "Rechazado",
    "equipment.Atualizar": "Actualizar",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "¿Desea enviar la actualización al equipo seleccionado?",
    "equipment.Companhia": "Compañía",
    "equipment.Exportar menu": "Menú exportar",
    "equipment.companhias": "compañías",
    "equipment.Nome da companhia": "Nombre de empresa",
    "equipment.Procurar equipamento, usuário ou companhia": "Búsqueda por equipo, usuario o empresa",
    "equipment.Versão da power": "Versión de potencia",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "AQUÍ AÚN NO HAY DATOS DE LA RECETA :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "AQUÍ AÚN NO HAY DATOS DE LIMPIEZA :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "AQUÍ NO HAY DATOS DEL EVENTO AÚN :(",
    "ID": "ID",
    "equipment.Hora": "Hora",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "EVENTOS MÁS OCURRIDOS EN EL EQUIPO DURANTE EL PERIODO",
    "equipment.hour": "h",
    "equipment.minutes": "min",
    "equipment.date format": "DD/MM/AAAA",
    "equipment.preparada": "preparada",
    "equipment.vezes": "veces",
    "equipment.vez": "vez",
    "equipment.Ver": "Ver",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "El equipo enviará datos a la plataforma cuando esté encendido. Cada dos meses, los datos recibidos se eliminarán automáticamente.",
    "equipment.Ligado": "Encendido",
    "equipment.Desligado": "Apagado",
    "equipment.CAN ERROR": "Error de CAN",
    "equipment.CAMERA SENSOR ERROR": "Error del sensor de la cámara",
    "equipment.STEAM/RI SENSOR ERROR": "Error del sensor RI",
    "equipment.PROBE 1 ERROR": "Alerta de sonda 1",
    "equipment.PROBE 2 ERROR": "Alerta de sonda 2",
    "equipment.PROBE 3 ERROR": "Alerta de sonda 3",
    "equipment.PROBE 4 ERROR": "Alerta de sonda 4",
    "equipment.PROBE 5 ERROR": "Alerta de sonda 5",
    "equipment.PROBE 6 ERROR": "Alerta de sonda 6",
    "equipment.GENERAL PROBE ERROR": "Error general de sonda",
    "equipment.WATER FACK ERROR": "Alerta de nivel bajo de agua",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Alerta de temperatura alta del panel",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Error del variador de frecuencia",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Alerta de comunicación en el variador de frecuencia",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Alerta de estado del variador de frecuencia",
    "equipment.CLEANING NOT COMPLETED ERROR": "Alerta de limpieza incompleta",
    "equipment.MAGNETRON ERROR": "Alerta del magnetrón",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Error de modelo de equipo",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Alerta del sensor de temperatura de la placa",
    "equipment.ENERGY MODULE ERROR": "Error del módulo de energía",
    "equipment.POWER BOARD I2C BUS ERROR": "Error en la placa de alimentación",
    "equipment.GAS SYSTEM ERROR": "Error de gas",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Error de temperatura del panel muy alta",
    "equipment.POWER ON": "Encendido",
    "equipment.POWER OFF": "Apagado",
    "equipment.DOOR OPENED": "Puerta abierta",
    "equipment.DOOR CLOSED": "Puerta cerrada",
    "equipment.CLEANING CANCELED ERROR": "Sanitización cancelada por usuario",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "PIN no encontrado. Por favor revisa e intenta de nuevo",
    "equipment.Online": "En línea",
    "equipment.Offline": "Desconectado",
    "equipment.Exibir apenas eventos": "Mostrar solo eventos",
    "equipment.Endereço": "Dirección",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Equipo conectado a la plataforma",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Equipo desconectado de la plataforma",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menú recibido de la plataforma",
    "equipment.MENU REJECTED BY THE USER": "Menú usuario rechazado",
    "equipment.CONNECTED TO THE INTERNET": "Conectado a Internet",
    "equipment.DISCONNECTED FROM THE INTERNET": "Desconectado de internet",
    "equipment.Infelizmente, não há versões compatíveis com este equipamento que realizem o envio de dados.": "Lamentablemente no existen versiones compatibles con este equipo que puedan enviar datos.",
    "equipment.O equipamento": "El equipo",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "debe actualizarse a la versión más reciente del software para comenzar a enviar datos a la plataforma",
    "equipment.Versão do seu equipamento": "Versión de su equipo",
    "equipment.Versão disponível": "Versión disponible",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Seleccione abajo cómo desea enviar la actualización a su equipo",
    "equipment.Salvar em pen drive": "Guardar en pen drive",
    "equipment.Enviar por wi-fi": "Enviar por Wi-Fi",
    "equipment.Cliente": "Cliente",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "NÚMERO DE SERIE NO ENCONTRADO",
    "equipment.BUSCAR": "BUSCAR",
    "equipment.ACESSADOS RECENTEMENTE": "ACCESO RECIENTE",
    "equipment.SEM BUSCAS RECENTES": "NO HAY BÚSQUEDAS RECIENTES",
    "equipment.Procurar equipamento pelo número de série": "Buscar equipos por número de serie",
    "equipment.AIR FILTER CLEANING COMPLETED": "Limpieza del filtro de aire completa",
    "equipment.AIR FILTER CLEANING REJECTED": "Limpieza del filtro de aire rechazada",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Limpieza del filtro catalítico completada",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Limpieza del filtro catalítico rechazada",
    "equipment.ID do equipamento": "ID del equipo",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Todo el equipo debe estar vinculado a un grupo. Seleccione un grupo de la lista o registre uno nuevo haciendo clic en + Registrar grupo.",
    "equipment.Nomeie seu forno": "Ponle nombre a tu horno",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Para visualizar el PIN acceda a la Plataforma Virtual IOK en la configuración del equipo.",
    "equipment.localização": "ubicación",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "AÚN NO HAY UBICACIÓN REGISTRADA :(",
    "equipment.Procurar empresa ou local": "Buscar empresa o ubicación",
    "equipment.Adicionar localização": "Agregar Direccion",
    "equipment.espaço": "Espacio",
    "equipment.local": "Local",
    "equipment.EXCLUIR LOCALIZAÇÃO": "BORRAR UBICACIÓN",
    "equipment.Você deseja excluir esta localização?": "¿Quieres eliminar esta ubicación?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Al hacer esto, los equipos vinculados al mismo no mostrarán ninguna ubicación, por lo que será necesario proporcionarles una nueva ubicación.",
    "equipment.Localização deletada com sucesso!": "¡Ubicación eliminada correctamente!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Error al eliminar la ubicación. Vuelve a intentarlo más tarde",
    "equipment.Não foi possivel deletar a localização": "No se puede eliminar la ubicación",
    "equipment.Localização adicionada com sucesso!": "¡Ubicación agregada exitosamente!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "No se puede agregar la ubicación. Vuelve a intentarlo más tarde",
    "equipment.Nome do espaço": "Nombre del espacio",
    "equipment.Informe o nome do espaço": "Introduce el nombre del espacio",
    "equipment.Preencha o nome do espaço": "Completa el nombre del espacio",
    "equipment.país": "País",
    "equipment.Endereço (rua, avenida, rodovia)": "Dirección (calle, avenida, carretera)",
    "equipment.Nº/Km": "Nº/Km",
    "equipment.informe o país": "Ingrese al pais",
    "equipment.preencha o país": "Complete el país",
    "equipment.Localização editada com sucesso!": "¡Ubicación editada correctamente!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "No se puede editar la ubicación. Vuelve a intentarlo más tarde",
    "equipment.Editar localização": "Editar ubicación",
    "equipment.Pico de preparações": "Pico de preparaciones",
    "equipment.informações da semana": "información de la semana",
    "equipment.horários de pico": "horas pico",
    "equipment.horário de pico": "hora pico",
    "equipment.preparações do dia": "preparaciones del día",
    "equipment.Horário": "Tiempo",
    "equipment.Preparações": "Preparaciones",
    "equipment.dia selecionado": "día seleccionado",
    "equipment.Vinculação IOK": "Encuadernación IOK",
    "equipment.Última preparação": "Última preparación",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "La limpieza adecuada y diaria del horno ayuda a que funcione correctamente e impacta en la garantía.",
    "equipment.Total de limpezas realizadas:": "Limpieza total realizada:",
    "equipment.Limpezas não concluídas:": "Limpiezas no completadas:",
    "equipment.Desde a vinculação do forno a IOK": "De conectar el horno a IOK",
    "equipment.Equipamento ligado há:": "Equipo encendido desde hace:",
    "equipment.Total de receitas preparadas:": "Total de recetas preparadas:",
    "equipment.Receitas preparadas": "Recetas preparadas",
    "equipment.Aberturas de porta": "Aperturas de puertas",
    "equipment.Desde a última vez que o forno foi ligado": "Desde la última vez que se encendió el horno",
    "equipment.Preparação": "Preparación",
    "equipment.Nenhuma localização criada": "No se han creado ubicaciones",
    "equipment.Acesso remoto": "Acceso remoto",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Ingrese el ID de la empresa que puede acceder remotamente a su horno. Usar ; para separar identificaciones.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "El acceso remoto permite a empresas seleccionadas enviar menús y actualizaciones a su equipo.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Concedo acceso remoto a la Prática oficial.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "¿Quieres seleccionar otros dispositivos vinculados a tu cuenta para otorgarles acceso remoto?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Seleccione a continuación el equipo al que desea otorgar acceso remoto al",
    "equipment.Acesso remoto concedido com sucesso!": "¡Acceso remoto concedido con éxito!",
    "equipment.Selecione pelo menos um equipamento": "Seleccione al menos un dispositivo",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "No se puede editar el equipo. Vuelve a intentarlo más tarde",
    "equipment.Solicitar atendimento": "Solicitar servicio",
    "equipment.Relate um problema": "Informar un problema",
    "equipment.Descreva aqui o problema de seu equipamento": "Describe aquí el problema de tu equipo",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "No pudimos enviar su solicitud de servicio. Inténtalo más tarde",
    "equipment.Solicitação técnica enviada com sucesso!": "¡Solicitud técnica enviada exitosamente!",
    "equipment.Enviar": "Enviar",
    "equipment.Solicitação de atendimento": "Solicitud de servicio",
    "equipment.Informações do magnetron": "Información del magnetrón",
    "equipment.Vida útil aproximada": "Vida útil aproximada",
    "equipment.MAGNETRON USAGE HOURS": "Horas de uso del magnetrón",
    "equipment.Filtros": "Filtros",
    "equipment.Filtrar": "Filtrar",
    "equipment.Limpar filtros": "Limpiar filtros",
    "equipment.Procurar por modelo, versão ou país": "Buscar por modelo, versión o país",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Buscar equipo, NS, modelo, versión o país",
    "equipment.Atendimento": "Servicio",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Eventos que más ocurrieron en el equipo durante el período",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "EQUIPO SIN NÚMERO DE SERIE",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Los siguientes IDs no pertenecen a una empresa del tipo 'Distribuidor' y han sido eliminados",
    "equipment.BAIXAR VERSÕES": "DESCARGAR VERSIONES",
    "equipment.Compatível com todas as versões da IHM.": "Compatible con todas las versiones de HMI.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Compatible únicamente con la versión Power 2.0.0C.",
    "equipment.Procurar evento": "Buscar evento",
    "equipment.Forno conectado": "Horno conectado",
    "equipment.Limpeza de filtro de ar": "Limpieza de filtro de aire",
    "equipment.Limpeza de filtro catalítico": "Limpieza de filtro catalítico",
    "equipment.Forno ligado": "Horno encendido",
    "equipment.Inversor de frequência": "Inversor de frecuencia",
    "equipment.Temperatura de painel": "Temperatura del panel",
    "equipment.Sensor da câmara": "Sensor de cámara",
    "equipment.Magnetron": "Magnetrón",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Información de menú",
    "equipment.Limpeza": "Limpieza",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "El equipo ya está utilizando la versión más reciente del software.",
    "equipment.Atenção: o cálculo da vida útil do magnetron não considera o tempo de uso anterior à versão F1.0.6." : "Atención: el cálculo de la vida útil del magnetrón no considera el tiempo de uso anterior a la versión F1.0.6.",
    "equipment.Equipamentos com erro" : "Equipos con errores",
    "equipment.Em breve você terá acesso a dados importantes enviados pelo forno, como horário de pico, receitas mais executadas, histórico de higienizações, eventos ocorridos e mais! Lembre-se de manter seu equipamento sempre atualizado com as novas versões disponíveis na plataforma." : 
      "¡Pronto tendrás acceso a datos importantes enviados por el horno, como horas pico, recetas más ejecutadas, historial de limpieza, eventos ocurridos y más! Recuerda mantener tu equipo siempre actualizado con las nuevas versiones disponibles en la plataforma.",
    "equipment.Para que a execução da limpeza seja registrada no equipamento e enviada à plataforma, é necessário seguir o passo a passo completo do procedimento no forno. Não é obrigatório assistir aos vídeos dos passos, quando disponíveis." : 
      "Para que la limpieza quede registrada en el equipo y enviada a la plataforma, es necesario seguir el procedimiento completo paso a paso en el horno. No es obligatorio ver vídeos de los pasos, cuando estén disponibles.",

    //LOJAS
    "store.Procurar loja": "Buscar tienda",
    "store.Excluir": "Eliminar",
    "store.Editar": "Editar",
    "store.nome": "Nombre",
    "store.equipamentos": "Equipo",
    "store.cidade": "Ciudad",
    "store.estado": "Estado",
    "store.salvar": "Guardar",
    "store.cadastrar loja": "registrar tienda",
    "store.Nome fantasia": "Nombre fantástico",
    "store.Preencha o nome da loja": "Completa el nombre de la tienda",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Completa el CNPJ de la tienda",
    "store.CEP": "Código postal",
    "store.Preencha o CEP da loja": "Completa el código postal de la tienda",
    "store.Informe o nome fantasia": "Completa el nombre fantástico",
    "store.Cidade": "Ciudad",
    "store.Informe a cidade": "Ingresa la ciudad",
    "store.Preencha a cidade": "Completa la ciudad",
    "store.UF": "Estado",
    "store.Preencha o UF da loja": "Completa el estado de la tienda",
    "store.Bairro": "Barrio",
    "store.Informe o bairro": "Ingresa el barrio",
    "store.Preencha o bairro da loja": "Completa el barrio de la tienda",
    "store.Endereço": "Dirección",
    "store.Informe o endereço": "Ingresa la dirección",
    "store.Preencha o endereço da loja": "Completa la dirección de la tienda",
    "store.Nº": "Nro.",
    "store.Loja cadastrada com sucesso": "Tienda registrada exitosamente",
    "store.Erro ao adicionar loja. Tente novamente": "Error al agregar la tienda. Inténtalo de nuevo",
    "store.Loja deletada com sucesso": "Tienda eliminada exitosamente",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Error al eliminar la tienda. Inténtalo de nuevo más tarde",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "TODAVÍA NO HAY TIENDAS REGISTRADAS :(",
    "store.EXCLUIR LOJA": "ELIMINAR TIENDA",
    "store.Você deseja excluir a loja da plataforma IOK?": "¿Deseas eliminar la tienda de la plataforma IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "Al hacerlo, el equipo vinculado a ella no estará vinculado a ninguna tienda, a menos que los vuelvas a vincular.",
    "store.loja editada com sucesso": "Tienda editada exitosamente",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "No fue posible editar la tienda. Inténtalo de nuevo más tarde",
    "store.editar loja": "editar tienda",
    "store.Procurar grupo de equipamentos": "Buscar grupo de equipos",
    "store.cadastrar grupo": "grupo de registro",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "AÚN NO HAY GRUPO REGISTRADO :(",
    "store.cadastrar grupo de equipamentos": "registrar grupo de equipos",
    "store.grupo de equipamentos cadastrado com sucesso!": "!Grupo de equipos registrado exitosamente!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "No se puede registrar el grupo. Vuelve a intentarlo más tarde",
    "store.Nome do grupo": "Nombre del grupo",
    "store.informe o nome do grupo": "Introduzca el nombre del grupo",
    "store.preencha o nome do grupo": "Complete el nombre del grupo",
    "store.Grupo de equipamentos deletado com sucesso!": "Grupo de equipos eliminado exitosamente!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Error al eliminar el grupo. Vuelve a intentarlo más tarde",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "ELIMINAR GRUPO DE EQUIPOS",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "¿Quiere eliminar este grupo de equipos de la plataforma IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Al hacer esto, los equipos vinculados a él no quedarán vinculados a ningún grupo, a menos que los vuelvas a vincular.",
    "store.grupo de equipamentos editado com sucesso!": "Grupo de equipos editado exitosamente!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "No se puede editar el grupo. Vuelve a intentarlo más tarde",
    "store.editar grupo de equipamentos": "editar grupo de equipos",
    "store.CEP não encontrado no Brasil": "Código postal no encontrado en Brasil",

    //NAVBAR
    "navbar.home": "home",
    "navbar.cookbook": "cookbook",
    "navbar.my menus": "mis menús",
    "navbar.equipment": "equipamiento",
    "navbar.users": "usuarios",
    "navbar.calls": "servicios",
    "navbar.settings": "configuraciones",
    "navbar.help": "ayuda",
    "navbar.logout": "Cerrar sesión",
    "navbar.Editar conta": "Editar cuenta",
    "navbar.API Externa": "API Externa",

    //FOOTERBAR
    "footerBar.menu": "Menú",
    "footerBar.cookbook": "Cookbook",
    "footerBar.home": "Inicio",
    "footerBar.menus": "Menús",
    "footerBar.notifications": "Notificaciones",

    //ATENDIMENTOS 
    "calls.ATENDIMENTOS EM ABERTO": "SERVICIOS EN CURSO",
    "calls.ATENDIMENTOS ENCERRADOS": "SERVICIOS CERRADOS",

    //MAP
    "map.título": "Ubicación",
    "map.erro": "Error",
    "map.mensagem de erro": "No se puede cargar el mapa",
    "map.Localização não disponível": "Ubicación no disponible",

    //MODAL
    "modal.CONFIRMAR": "CONFIRMAR",
    "modal.Salvar alterações": "Guardar ediciones",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Hay cambios no guardados en los parámetros del paso",
    "Modal.Como deseja proceder?": "¿Cómo deseas proceder?",
    "Modal.Avaliar alterações realizadas": "Evaluar cambios realizados",
    "Modal.Descartar alterações": "Descartar cambios",

    //USUÁRIOS
    "user.Nome": "Nombre",
    "user.E-mail": "Correo electrónico",
    "user.Permissão": "Permiso",
    "user.Loja": "Tienda",
    "user.Status": "Estado",
    "user.Excluir": "Eliminar",
    "user.Editar": "Editar",
    "user.Procurar usuário": "Buscar usuario",
    "user.ADICIONAR USUÁRIO": "AGREGAR USUARIO",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "AÚN NO HAY USUARIOS REGISTRADOS :(",
    "user.+ Usuário": "+ Usuario",
    "user.Informe o nome do usuário": "Ingresa el nombre de usuario",
    "user.Preencha o nome do usuário": "Completa el nombre de usuario",
    "user.Informe o e-mail do usuário": "Ingresa el correo electrónico del usuario",
    "user.Preencha com um e-mail válido": "Ingresa una dirección de correo electrónico válida",
    "user.Administrador": "Administrador",
    "user.Usuário": "Usuario",
    "user.Preencha o telefone do usuário": "Completa el número de teléfono del usuario",
    "user.Selecione a permissão do usuário": "Selecciona el permiso del usuario",
    "user.Ativo": "Activo",
    "user.Inativo": "Inactivo",
    "user.Usuário deletado da plataforma com sucesso!": "Usuario eliminado de la plataforma exitosamente!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Ha ocurrido un error. Inténtalo de nuevo más tarde.",
    "user.EXCLUIR USUÁRIO": "ELIMINAR USUARIO",
    "user.delete.paragrafo 1": "¿Deseas eliminar al usuario de la plataforma IOK?",
    "user.delete.paragrafo 2": "Al hacerlo, perderá el acceso a todas las herramientas de la plataforma, a menos que se le vuelva a agregar siguiendo las instrucciones de registro.",
    "user.EDITAR USUÁRIO": "EDITAR USUARIO",
    "user.Usuário editado com sucesso!": "Usuario editado exitosamente!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "No fue posible editar a este usuario. Por favor, inténtalo más tarde.",
    "user.INFORMAÇÕES DO USUÁRIO": "INFORMACIÓN DEL USUARIO",
    "user.Telefone": "Teléfono",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "No fue posible registrar a este usuario. Por favor, inténtalo más tarde.",
    "user.Usuário adicionado com sucesso!": "!Usuario agregado exitosamente!",
    "user.CRIAR TIPO DE USUÁRIO": "CREAR TIPO DE USUARIO",
    "user.Criar novo tipo de usuário": "Crear nuevo tipo de usuario",
    "user.Adicionar novo usuário": "Agregar nuevo usuario",
    "user.Permissões": "Permisos",
    "user.Preencha o nome do novo tipo de usuário": "Completa el nombre del nuevo tipo de usuario",
    "user.Selecione pelo menos uma permissão": "Selecciona al menos un permiso",
    "user.Não foi possível carregar as permissões": "No fue posible cargar los permisos",
    "user.Tipo de usuário criado com sucesso!": "!Tipo de usuario creado exitosamente!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "No fue posible crear este nuevo tipo de usuario. Por favor, inténtalo más tarde.",
    "user.Não foi possível carregar os tipos de usuário da companhia": "No fue posible cargar los tipos de usuario de la empresa",
    "user.Tipo de usuário": "Tipo de usuario",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "Correo electrónico ya registrado en la plataforma. Ingresa un nuevo correo electrónico.",
    "user.UNIR CONTAS": "UNIR CUENTAS",
    "user.ID da companhia": "ID de la compañía",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Ingrese el ID de la empresa con la que desea fusionar su cuenta",
    "user.Preencha o ID da companhia": "Complete el ID de la empresa",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "El administrador de la cuenta a la que desea unirse podrá encontrar el ID haciendo clic en su nombre de usuario, ubicado en el menú superior, en la esquina derecha de la pantalla.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "El administrador de la otra cuenta recibirá un correo electrónico para aceptar su solicitud. Una vez aceptada, las dos cuentas se fusionarán!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "No se puede solicitar la fusión de cuentas. Confirma tu ID o inténtalo más tarde.",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "SOLICITUD APROBADA EXITOSAMENTE",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "POR FAVOR ESPERE MIENTRAS CONECTAMOS LAS CUENTAS. ESTE PROCESO PUEDE TARDAR UNOS MINUTOS...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "¡UNIÓN DE CUENTAS COMPLETADA CON ÉXITO! HAZ CLIC EN EL BOTÓN DE ABAJO PARA ACCEDER A LA PLATAFORMA.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "NO PODEMOS FUSIONAR LAS CUENTAS. INTÉNTALO DE NUEVO. SI EL PROBLEMA PERSISTE, CONTACTE CON NUESTRO SOPORTE.",
    "user.possui todos os acessos": "tiene todo el acceso",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "tiene acceso al libro de cocina, a mis menús y a las pestañas de equipo",
    "user.possui apenas o acesso da aba cookbook": "solo tiene acceso a la pestaña del libro de cocina",
    "user.possui apenas o acesso da aba equipamentos": "solo tiene acceso a la pestaña de equipo",
    "user.possui apenas o acesso da aba meus menus": "solo tiene acceso a la pestaña mis menús",
    "user.Grupo de equipamentos": "Grupo de equipos",
    "user.Informe o telefone do usuário": "Ingrese el número de teléfono del usuario",
    "user.Nenhum grupo criado": "No se crearon grupos",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Ingrese el ID de la empresa que tendrá acceso a su cuenta",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Al proporcionar una identificación para fusionar cuentas con su empresa, tenga en cuenta que el nuevo usuario también se convertirá en administrador de la cuenta de su empresa. Es importante informar que desde el momento en que un usuario acepta unir cuentas a su empresa, la empresa que le pertenece dejará de existir.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Ejemplo: Tu cuenta: 001 | Otra cuenta: 111. Después de fusionar cuentas con usted, la otra cuenta pasará a ser 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "El administrador de la otra cuenta podrá localizar el ID haciendo clic en su nombre de usuario, ubicado en el menú superior, en la esquina derecha de la pantalla.",
    "user.Tela de Equipamentos": "Pantalla de Equipos",
    "user.Tela de Meus Menus": "Pantalla de Mis Menús",
    "user.Tela do Cookbook": "Pantalla del Cookbook",
    "user.Tela de Usuários": "Pantalla de Usuarios",
    "user.Aba de Suporte": "Pestaña de Soporte",
    "user.Usuário Comum": "Usuario Común",
    "user.Usuário do Cookbook": "Usuario del Cookbook",
    "user.Usuário de Equipamentos": "Usuario de Equipos",
    "user.Usuário de Meus Menus": "Usuario de Mis Menús",
    "user.Você deseja excluir seu acesso da plataforma IOK?" : "¿Deseas eliminar tu acceso a la plataforma IOK?",
    "user.Ao fazer isso, você perderá o acesso a todas as ferramentas da plataforma e, para voltar a acessá-la, será necessário ser adicionado à ela novamente." : "Al hacer esto, perderás el acceso a todas las herramientas de la plataforma y, para volver a acceder, tendrás que ser añadido a ella nuevamente.",
    "user.CONFIRMAÇÃO" : "CONFIRMACIÓN",
    "user.Confirme sua senha" : "Confirma tu contraseña",
    "user.Senha incorreta" : "Contraseña incorrecta",
    
    //TUTORIAIS
    "tutorial.Fechar": "Cerrar",
    "tutorial.Fim": "Finalizar",
    "tutorial.Próximo": "Siguiente",
    "tutorial.Abrir": "Abrir",
    "tutorial.Pular tutorial": "Omitir tutorial",
    "tutorial.Editar detalhes da receita": "Editar detalles de la receta",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Ahora puedes agregar más detalles a tus recetas y hacerlas aún más completas. Haz clic en Editar detalles y completa la información necesaria.",
    "tutorial.Salvar edição da receita": "Guardar edición de receta",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Después de haber proporcionado más detalles de la receta, haz clic en Guardar",
    "tutorial.Editar registro de um equipamento": "Editar registro del equipo",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Para completar el registro de tu equipo, abre el menú lateral, haz clic en Editar y completa la información de la Categoría y la Tienda.",
    "tutorial.Editar usuário": "Editar usuario",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Para completar el registro de usuario, abre el menú lateral, haz clic en Editar y completa la información sobre la tienda a la que pertenecen.",
    "tutorial.Salvar edições de um equipamento": "Guardar las ediciones de un equipo",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Después de completar el registro de tu equipo, haz clic en Guardar.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Al hacer clic en la línea de un equipo registrado en una tienda, podrás ver más estadísticas e información sobre él, además de poder solicitar un servicio o activar actualizaciones.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "Para ver más información sobre la tienda, como la ubicación y las recetas más preparadas, haga clic en la fila de la unidad que desea analizar.",
    "tutorial.Criar receita": "Crear receta",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "¡En el Cookbook puedes crear tus propias recetas! Haz clic en Crear Receta y completa toda la información necesaria.",
    "tutorial.Editar ou excluir um usuário": "Editar o eliminar un usuario",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Para eliminar o editar la información de un usuario registrado, haz clic en el menú lateral y selecciona la opción deseada.",
    "tutorial.Gerenciamento de usuários": "Gestión de usuarios",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Aquí podrás gestionar tus usuarios más fácilmente. Al hacer clic en + Usuario, puede agregar un nuevo usuario o crear un nuevo tipo de usuario.",
    "tutorial.Suas receitas": "Tus recetas",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "A través del Cookbook, también puedes encontrar tus propias recetas, ¡además de poder crearlas! Haz clic en Recetas Guardadas para ver las recetas guardadas del Cookbook de Prática y las creadas por ti.",
    "tutorial.Filtros do Cookbook": "Filtros del Cookbook",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Para facilitar la navegación a través de las recetas, puedes aplicar filtros a ellas. Solo tienes que hacer clic en el filtro deseado y elegir una de sus opciones.",
    "tutorial.Cookbook": "Cookbook",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "En el Cookbook, en Recetas Disponibles, encontrarás las recetas creadas por los chefs de Prática y podrás utilizarlas cuando lo desees.",
    "tutorial.Atualizar equipamentos": "Actualizar equipo",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Para enviar una actualización a tu equipo, haz clic en Actualizar Equipo, selecciona los modelos que deseas actualizar y cómo deseas hacerlo, ya sea mediante Wi-Fi o una unidad flash USB. Después de eso, haz clic en Confirmar.",
    "tutorial.Adicionar equipamento": "Agregar equipo",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Para agregar un equipo a tu cuenta, haz clic en Agregar Equipo y completa la información del modal. Después, haz clic en Guardar.",
    "tutorial.Editar equipamento": "Editar equipo",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Para desvincular un equipo o editar su información, haz clic en el menú lateral y selecciona la opción deseada.",
    "tutorial.Seus equipamentos": "Tus equipos",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Aquí verás todos los equipos registrados en tu plataforma.",
    "tutorial.Restaurar arquivos": "Restaurar archivos",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Para restaurar un archivo eliminado o eliminarlo permanentemente, haz clic en el menú lateral y selecciona la opción deseada.",
    "tutorial.Lixeira": "Papelera",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Los menús eliminados se mantendrán en la Papelera durante 30 días. Después de este período, se eliminarán permanentemente.",
    "tutorial.Salvar edições de usuário": "Guardar ediciones de usuario",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Después de completar el registro del usuario, haz clic en Guardar.",
    "tutorial.Criar menu": "Crear menú",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Para crear y personalizar un menú, haz clic en Crear Menú y completa la información necesaria en el modal. Puedes editar un menú en cualquier momento.",
    "tutorial.Importar menu": "Importar menú",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Para importar un menú, haz clic en Importar Menú y selecciona el modelo y la versión del equipo.",
    "tutorial.Criar grupo": "Crear grupo",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Para gestionar tus recetas de grupo, haz clic en Crear Grupo y completa la información necesaria en el modal.",
    "tutorial.Tipos de usuário": "Tipos de usuarios",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Ahora también puede crear tipos de usuarios con permisos específicos para servir mejor a su organización.",
    "tutorial.Gerenciamento de contas": "Administración de cuentas",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Si alguien más en su organización también migró su cuenta principal de empresa, aquí puede fusionar las dos cuentas.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Para completar el registro de su equipo, abra el menú lateral, haga clic en Editar y complete la información del Grupo.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Para completar el registro de usuario, abra el menú lateral, haga clic en Editar y complete la información relativa al Grupo de Equipo al que pertenece.",
    "tutorial.Grupos de equipamentos": "Grupos de equipos",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "En la pestaña Grupos podrás visualizar los grupos registrados y los equipos distribuidos entre ellos. Además, es posible registrar nuevos grupos y editar los ya registrados.",
    "tutorial.Detalhes de grupos": "Detalles del grupo",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "Para visualizar más información sobre el grupo, como la ubicación y las recetas más preparadas, haz clic sobre la fila que deseas analizar.",
    "tutorial.Equipamentos em grupos": "Equipo en grupos",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Al hacer clic en la línea de un dispositivo registrado en un grupo, podrás ver más estadísticas e información sobre el mismo, además de poder solicitar servicio o activar actualizaciones.",
    "tutorial.Grupos": "Grupos",
    "tutorial.Atualização e cache": "Actualización y caché",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Para actualizar la plataforma y corregir cualquier problema de caché, haga clic aquí.",
    "tutorial.Dados dos equipamentos": "Datos de los equipos",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "Al hacer clic sobre la fila de un equipo, puedes visualizar más estadísticas e información sobre él, como las recetas más preparadas, limpiezas realizadas y otros datos relevantes.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Para cambiar el idioma de su plataforma, haga clic en Idioma y seleccione el idioma deseado.",
    "tutorial.Alterar idioma": "Cambia el idioma",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Para editar o configurar su cuenta, haga clic en su nombre y seleccione la opción deseada.",
    "tutorial.Editar conta": "Editar cuenta",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Total de usuarios registrados en su cuenta.",
    "tutorial.Home": "Home",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Ubicación de su equipo, permitiendo ampliar el mapa para una mejor visualización;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Actualizaciones sobre la versión del equipo vinculado a su cuenta;",
    "tutorial.Total de Receitas Cadastradas;": "Ingresos Totales Registrados;",
    "tutorial.Menus Disponíveis;": "Menús disponibles;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "¡Esta es tu página de inicio! Aquí encontrará un resumen general de su cuenta, como la cantidad de Equipos Vinculados;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Haz clic en las pestañas del menú para explorar la plataforma y descubrir todo lo que puede ofrecer.",
    "tutorial.Exportar menu": "Menú exportar",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Después de personalizar su menú, expórtelo a sus dispositivos a través de Internet o mediante pendrive.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "En la pestaña Ubicación podrás visualizar las direcciones ya registradas, además de crear ubicaciones precisas para vincular tu equipo a ellas.",
    "tutorial.Localização": "Ubicación",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Para continuar, selecciona la categoría a la que perteneces:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "¿Deseas acceder a la plataforma como DISTRIBUIDOR? Crea tu cuenta haciendo clic en EMPRESA y contáctanos para habilitar los demás accesos.",
    "recoverAccount.A qual organização você pertence": "¿A qué organización perteneces?",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "Correo electrónico para recuperación de contraseña",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "Ingresa el correo electrónico registrado en la plataforma.",
    "recoverPassword.E-mail inválido. Por favor, verifique": "Correo electrónico no válido. Por favor, verifica.",
    "recoverPassword.RECUPERAR SENHA": "RECUPERAR CONTRASEÑA",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "PRONTO RECIBIRÁS UN CORREO ELECTRÓNICO CON EL ENLACE DE RECUPERACIÓN DE CONTRASEÑA.",
    "recoverPassword.Login": "Inicio de sesión",
    "recoverPassword.Informe seu e-mail": "Ingresa tu correo electrónico.",
    "recoverPassword.Crie uma nova senha": "Crea una nueva contraseña",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "El campo de la contraseña debe tener al menos 6 caracteres.",
    "recoverPassword.Confirme sua senha": "Confirmar contraseña",
    "recoverPassword.As senhas devem ser iguais": "Las contraseñas deben coincidir.",
    "recoverPassword.ENTRAR": "ENTRAR",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "PRONTO RECIBIRÁS UN CORREO ELECTRÓNICO PARA CONFIRMAR TU IDENTIDAD.",
    "recoverPassword.CONTINUAR": "CONTINUAR",
    "recoverPassword.CONFIRMAR": "CONFIRMAR",
    "recoverPassword.Por segurança, o acesso à conta foi bloqueado devido a tentativas de login incorretas.": "Por razones de seguridad, el acceso a la cuenta ha sido bloqueado debido a intentos de inicio de sesión incorrectos.",
    "recoverPassword.Para desbloquear sua conta, siga as instruções após clicar no botão abaixo:": "Para desbloquear su cuenta, siga las instrucciones después de hacer clic en el botón a continuación:",
    "recoverPassword.Desbloquear conta": "Desbloquear cuenta",
    "recoverPassword.Você recebeu um e-mail para iniciar o desbloqueio de sua conta.": "Ha recibido un correo electrónico para iniciar el desbloqueo de su cuenta.",
    "recoverPassword.Verifique sua caixa de entrada ou spam.": "Verifique su bandeja de entrada o carpeta de spam.",
    "recoverPassword.Verifique seu e-mail": "Verifique su correo electrónico.",

    // EXPORT
    "export.Limpezas": "Limpiezas",
    "export.Eventos": "Eventos",
    "export.Lista de receitas preparadas": "Lista de recetas preparadas",
    "export.Lista de limpezas realizadas": "Lista de limpiezas realizadas",
    "export.Lista de eventos ocorridos": "Lista de eventos ocurridos",
    "export.Exportar dados": "Exportar datos",
    "export.Informações do equipamento": "Información del equipo",
    "export.Dados exportados com sucesso!": "¡Datos exportados con éxito!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "No se pudo exportar los datos. Por favor, inténtelo de nuevo más tarde.",
    "export.Detalhes do menu": "Detalles del menú",
    "export.Detalhes de receitas": "Detalles de recetas",
    "export.Detalhes e Passos": "Detalles y Pasos",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Exportando datos. ¡El archivo estará disponible en breve!",
    "export.Resumo dos passos": "Resumen de los pasos",
    "export.salvar imagem": "guardar imagen"
  },
};

export default es;
