const VALIDATOR_TYPE_REQUIRE = 'REQUIRE';
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
const VALIDATOR_TYPE_MIN = 'MIN';
const VALIDATOR_TYPE_MAX = 'MAX';
const VALIDATOR_TYPE_EMAIL = 'EMAIL';
const VALIDATOR_TYPE_FILE = 'FILE';
const VALIDATOR_TYPE_NAME = 'NAME';
const VALIDATOR_TYPE_PASSWORD = 'PASSWORD'

type validatorRequireType = { type: typeof VALIDATOR_TYPE_REQUIRE; };
type validatorFileType = { type: typeof VALIDATOR_TYPE_FILE; };
type validatorMinLengthType = { type: typeof VALIDATOR_TYPE_MINLENGTH; val: number; };
type validatorMaxLengthType = { type: typeof VALIDATOR_TYPE_MAXLENGTH; val: number; };
type validatorMinType = { type: typeof VALIDATOR_TYPE_MIN; val: number };
type validatorMaxType = { type: typeof VALIDATOR_TYPE_MAX; val: number };
type validatorEmailType = { type: typeof VALIDATOR_TYPE_EMAIL; };
type validatorNameType = { type: typeof VALIDATOR_TYPE_NAME; };
type validatorPasswordType = { type: typeof VALIDATOR_TYPE_PASSWORD; };

export type validatorType =
  | validatorRequireType
  | validatorFileType
  | validatorMinLengthType
  | validatorMaxLengthType
  | validatorMinType
  | validatorMaxType
  | validatorEmailType
  | validatorNameType
  | validatorPasswordType;

export const VALIDATOR_REQUIRE = (): validatorRequireType => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALIDATOR_FILE = (): validatorFileType => ({ type: VALIDATOR_TYPE_FILE });
export const VALIDATOR_MINLENGTH = (val: number): validatorMinLengthType => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val,
});
export const VALIDATOR_MAXLENGTH = (val: number): validatorMaxLengthType => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val,
});
export const VALIDATOR_MIN = (val: number): validatorMinType => ({
  type: VALIDATOR_TYPE_MIN,
  val: val,
});
export const VALIDATOR_MAX = (val: number): validatorMaxType => ({
  type: VALIDATOR_TYPE_MAX,
  val: val,
});
export const VALIDATOR_EMAIL = (): validatorEmailType => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALIDATOR_NAME = (): validatorNameType => ({ type: VALIDATOR_TYPE_NAME });
export const VALIDATOR_PASSWORD = (): validatorPasswordType => ({ type: VALIDATOR_TYPE_PASSWORD });

export const validate = (value: string, validators: validatorType[]) => {
  let isValid = true;
  for (const validator of validators) {
    if (validator.type === VALIDATOR_TYPE_REQUIRE) {
      isValid = isValid && !!value && value.trim().length > 0;
    }
    if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
      isValid = isValid && !!value && value.trim().length >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
      isValid = isValid && !!value && value.trim().length <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MIN) {
      (!value || (isValid = isValid && +value >= validator.val));
    }
    if (validator.type === VALIDATOR_TYPE_MAX) {
      (!value || (isValid = isValid && +value <= validator.val));
    }
    if (validator.type === VALIDATOR_TYPE_EMAIL) {
      isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_NAME) {
      isValid = isValid && /^[a-zA-Z0-9À-ÖØ-öø-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u0370-\u03FF\u0400-\u04FF\u4E00-\u9FFF\s]+$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_PASSWORD) {
      isValid = isValid && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/.test(value);
    }    
  }
  return isValid;
};
